// src/redux/sessionReducer.ts
import { createSlice } from "@reduxjs/toolkit";

const sessionSlice = createSlice({
  name: "session",
  initialState: { data: [], loading: false, error: null },
  reducers: {
    sessionRequested: (state) => {
      state.loading = true;
      state.error = null;
    },
    sessionReceived: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    sessionRequestFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateSessionData: (state: any, action) => {
      const updatedData = state?.data?.map((x: any) =>
        x?._id === action?.payload?._id
          ? { ...x, ...action.payload, updated: true }
          : x
      );

      return {
        ...state,
        loading: false,
        data: updatedData,
      };
    },

    singleSessionRequested: (state) => {
      state.loading = true;
      state.error = null;
    },
    singleSessionReceived: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    singleSessionRequestFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    sessionsReset: (state) => {
      state.loading = false;
      state.data = [];
    },
  },
});

export const {
  sessionRequested,
  sessionReceived,
  sessionRequestFailed,
  singleSessionRequested,
  singleSessionReceived,
  singleSessionRequestFailed,
  updateSessionData,
  sessionsReset,
} = sessionSlice.actions;

export default sessionSlice.reducer;
