import { useEffect, useState } from "react";
import { getCurrentUser } from "../../../shared/components/authentication";

const useGetCurrentUser = () => {
  const [currentUser, setCurrentUser] = useState<any>({});

  useEffect(() => {
    const user = getCurrentUser();
    if (user) {
      setCurrentUser(user);
    }
  }, []);

  return { currentUser };
};

export default useGetCurrentUser;
