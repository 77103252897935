// src/redux/coachingReducer.ts
import { createSlice } from "@reduxjs/toolkit";

const coachingSlice = createSlice({
  name: "coaching",
  initialState: { data: [], loading: false, error: null },
  reducers: {
    coachingRequested: (state) => {
      state.loading = true;
      state.error = null;
    },
    coachingReceived: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    coachingRequestFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateCoachingData: (state: any, action) => {
      const updatedData = state?.data?.map((coaching: any) =>
        coaching._id === action.payload._id
          ? { ...coaching, ...action.payload, updated: true }
          : coaching
      );

      state.data = updatedData;
    },
    singleCoachingRequested: (state) => {
      state.loading = true;
      state.error = null;
    },
    singleCoachingReceived: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    singleCoachingRequestFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    coachingReset: (state) => {
      state.loading = false;
      state.data = [];
    },
  },
});

export const {
  coachingRequested,
  coachingReceived,
  coachingRequestFailed,
  singleCoachingRequested,
  singleCoachingReceived,
  singleCoachingRequestFailed,
  updateCoachingData,
  coachingReset,
} = coachingSlice.actions;

export default coachingSlice.reducer;
