import { IMAGE_BASE_PATH_BUCKET_URL } from "../../config/constants";

export const HelpEN = {
  howToSignup: {
    title: "HOW TO SIGN UP IN RESA PADEL?",
    content: `You can sign in to the Resa Padel using 2 ways
          <br/>
          <ul style="list-style: number">
            <li>Signing up with Mobile Number</li>
            <li>Signing up with Social Accounts</li>
          </ul>
          <div class="static-subtitle">
            Signing Up with Mobile Number
          </div>
            To create an account, users need to provide their country code along
            with their mobile number. 
            <br/>

            <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/1Signup.png" alt="signup"  width="200px" />
            </div>
            
            Upon entering a valid mobile number, a one-time password (OTP) will be sent to the user via text message.
            <br/>

            <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/2Otp_msg.png" alt="Otp message"  width="200px" />
            </div>

            <div class="static-subtitle">
              Verification Process
            </div> 
         
            After receiving the OTP, users must key in the OTP into the
            application. If the OTP is valid, the application will prompt the
            user to set a password. They need to provide a new password and
            confirm by entering it again.Once the password is successfully
            confirmed, users are allowed to login to the app.
           <br />

            <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/3OTP.png" alt="Otp"  width="200px" />
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/4create_pass.png" alt="Create Password"  width="200px" />
            </div>

           <br />
            For subsequent logins, the user needs to remember this password to
            login.
            <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/5Entermobile.png" alt="Enter Mobile"  width="200px" />
            </div>
          `,
  },
  howToSignSocialAccounts: {
    title: "HOW TO SIGN UP USING SOCIAL ACCOUNTS?",
    content: `The user needs to authenticate the social account login and after
            successful authentication, the application will allow the user to
            login.
            <br /><br />
          
            Social Accounts like <b>Gmail, Facebook, LinkedIn, Apple</b>

            <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/6Social.png" alt="Social"  width="200px" />
            </div>

            <div class="static-subtitle">Login:</div>
         
            The signed up users will be logged in by providing their mobile
            number and Password as well if they signed up using their social
            accounts, they have to login via social account.

            <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/7Forgot.png" alt="Forgot"  width="200px" />
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/8Password.png" alt="Password"  width="200px" />
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/3OTP.png" alt="OTP"  width="200px" />

              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/9Update_pass.png" alt="Update"  width="200px" />
            </div>

            <div class="static-subtitle">Forgot Password:</div>
     
            When you are not able to recollect the password, you can use the
            Forgot Password flow. Click on the Forgot Password option. Enter the
            mobile number and input the One Time Password received and resets
            the password.

            <div class="static-subtitle">
              Create the Player Profile in Resa Padel:
            </div>
            After successful sign up, the application will ask the user to fill
            the basic profile details and onboarding questions.
            <br /> <br />
            Both the basic profile details and onboarding questions are
            mandatory to fill in and based on the user’s response, the system
            will automatically generate the initial player level.

             <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/10Player_level.png" alt="Playerlevel"  width="200px" />
            </div>

            <div class="static-subtitle">
              What is User Player Level:
            </div>
            When the user signs up, the application will show the list of
            questions and based on the user’s response, the application will
            calculate the player level and it will be updated based on the
            competitive match results.
          `,
  },
  howtoReserveCourt: {
    title: "HOW TO RESERVE A COURT?",
    content: `<div class="static-subtitle">
            Selecting the Match details:
            </div>
 
            When the user turns on the location, and clicks on the Book a Court
            option, the application will display the form where the user can
            choose the match type (Singles or Doubles) and other details like
            Gender, match time, Friendly/Competitive. 
            <br /><br />

            Note: If the match type is provided as Private then the others will
            not be able to see the match except the invited ones in the
            Organizer end.
            <br />


             <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/11Config.png" alt="Config"  width="200px" />
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/12Config_2.png" alt="Config2"  width="200px" />
            </div>

            <div class="static-subtitle">
              Listing of Clubs based on User’s current location or by user’s
              search:
            </div>
  
            The application will show the list of clubs based on the user’s
            current location and by default Distance filter will be applied
            within 15 km range from the user’s current location. <br />
            <br />

            If the user wants to choose any specific club or location then they
            can search for it and the searched results will be displayed.


            <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/13club-list.png" alt="clublist"  width="200px" />
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/14club-filter.png" alt="clubfilter"  width="200px" />
            </div>
            
     
            <div class="static-subtitle">
              Selecting the booking date and time:
            </div>
         
            (Users are allowed to book “n” as configurable in advance on the app
            side).
            <br />
            <br />
            When the user chooses a date, the application will list down the
            available time slots and time slots will be shown in Morning,
            Afternoon and evening. Time slots before 12 pm are displayed in the
            morning and between 12 pm to 5 pm will be displayed in the afternoon
            and from 5 pm, the slots will be displayed in the evening slots.
            <br /> <br />

            After choosing the date and time, the courts available in the
            respective club on the specific date and time will be displayed and
            the user can choose the court and proceed to the check out page. In
            the checkout page, the application will show the payment summary and
            the booking details along with the option to choose the extras if
            they want to purchase.
            <br /><br />

            Purchasing extras will be done by selecting the product and the
            quantity. For every purchase and booking, the tax of 20% will be
            added. 
            <br /><br />

            Note: all the ordered extras will be separately shown in the Orders
            page with its details.

            <div class="static-subtitle">
              What is User Player Level:
            </div>
          
            When the user signs up, the application will show the list of
            questions and based on the user’s response, the application will
            calculate the player level and it will be updated based on the
            competitive match results.

            <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/15court.png" alt="court"  width="200px" />
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/16court-1.png" alt="court1"  width="200px" />
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/17iPhone-13-mini-24.png" alt="17iPhone"  width="200px" />
            </div>
            
         `,
  },
  payment: {
    title: "PAYMENT",
    content: `Users can add the Payment method for Payment. Following are the
            Payment options available in the application.

            <ul style="list-style: number">
            <li>Card Payment</li>
            <li>Payment using Gymlib</li>
            <li>Apple Pay</li>
            </ul>
            <div class="static-subtitle">Payment by Card:</div>

            When the user wants to make the card Payment, the user needs to add
            the card by providing the required information like Name on Card,
            Card Number, Expiry date. Once it is added, the user confirms to
            pay, the application will debit the amount for the Payment and the
            booking will be confirmed. <br />
            <br />
            On successful confirmation of booking, the user will receive the
            confirmation email for the same and for every transaction happened
            in the stripe, the application will trigger the email communication
            as well.

            <div class="static-subtitle">
            Payment using Gymlib:
            </div>

            When the user wants to make the Payment by Gymlib, the user needs to
            key in the Gymlib code. The code will be validated by Gymlib and if
            it is valid, booking will be confirmed.

            <div class="static-subtitle">
            Payment using Apple Pay:
            </div>

            The user needs to choose the Payment method as Apple Pay and confirm
            the booking details and authenticate the transaction. In this way,
            booking can be confirmed using Apple Pay.

            <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/18payment.png" alt="payment"  width="200px" />
            </div>

            <div class="static-subtitle">
            Membership plans for court booking:
            </div>
            Membership plans are available in 2 ways
            <ul style="list-style: number">
              <li>
              Monthly Membership (Current billing date will be the plan
              purchased date. Next billing date will the billing date of next
              month)
              </li>
              <li>
              Annual Membership. (current billing date will be the plan
              purchased date and next billing date will be the billing date of
              next year)
              </li>
            </ul>
            Users can choose the Plan category and the available plans in the
            specific category will be displayed. Plans can be purchased as per
            the user’s wish (either Monthly or Annually).
            <br />
            <br />
            Users are allowed to purchase the plan (one time purchase) as well
            as if they wish, they can subscribe to it (Recurring Payment). If
            the user purchases the plan, the plan confirmation email will be
            sent to the user. Also the reminder email will be sent to the user
            before the user’s plan will expire. (5 days before the plan
            expiration date).
            <br />
            <div class="static-subtitle">Benefits of Purchasing Membership:</div>
            <ul>
              <li>
              1 free session up to 90 mins per day till the plan expiration
              date.
              </li>
              <li>No Payment transaction is needed for every booking. </li>
            </ul>

            At a time, the users can have 2 free sessions in scheduled status
            and once any of the free sessions is completed then the user can
            book again using membership. If the user wants to make a booking on
            the same day where he has already had the free session then he needs
            to pay for the booking.

            <div class="static-subtitle">
            What is User Player Level:
            </div>

            When the user signs up, the application will show the list of
            questions and based on the user’s response, the application will
            calculate the player level and it will be updated based on the
            competitive match results.


             <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/19prouser-1.png" alt="payment"  width="200px" />
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/20prouser-2.png" alt="payment"  width="200px" />
            </div>
           `,
  },
  howtoJointheMatch: {
    title: "HOW TO JOIN THE MATCH:",
    content: `<div class="static-subtitle">
                Choosing the club where they want to play
              </div>
              The application will show the list of clubs based on the user’s
              current location and by default Distance filter will be applied
              within 15 km range from the user’s current location.
              <br />
              <br />
              If the user wants to choose any specific club or location then they
              can search for it and the searched results will be displayed.

              <div class="static-subtitle">
                Choosing the Match date and timings:
              </div>
              When the users want to join the match, the user can choose the club
              and the list of matches (Publicly created by other users in the
              particular club) will be displayed.
              <br />
              In the list of matches, the user can see the following details
              <ul>
                <li>Players information</li>
                <li>Match Level</li>
                <li>Match timing and other details like Gender, club details.</li>
              </ul>

              Users should select the position where they want to play and if the
              user’s player level falls within the range of Match level, the user
              can join the match immediately.
              <br />  <br />
              If the user’s player level is outside of the Match level, then the
              user should send a request to the other players in the match. When
              all the other players approve the request then the user who sends
              the request should get notified.
              <br />  <br />
              If the user has not received the approval notification or the
              request has been approved but the user hasn’t confirmed the
              participation by paying the amount or during the request process if
              any of the other players who has joined the match and no positions
              are left empty then the user will not be allowed to join the
              particular match.

              <div class="static-subtitle">
              Confirming the participation in the match by making the payment
              successfully
              </div>

              Once the user selects their position, they will be directed to the
              checkout page, where they can proceed with payment for their spot
              (Match organizer has done the payment for his/her spot alone).
              <br />
              <br />

              If the organizer has reserved the court by paying the whole amount
              then the user will not be asking for any payment for match
              participation. 
              <br /><br />

              However, if the user wishes to purchase extras, they will need to
              pay for those separately, and a 20% tax will be added to each
              purchase. 
              <br /><br />

              Users can choose the payment method if it is already added or they
              can add the payment method and the booking will be confirmed when
              the payment is successful.

            <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/21join-match.png" alt="join-match"  width="200px" />
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/22join-match1.png" alt="join-match1"  width="200px" />
            </div>
          `,
  },
  howtoJointhePrivateMatch: {
    title: "HOW TO JOIN THE PRIVATE MATCH:",
    content: `When the organizer created the match privately, then only the users
            who got an invitation from the organizer can see the match details
            and join the match.
            <br />
            <br />
            Match details can be shared via the Resa Padel app internally or with
            any of the social media platforms installed in the user’s phone or
            from the contacts list.
            <br />
            <br />
            When the user shares the match details, then the application will
            send the match details in the respective app and when it is shared
            via the contacts, a text message will be shared. <br />
            <br />
            The invited users can join the match and the player level request
            approval process will remain the same as in the public matches.`,
  },
  howtoJointheTournament: {
    title: "HOW TO JOIN THE TOURNAMENT:",
    content: `<div class="static-subtitle">
                Listing of Tournaments:
              </div>
         
            When the user clicks on the book of the tournament, the application
            will list down the tournaments happening within 15 km distance from
            the current location.
            <br />
            <br />
            You can apply the filters to search for more tournaments happening
            outside of 15 km.
          
            <div class="static-subtitle">
              Joining the Tournament by user themselves without having any invite:
            </div>
         
            When the user chooses the tournament, the user needs to provide the
            license number and it is mandatory and at any point of time, the use
            <br /><br />

            User can update the license number after joining the tournament as
            well <br /><br />

            When the user wants to join the tournament, they should invite
            his/her partner to the tournament and after paying the amount, the
            user should be allowed to join the tournament. 
            <br /><br />

            When the user joins the tournament, the application will list down
            the particular tournament participation in My Bookings → Tournament
            screen. <br /><br />

            Initially it will be in scheduled status and once the current time
            crosses the Tournament start time, the application will move the
            particular booking to Inprogress and when the tournament end time is
            reached then the particular booking will be moved to completed
            status.
          
            <div class="static-subtitle">
              Joining the tournament after getting the invite:
            </div>
         
            When the user receives the tournament, the application will click on
            the notification and tournament details will be displayed. From the
            tournament details page, the user should be able to make the payment
            with or without entering the license number and when the payment is
            successful
          
            <div class="static-subtitle">
              Tournament Participation Declined:
            </div>
         
            Users should be notified when the admin declines the license number
            or when the admin shares any information, then the user needs to
            update the license number if needed and if it is still in declined
            status, the application will cancel the user’s participation.
            <br /><br />

            Even if the admin fails to verify the license number, the
            application will cancel the user’s participation before 4 hours of
            tournament start date and time.
          
            <div class="static-subtitle">
              Tournament Participation Confirmation:
            </div>
          
            When the admin approves the license number of the user, then the
            participation will be confirmed.
            <br /><br />
            
            Note: All the tournament updates will be handled outside of the
            Resa Padel.

            <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/23tour-licens.png" alt="tour-licens"  width="200px" />
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/24tour.png" alt="24tour"  width="200px" />
            </div>
          `,
  },
  howtoBookCoachingSession: {
    title: "HOW TO BOOK A COACHING SESSION",
    content: `<div class="static-subtitle">
            View the list of Clubs:
          </div>
       
            The application will show the list of clubs based on the user’s
            current location and by default Distance filter will be applied
            within 15 km range from the user’s current location.
            <br /><br />

            If the user wants to choose any specific club or location then they
            can search for it and the searched results will be displayed.
         
            <div class="static-subtitle">
              View the list of Coaches:
            </div>
   
            When the user chooses the club, the application will list down the
            coaches who are available in the clubs.
            <br />

            Users can choose to book “Public Coaching” as well as “Private
            Coaching” as per their wish and can choose the Coach.
  
            <div class="static-subtitle">
              Confirming the date and time of Coaching session:
            </div>
   
            For every type of coaching, the users can see the coaches who are
            available on any specific date and time, the users will be directed
            to the checkout page, where they can proceed with payment.
            <br />
            <br />

            However, if the user wishes to purchase extras, they will need to
            pay for those separately, and a 20% tax will be added to each
            purchase.
            <br />
            <br />

            Users can choose the payment method if it is already added or they
            can add the payment method. <br />
            <br />

            Coaching session will be confirmed when the payment is successful
            for Private Coaching whereas for the Group coaching, the application
            will hold the whole group coaching session amount and before “n”
            hours of the booking date and time, the application will debit the
            amount (Group coaching session amount / Total number of Confirmed
            participants) and release the remaining amount to the user’s
            account.

            <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/25coach.png" alt="coach"  width="200px" />
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/26coach1.png" alt="coach1"  width="200px" />
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/27coach2.png" alt="coach2"  width="200px" />
            </div>
        
            <div class="static-subtitle">
              Purchasing Coaching Products:
            </div>
         
            Users can purchase the coaching session credits by purchasing the
            specific plan what they preferred.
            <br /><br />

            Based on the coaching products purchased, the users are allowed to
            book the private coaching session for free with the coaches who are
            eligible for the particular plan. 
            <br /><br />

            If the coaches are outside of the purchased plan category then the
            users will be charged for the same. 
            <br /><br />

            For every coaching product purchase, the application will apply 20%
            tax on the same. 
            <br /><br />

            The users can book the coaching session for free and get the
            benefits of the free coaching session as a package.
            <br /><br />

            Note: This coaching product will not be used for Group Coaching
            Sessions.

            <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/17iPhone-13-mini-24.png" alt="coach"  width="200px" />
            </div>
        
`,
  },
  myBookings: {
    title: "MY BOOKINGS",
    content: `All the confirmed bookings of the Coaching, Tournament and Court
            bookings/Joining a match will be reflected in the My bookings screen
            in the respective tabs. <br /><br />

            When the bookings are scheduled and the current date and time
            doesn’t cross the booking date and time, then the user can see those
            bookings in the Scheduled status.
            <br /><br />

            Once the current date and time crosses the booking date and
            time, the application will move the bookings to the In progress
            status and when the current date and time crosses the booking end
            date and time, then it will be moved to the Completed status.

            <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/28booking1.png" alt="bookings1"  width="200px" />
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/29booking2.png" alt="bookings2"  width="200px" />
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/30booking3.png" alt="bookings3"  width="200px" />
            </div>

      
            <div class="static-subtitle">
              Match Score updates:
            </div>

            When the matches are moved to in progress/Completed tab, then any of
            the participants in the match are allowed to update the score on
            each set.
            <br /> <br />

            When any of the users starts inputting the score update then the
            other participants in the match will not be allowed to update. They
            can accept / Raise a conflict for the score updates.
            <br /><br />

            When the conflict has been raised, the application will allow the
            user who has inputted the score can update the score. <br />
            <br />

            If no users responded to the match score update, the application
            will automatically confirm the score after 6 hours from the match
            end time.

            <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/31result.png" alt="result"  width="200px" />
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/32result1.png" alt="result1"  width="200px" />
            </div>
     
            <div class="static-subtitle">
              Changing the Match type from Private to Public:
            </div>

         
            Organizers are allowed to change the Match type from Private to
            Public in the My bookings tab → Scheduled status. Once it is changed
            to public, all the users should get the visibility of the match and
            they can join too if they wish to join.

            <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/33private-match.png" alt="bookings1"  width="200px" />
            </div>
    
            <div class="static-subtitle">
              Canceling the Match:
            </div>

      
            End users can cancel his spot by clicking on the Cancel button in
            the My bookings→ booking details page in Scheduled status.
            <br />
            <br />

            If the users are canceling the booking before the allowed time to
            cancel as defined in the club policy, then the users will get the
            full refund and if it crosses the allowed time to cancel then no
            amount will be refunded whereas the booking will be moved to
            canceled status.


            <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/34cancel.png" alt="bookings1"  width="200px" />
            </div>

          <div class="static-subtitle">
            Rescheduling the coaching sessions:
          </div>
     
            End users can reschedule his/her coaching session by clicking on the
            Reschedule button in the My bookings→ Coaching details page in
            Scheduled status.
            <br />
            <br />
            
            If the users are rescheduling the booking, the application will show
            the coach details and his/her availability. Based on that, the user
            can choose the date and time to reschedule and when the session gets
            rescheduled, the booking will be displayed in the newly updated date
            and time.            
            <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/35Reschedule.png" alt="bookings1"  width="200px" />
            </div>
          `,
  },
};
