import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Box,
  IconButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { showToast } from "../../../../shared/util/toastHelper";
import ReactPlayer from "react-player";
import LinearProgress from "@mui/material/LinearProgress";
import {
  IFeedMediaUploadDialogProps,
  IMedia,
  IPresignedUrlResponse,
} from "../../models/FeedPageModels";
import { isEmpty } from "lodash";
import {
  generatePresignedUrl,
  uploadTos3PresignedUrl,
} from "../../apiService/awsS3Services";

const ModalFeedMedia: React.FC<IFeedMediaUploadDialogProps> = ({
  open,
  onClose,
  onUpload,
  maxImageSize = 10, // Default max image size is 10 MB
  maxVideoSize = 150, // Default max video size is 150 MB
  maxVideosPerPost = 5, // Default max videos per post is 5
  maxImagesPerPost = 10, // Default max images per post is 10
}) => {
  const { t } = useTranslation();
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [filePreviews, setFilePreviews] = useState<string[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);
  const [retryFileIndex, setRetryFileIndex] = useState<number[] | null>(null);
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  useEffect(() => {
    const totalSize = selectedFiles.reduce((acc, file) => acc + file.size, 0);
    const bytesUploaded = (uploadProgress / 100) * totalSize;
    const uploadedPercent = (bytesUploaded / totalSize) * 100;

    if (uploadedPercent >= 100) {
      // Reset progress after upload completes
      setUploadProgress(0);
    }
  }, [uploadProgress, selectedFiles]);

  const handleClose = () => {
    onClose();
    setSelectedFiles([]);
    setRetryFileIndex(null);
  };

  // TODO refactor this function
  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!uploading) {
      if (e.target.files) {
        const files = Array.from(e.target.files);

        // Validate file type and size for image, video, and text
        const validFiles = files?.filter((file) => {
          const fileType = file?.type?.toLowerCase();
          const isImage =
            fileType === "image/jpeg" ||
            fileType === "image/png" ||
            fileType === "image/jpg";
          const isVideo =
            fileType === "video/mp4" ||
            fileType === "video/mpeg" ||
            fileType === "video/quicktime";

          if (!isImage && !isVideo) {
            showToast("Unsupported file type", "Error");
            return false;
          }

          let maxFileSize = 5; // Default max size is 5 MB
          if (isImage) {
            maxFileSize = maxImageSize;
          } else if (isVideo) {
            maxFileSize = maxVideoSize;
          }

          const isValidSize = file.size <= maxFileSize * 1024 * 1024;

          if (!isValidSize) {
            showToast(
              `File size should be a maximum of ${maxFileSize} MB`,
              "Error"
            );
          }

          return isValidSize;
        });

        // Limit the number of videos to maxVideosPerPost
        const videoFiles = validFiles?.filter((file) =>
          file?.type?.startsWith("video")
        );

        const existingVideosCount = selectedFiles?.filter((file) =>
          file?.type?.startsWith("video")
        ).length;

        if (existingVideosCount + videoFiles.length > maxVideosPerPost) {
          showToast(
            `Maximum allowed number of videos is ${maxVideosPerPost}`,
            "Error"
          );
          return;
        }

        // Limit the number of images to maxImagesPerPost
        const imageFiles = validFiles?.filter((file) =>
          file?.type?.startsWith("image")
        );

        const existingImagesCount = selectedFiles?.filter((file) =>
          file?.type?.startsWith("image")
        ).length;

        if (existingImagesCount + imageFiles.length > maxImagesPerPost) {
          showToast(
            `Maximum allowed number of images is ${maxImagesPerPost}`,
            "Error"
          );
          return;
        }

        // Limit the number of files to 10
        const newSelectedFiles = selectedFiles?.concat(validFiles).slice(0, 10);

        if (newSelectedFiles.length > 10) {
          showToast("Maximum allowed number of files is 10", "Error");
        } else {
          setSelectedFiles(newSelectedFiles);

          // Read and set previews
          const filePreviewUrls: string[] = [];
          newSelectedFiles?.forEach((file) => {
            const reader = new FileReader();
            reader.onload = (e) => {
              if (e.target) {
                if (file.type.startsWith("image")) {
                  // For image files, use data URL
                  filePreviewUrls?.push(e.target.result as string);
                } else if (file.type.startsWith("video")) {
                  // For video files, use the actual file object
                  filePreviewUrls?.push(URL.createObjectURL(file));
                }
                setFilePreviews([...filePreviewUrls]);
              }
            };
            if (
              file.type.startsWith("image") ||
              file.type.startsWith("video")
            ) {
              reader.readAsDataURL(file);
            } else if (file.type.startsWith("text")) {
              reader.readAsText(file);
            }
          });
        }
      }
    }
  };

  const handleRemoveFile = (index: number) => {
    if (!uploading) {
      const updatedFiles = [...selectedFiles];
      updatedFiles.splice(index, 1);
      setSelectedFiles(updatedFiles);

      const updatedPreviews = [...filePreviews];
      updatedPreviews.splice(index, 1);
      setFilePreviews(updatedPreviews);
    }
  };

  // TODO: retry failed uploads

  const handleMediaUpload = async () => {
    const totalFiles = selectedFiles.length;
    if (totalFiles === 0 || uploading) {
      return;
    }
    setUploading(true);

    let localFileIndex = 0;
    const localMediaRecords: { [key: number]: any } = {};

    for (const file of selectedFiles) {
      try {
        const presignedUrlInput = {
          category: "feed",
          files: [
            {
              fileName: file.name,
              fileSize: file.size,
            },
          ],
        };

        const presignedUrlData: IPresignedUrlResponse | null =
          await generatePresignedUrl(presignedUrlInput);

        if (presignedUrlData && !isEmpty(presignedUrlData.data)) {
          const mediaRecord = presignedUrlData.data[0];
          localMediaRecords[localFileIndex] = mediaRecord as any;

          const s3UploadInput = {
            file,
            url: mediaRecord?.presignedUrl,
            setUploadProgress,
          };

          const resultFromUpload = await uploadTos3PresignedUrl(s3UploadInput);
          if (resultFromUpload?.status === 200) {
            showToast(`Media uploaded successfully ${file.name}`, "Success");
          }
        }
      } catch (error) {
        if (retryFileIndex === null) {
          setRetryFileIndex([localFileIndex]);
        } else {
          setRetryFileIndex([...retryFileIndex, localFileIndex]);
        }
        console.error("handleMediaUpload error", { error });
        localMediaRecords[localFileIndex] = { error: "Failed to upload" };
      }

      localFileIndex++;
    }

    const mediaRecordValues: IMedia[] = Object.values(localMediaRecords)
      .filter((record) => !isEmpty(record.mediaId))
      .map((record: any) => ({
        mediaId: record.mediaId,
        filePath: record.filePath,
        // mediaPath: record.filePath, // Add the mediaPath property
      }));

    onUpload(mediaRecordValues);
    setUploadProgress(100);
    setUploading(false);

    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {t("FileUpload")}
          <IconButton color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <input
          type="file"
          id="fileInput"
          accept="image/jpeg,image/jpg,image/png,video/mp4,video/mpeg,video/quicktime,text/plain"
          multiple
          style={{ display: "none" }}
          onChange={handleFileInputChange}
        />
        <label htmlFor="fileInput">
          <Button
            variant="outlined"
            component="span"
            sx={{ width: "100%" }}
            disabled={uploading}
          >
            {t("SelectFiles")}
          </Button>
        </label>
        <Box mt={2}>
          {selectedFiles?.map((file: any, index: any) => (
            <div key={file[index]}>
              {file?.type?.startsWith("image") ? (
                <img
                  src={filePreviews[index]}
                  alt={`Preview ${index + 1}`}
                  style={{
                    maxWidth: "100px",
                    maxHeight: "100px",
                    marginRight: "10px",
                  }}
                />
              ) : file?.type?.startsWith("video") ? (
                <div style={{ maxWidth: "100%", height: "auto" }}>
                  <ReactPlayer
                    url={filePreviews[index]}
                    controls
                    width="100%"
                    style={{ display: "block", margin: "0 auto" }}
                  />
                </div>
              ) : (
                <div>{filePreviews[index]}</div>
              )}
              {file?.name}

              <LinearProgress
                variant="determinate"
                value={uploadProgress}
                sx={{ width: "100%", marginTop: "10px" }}
              />

              <IconButton
                onClick={() => handleRemoveFile(index)}
                color="secondary"
                size="small"
                style={{ marginLeft: "10px" }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          ))}
        </Box>
        {/* TODO: refactor {retryFileIndex !== null && (
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleRetryUpload}
              disabled={uploading}
            >
              Retry Upload
            </Button>
          </Box>
        )} */}
        <Box mt={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleMediaUpload()}
            disabled={uploading || selectedFiles.length === 0}
          >
            {uploading ? `${t("Uploading")}...` : t("UploadMedia")}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ModalFeedMedia;
