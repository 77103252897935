import { useQuery } from "@tanstack/react-query";
import { fetchDataFromApi } from "../../Clubs/clubsApiService";
import { FEED_ITEMS_MANAGEMENT } from "../../../config/API.constants";

const listLocalityAPI = FEED_ITEMS_MANAGEMENT.API_END_POINTS.LOCALITY_LIST;

const useFetchLocalityLean = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: ["localityListLean"],
    queryFn: async () => {
      const result = await fetchDataFromApi(listLocalityAPI, 0, 1000000, true);

      const localityRowArr: any = (result as any[] | undefined)?.map(
        (locality: any) => ({
          id: locality?._id,
          name: locality?._id,
        })
      );

      return localityRowArr;
    },
    refetchOnWindowFocus: false,
    refetchInterval: false,
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchOnMount: true,
  });

  return { data, isLoading, error };
};

export default useFetchLocalityLean;
