import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Dispatch, AnyAction } from "redux";
import { isEmpty } from "lodash";

import { CUSTOM_ERROR_MSG, HANDLE_ERROR, HTTP_CALL } from "../../config/http";

import {
  GET,
  PATCH,
  CONFIGURATIONS_MANAGEMENT,
  USER_MANAGEMENT,
} from "../../config/API.constants";

import { showToast } from "../../shared/util/toastHelper";
import {
  updateConfigurationData,
  singleConfigurationReceived,
  singleConfigurationRequested,
  configurationsReset,
  getLoggedinUserReceived,
} from "app/redux/configurationsReducer";

type AppDispatch = Dispatch<AnyAction>;

export const getSingleConfiguration =
  (callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};
    dispatch(singleConfigurationRequested());

    try {
      response = await HTTP_CALL(
        `${CONFIGURATIONS_MANAGEMENT.GET_ALL_CONFIGURATIONS}`,
        GET,
        ""
      );

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          localStorage.setItem("settings", JSON.stringify(apiData));

          dispatch(singleConfigurationReceived(apiData));

          if (typeof callback === "function") {
            callback(apiData);
          }

          return apiData;
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

/**
 * Fetches data from the API.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const updateConfiguration =
  (id: string, values: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};

    try {
      response = await HTTP_CALL(
        `${CONFIGURATIONS_MANAGEMENT.UPDATE_CONFIGURATIONS}/${id}`,
        PATCH,
        "",
        values
      );

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (statusCode === 200) {
        showToast("Configuration updated successfully", "Success", {
          position: toast.POSITION.TOP_RIGHT,
        });

        if (!isEmpty(apiData)) {
          localStorage.setItem("settings", JSON.stringify(apiData));
          dispatch(updateConfigurationData(apiData));

          if (typeof callback === "function") {
            callback(apiData);
          }

          return apiData;
        } else {
          showToast("No Data", "Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

export const getProfile =
  (userType = "admin", callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};
    //dispatch(getLoggedinUserRequested());

    try {
      response = await HTTP_CALL(
        `${
          userType === "coach"
            ? USER_MANAGEMENT.API_END_POINTS.GET_CUSTOMER_DETAILS
            : USER_MANAGEMENT.API_END_POINTS.GET_ADMIN_DETAILS
        }`,
        GET,
        ""
      );

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          localStorage.setItem("profileData", JSON.stringify(apiData));

          dispatch(getLoggedinUserReceived(apiData));

          if (typeof callback === "function") {
            callback(apiData);
          }

          return apiData;
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

export const resetData = () => async (dispatch: AppDispatch) => {
  dispatch(configurationsReset());
};
