import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container } from "@mui/material";

//import GeneralInformation from "./GeneralInformation";
// import UserResponsibilities from "./UserResponsibilities";
// import PurchaseProcess from "./PurchaseProcess";


// import { PrivacyEN } from "app/languages/en/privacy-translation";
// import { PrivacyES } from "app/languages/es/privacy-translation";
import { PrivacyFR } from "app/languages/fr/privacy-translation";
import MentionsLegales from "./MentionsLegales";
import PrivacyData from "./PrivacyData";

const Privacy: React.FC = () => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const { lang } = useParams<{ lang: string }>();

  const selectedLanguage: string = localStorage.getItem('accessToken') ? i18n.language || lang || "en": lang || "en";

  useEffect(() => {
    // Set the i18n language if it's not the same as the selected language
    if (selectedLanguage && i18n.language !== selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
    }

    // Navigate to a route with the selected language if needed
    // navigate(`/Privacy/${selectedLanguage}`);
  }, [selectedLanguage, i18n, navigate]);

  // let PrivacyContent =
  //   selectedLanguage === "es"
  //     ? PrivacyES
  //     : selectedLanguage === "fr"
  //     ? PrivacyFR
  //     : PrivacyEN;

    let PrivacyContent = PrivacyFR;

  return (
    <>
      <Container maxWidth="md" sx={{ marginTop: "2rem", marginBottom: "2rem" }}>
        <PrivacyData PrivacyContent={PrivacyContent} />
      </Container>
    </>
  );
};

export default Privacy;
