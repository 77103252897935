import { IMAGE_BASE_PATH_BUCKET_URL } from "../../config/constants";

export const HelpES = {
  howToSignup: {
    title: "CÓMO REGISTRARSE EN RESA PADEL?",
    content: `Puedes iniciar sesión en Resa Padel de dos maneras:
            <br/>

            <ul style="list-style: number">
              <li>Registrándote con el número de móvil</li>
              <li>Registrándote con cuentas sociales</li>
            </ul>
            <div class="static-subtitle">
              Registro con el número de móvil
            </div>
            Para crear una cuenta, los usuarios deben proporcionar su código de país junto con su número de móvil. 
            
            <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/1Signup.png" alt="signup"  width="200px" />
            </div>

            <br/>
            Al ingresar un número de móvil válido, se enviará una contraseña de un solo uso (OTP) al usuario por mensaje de texto.
            <br/>

            <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/2Otp_msg.png" alt="Otp message"  width="200px" />
            </div>

            <div class="static-subtitle">
              Proceso de verificación
            </div>
            Después de recibir el OTP, los usuarios deben ingresarlo en la aplicación. Si el OTP es válido, la aplicación solicitará al usuario que establezca una contraseña. Deben proporcionar una nueva contraseña y confirmarla ingresándola nuevamente. Una vez que la contraseña se confirma con éxito, los usuarios podrán iniciar sesión en la aplicación.
            <br />
            
            <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/3OTP.png" alt="Otp"  width="200px" />
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/4create_pass.png" alt="Create Password"  width="200px" />
            </div>

            <br />

            Para inicios de sesión posteriores, el usuario debe recordar esta contraseña para iniciar sesión.

             <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/5Entermobile.png" alt="Enter Mobile"  width="200px" />
            </div>
          `,
  },
  howToSignSocialAccounts: {
    title: "¿CÓMO REGISTRARSE UTILIZANDO CUENTAS SOCIALES?",
    content: `El usuario necesita autenticar el inicio de sesión en la cuenta social y después de una 
            autenticación exitosa, la aplicación permitirá al usuario iniciar sesión.
            <br /><br />

            Cuentas sociales como <b>Gmail, Facebook, LinkedIn, Apple</b>

            <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/6Social.png" alt="Social"  width="200px" />
            </div>

            <div class="static-subtitle">Inicio de sesión:</div>
            Los usuarios registrados iniciarán sesión proporcionando su número de móvil y contraseña. Si se registraron utilizando sus cuentas sociales, deben iniciar sesión a través de la cuenta social.

            <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/7Forgot.png" alt="Forgot"  width="200px" />
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/8Password.png" alt="Password"  width="200px" />
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/3OTP.png" alt="OTP"  width="200px" />

              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/9Update_pass.png" alt="Update"  width="200px" />
            </div>

            <div class="static-subtitle">Olvidé mi contraseña:</div>
            Cuando no puedas recordar la contraseña, puedes utilizar el flujo de Olvidé mi contraseña. Haz clic en la opción Olvidé mi contraseña. Ingresa el número de móvil y el código de un solo uso recibido para restablecer la contraseña.

            <div class="static-subtitle">Crear el perfil del jugador en Resa Padel:</div>
            Después de un registro exitoso, la aplicación pedirá al usuario que complete los detalles básicos del perfil y las preguntas de incorporación.
            <br /><br />
            Tanto los detalles básicos del perfil como las preguntas de incorporación son obligatorios y, basándose en las respuestas del usuario, el sistema generará automáticamente el nivel inicial del jugador.

            <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/10Player_level.png" alt="Playerlevel"  width="200px" />
            </div>

            <div class="static-subtitle">¿Qué es el nivel de jugador del usuario?</div>

            Cuando el usuario se registre, la aplicación mostrará la lista de preguntas y, basándose en las respuestas del usuario, la aplicación calculará el nivel del jugador y este se actualizará en función de los resultados de los partidos competitivos.
          `,
  },
  howtoReserveCourt: {
    title: "¿CÓMO RESERVAR UN TRIBUNAL?",
    content: `<div class="static-subtitle">
            Selección de los detalles del partido:
          </div>
          Cuando el usuario activa la ubicación y hace clic en la opción Reservar una cancha, la aplicación muestra el formulario donde el usuario puede elegir el tipo de partido (Individual o Dobles) y otros detalles como género, hora del partido, Amistoso/Competitivo.
          <br /><br />

          Nota: Si el tipo de partido se establece como Privado, los demás no podrán ver el partido excepto los invitados en la organización.
          <br />

          <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/11Config.png" alt="Config"  width="200px" />
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/12Config_2.png" alt="Config2"  width="200px" />
          </div>

          <div class="static-subtitle">
            Listado de clubes según la ubicación actual del usuario o por búsqueda del usuario:
          </div>
          La aplicación mostrará la lista de clubes según la ubicación actual del usuario y por defecto, se aplicará el filtro de distancia dentro de un rango de 15 km desde la ubicación actual del usuario.
          <br /><br />

          Si el usuario quiere elegir un club o una ubicación específica, puede buscarlo y se mostrarán los resultados de la búsqueda.

          <div class="image-container">
            <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/13club-list.png" alt="clublist"  width="200px" />
            <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/14club-filter.png" alt="clubfilter"  width="200px" />
          </div>

          <div class="static-subtitle">
            Selección de la fecha y hora de la reserva:
          </div>
          (Los usuarios pueden reservar con "n" días de antelación, configurables en la aplicación).
          <br /><br />

          Cuando el usuario elige una fecha, la aplicación lista los horarios disponibles. Los horarios se mostrarán en la mañana, la tarde y la noche. Los horarios antes de las 12 pm se muestran por la mañana, entre las 12 pm y las 5 pm se mostrarán por la tarde y a partir de las 5 pm, los horarios se mostrarán por la noche.
          <br /><br />

          Después de elegir la fecha y la hora, se mostrarán las canchas disponibles en el club respectivo en la fecha y hora específicas y el usuario podrá elegir la cancha y proceder a la página de pago. En la página de pago, la aplicación mostrará el resumen del pago y los detalles de la reserva junto con la opción de elegir los extras si desean comprarlos.
          <br /><br />

          La compra de extras se realizará seleccionando el producto y la cantidad. Para cada compra y reserva, se añadirá un impuesto del 20 %.
          <br /><br />

          Nota: Todos los extras pedidos se mostrarán por separado en la página de Pedidos con sus detalles.

          <div class="static-subtitle">
            ¿Qué es el nivel de jugador del usuario?:
          </div>
          Cuando el usuario se registra, la aplicación mostrará la lista de preguntas y, basándose en las respuestas del usuario, la aplicación calculará el nivel del jugador y este se actualizará en función de los resultados de los partidos competitivos.

            <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/15court.png" alt="court"  width="200px" />
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/16court-1.png" alt="court1"  width="200px" />
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/17iPhone-13-mini-24.png" alt="17iPhone"  width="200px" />
            </div>
         `,
  },
  payment: {
    title: "PAGO",
    content: `Los usuarios pueden agregar un método de pago para realizar el pago. A continuación, se presentan las opciones de pago disponibles en la aplicación:

          <ul style="list-style: number">
            <li>Pago con tarjeta</li>
            <li>Pago usando Gymlib</li>
            <li>Apple Pay</li>
          </ul>
          <div class="static-subtitle">Pago con tarjeta:</div>
          Cuando el usuario desea realizar un pago con tarjeta, debe agregar la tarjeta proporcionando la información requerida, como el nombre en la tarjeta, el número de la tarjeta y la fecha de vencimiento. Una vez agregada la tarjeta y confirmado el pago, la aplicación debitará el monto para el pago y la reserva será confirmada.
          <br /><br />
          Una vez confirmada la reserva, el usuario recibirá un correo electrónico de confirmación. Para cada transacción realizada en Stripe, la aplicación también enviará una comunicación por correo electrónico.

          <div class="static-subtitle">Pago usando Gymlib:</div>
          Cuando el usuario desea realizar el pago con Gymlib, debe ingresar el código de Gymlib. El código será validado por Gymlib y, si es válido, se confirmará la reserva.

          <div class="static-subtitle">Pago usando Apple Pay:</div>
          El usuario debe elegir Apple Pay como método de pago, confirmar los detalles de la reserva y autenticar la transacción. De esta manera, se puede confirmar la reserva usando Apple Pay.

          <div class="image-container">
            <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/18payment.png" alt="payment"  width="200px" />
          </div>

          <div class="static-subtitle">Planes de membresía para la reserva de canchas:</div>
          Los planes de membresía están disponibles de dos maneras:
          <ul style="list-style: number">
            <li>
              Membresía mensual (La fecha de facturación actual será la fecha de compra del plan. La próxima fecha de facturación será la fecha de facturación del mes siguiente)
            </li>
            <li>
              Membresía anual (La fecha de facturación actual será la fecha de compra del plan y la próxima fecha de facturación será la fecha de facturación del año siguiente)
            </li>
          </ul>
          Los usuarios pueden elegir la categoría de plan y se mostrarán los planes disponibles en la categoría específica. Los planes se pueden comprar según el deseo del usuario (mensual o anual).
          <br /><br />
          Los usuarios pueden comprar el plan (compra única) o, si lo desean, suscribirse a él (pago recurrente). Si el usuario compra el plan, se enviará un correo electrónico de confirmación del plan. También se enviará un correo electrónico de recordatorio al usuario antes de que expire el plan (5 días antes de la fecha de vencimiento).
          <br />

          <div class="static-subtitle">Beneficios de comprar la membresía:</div>
          <ul>
            <li>1 sesión gratuita de hasta 90 minutos por día hasta la fecha de vencimiento del plan.</li>
            <li>No se necesita una transacción de pago para cada reserva.</li>
          </ul>
          En cualquier momento, los usuarios pueden tener 2 sesiones gratuitas en estado programado. Una vez que se complete una de las sesiones gratuitas, el usuario puede reservar nuevamente utilizando la membresía. Si el usuario desea realizar una reserva el mismo día en que ya ha tenido la sesión gratuita, deberá pagar por la reserva.

          <div class="static-subtitle">¿Qué es el nivel de jugador del usuario?</div>
          Cuando el usuario se registra, la aplicación mostrará la lista de preguntas y, basándose en las respuestas del usuario, la aplicación calculará el nivel del jugador y este se actualizará en función de los resultados de los partidos competitivos.

          <div class="image-container">
            <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/19prouser-1.png" alt="payment"  width="200px" />
            <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/20prouser-2.png" alt="payment"  width="200px" />
          </div>
           `,
  },
  howtoJointheMatch: {
    title: "CÓMO UNIRSE AL PARTIDO:",
    content: `<div class="static-subtitle">
                Elegir el club donde quieren jugar
              </div>
              La aplicación mostrará la lista de clubes basados en la ubicación actual del usuario y, por defecto, se aplicará un filtro de distancia dentro de un rango de 15 km desde la ubicación actual del usuario.
              <br />
              <br />
              Si el usuario quiere elegir un club o una ubicación específicos, puede buscarlo y se mostrarán los resultados de la búsqueda.

              <div class="static-subtitle">
                Elegir la fecha y horarios del partido:
              </div>
              Cuando los usuarios quieren unirse al partido, pueden elegir el club y se mostrará la lista de partidos (creados públicamente por otros usuarios en el club en particular).
              <br />
              En la lista de partidos, el usuario puede ver los siguientes detalles:
              <ul>
                <li>Información de los jugadores</li>
                <li>Nivel del partido</li>
                <li>Horario del partido y otros detalles como género, detalles del club, etc.</li>
              </ul>

              Los usuarios deben seleccionar la posición en la que quieren jugar y, si el nivel del jugador se encuentra dentro del rango del nivel del partido, el usuario puede unirse al partido inmediatamente.
              <br />  <br />

              Si el nivel del jugador está fuera del nivel del partido, entonces el usuario debe enviar una solicitud a los otros jugadores en el partido. Cuando todos los demás jugadores aprueben la solicitud, el usuario que envió la solicitud recibirá una notificación.
              <br />  <br />

              Si el usuario no ha recibido la notificación de aprobación o la solicitud ha sido aprobada pero el usuario no ha confirmado la participación pagando el monto o durante el proceso de solicitud alguno de los otros jugadores se ha unido al partido y no quedan posiciones vacías, entonces el usuario no podrá unirse al partido en particular.

              <div class="static-subtitle">
                Confirmar la participación en el partido realizando el pago con éxito
              </div>

              Una vez que el usuario selecciona su posición, será dirigido a la página de pago, donde puede proceder con el pago para su lugar (el organizador del partido ha realizado el pago solo por su lugar).
              <br />
              <br />

              Si el organizador ha reservado la cancha pagando el monto total, entonces no se le pedirá al usuario que realice ningún pago para participar en el partido.
              <br /><br />

              Sin embargo, si el usuario desea comprar extras, deberá pagarlos por separado y se añadirá un impuesto del 20% a cada compra.
              <br /><br />

              Los usuarios pueden elegir el método de pago si ya está añadido o pueden añadir el método de pago y la reserva se confirmará cuando el pago sea exitoso.

             <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/21join-match.png" alt="join-match"  width="200px" />
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/22join-match1.png" alt="join-match1"  width="200px" />
             </div>
          `,
  },
  howtoJointhePrivateMatch: {
    title: "HOW TO JOIN THE PRIVATE MATCH:",
    content: `Cuando el organizador crea el partido de forma privada, solo los usuarios que reciban una invitación del    organizador pueden ver los detalles del partido y unirse al mismo.
      <br />
      <br />
      Los detalles del partido pueden compartirse a través de la aplicación Resa Padel internamente o con cualquiera de las plataformas de redes sociales instaladas en el teléfono del usuario o desde la lista de contactos.
      <br />
      <br />
      Cuando el usuario comparte los detalles del partido, la aplicación enviará los detalles del partido en la aplicación correspondiente y cuando se comparta a través de los contactos, se enviará un mensaje de texto.
      <br />
      <br />
      Los usuarios invitados pueden unirse al partido y el proceso de aprobación del nivel de jugador seguirá siendo el mismo que en los partidos públicos.`,
  },
  howtoJointheTournament: {
    title: "HOW TO JOIN THE TOURNAMENT:",
    content: `<div class="static-subtitle">
              Listado de Torneos:
            </div>
            Cuando el usuario hace clic en el libro del torneo, la aplicación listará los torneos que se realizan dentro de un radio de 15 km desde la ubicación actual.
            <br /><br />
            Puede aplicar filtros para buscar más torneos que se realicen fuera de los 15 km.

            <div class="static-subtitle">
              Unirse al Torneo por sí mismo sin tener invitación:
            </div>
            Cuando el usuario elige el torneo, debe proporcionar el número de licencia, lo cual es obligatorio en cualquier momento.
            <br /><br />
            El usuario puede actualizar el número de licencia después de unirse al torneo también.
            <br /><br />
            Cuando el usuario quiera unirse al torneo, debe invitar a su pareja al torneo y después de pagar el importe, se le permitirá unirse al torneo.
            <br /><br />
            Cuando el usuario se une al torneo, la aplicación listará la participación en el torneo en Mis Reservas → Pantalla de Torneos.
            <br /><br />
            Inicialmente estará en estado programado y una vez que el tiempo actual cruce la hora de inicio del torneo, la aplicación moverá la reserva particular a En progreso y cuando se alcance la hora de finalización del torneo, la reserva particular se moverá a estado completado.

            <div class="static-subtitle">
              Unirse al torneo después de recibir la invitación:
            </div>
            Cuando el usuario recibe el torneo, la aplicación hará clic en la notificación y se mostrarán los detalles del torneo. Desde la página de detalles del torneo, el usuario debería poder realizar el pago con o sin ingresar el número de licencia y cuando el pago sea exitoso.

            <div class="static-subtitle">
              Participación en el Torneo Rechazada:
            </div>
            Los usuarios deben ser notificados cuando el administrador rechace el número de licencia o cuando el administrador comparta alguna información, entonces el usuario necesita actualizar el número de licencia si es necesario y si aún está en estado rechazado, la aplicación cancelará la participación del usuario.
            <br /><br />
            Incluso si el administrador no verifica el número de licencia, la aplicación cancelará la participación del usuario antes de 4 horas de la fecha y hora de inicio del torneo.

            <div class="static-subtitle">
              Confirmación de Participación en el Torneo:
            </div>
            Cuando el administrador apruebe el número de licencia del usuario, la participación será confirmada.
            <br /><br />
            Nota: Todas las actualizaciones del torneo se manejarán fuera de Resa Padel.

            <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/23tour-licens.png" alt="tour-licens"  width="200px" />
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/24tour.png" alt="24tour"  width="200px" />
            </div>
          `,
  },
  howtoBookCoachingSession: {
    title: "HOW TO BOOK A COACHING SESSION",
    content: `<div class="static-subtitle">
                Ver la lista de Clubes:
              </div>
              La aplicación mostrará la lista de clubes según la ubicación actual del usuario y, por defecto, se aplicará el filtro de Distancia dentro de un rango de 15 km desde la ubicación actual del usuario.
              <br /><br />

              Si el usuario desea elegir algún club o ubicación específica, puede buscarlo y se mostrarán los resultados de la búsqueda.

              <div class="static-subtitle">
                Ver la lista de Entrenadores:
              </div>
              Cuando el usuario elige el club, la aplicación listará los entrenadores que están disponibles en los clubes.
              <br />

              Los usuarios pueden optar por reservar "Entrenamiento Público" así como "Entrenamiento Privado" según su deseo y pueden elegir al Entrenador.

              <div class="static-subtitle">
                Confirmación de la fecha y hora de la sesión de Entrenamiento:
              </div>

              Para cada tipo de entrenamiento, los usuarios pueden ver a los entrenadores que están disponibles en una fecha y hora específicas, los usuarios serán dirigidos a la página de pago, donde podrán proceder con el pago.
              <br /><br />

              Sin embargo, si el usuario desea comprar extras, deberá pagarlos por separado, y se añadirá un 20% de impuestos a cada compra.
              <br /><br />

              Los usuarios pueden elegir el método de pago si ya está añadido o pueden agregar el método de pago.
              <br /><br />

              La sesión de entrenamiento se confirmará cuando el pago sea exitoso para el Entrenamiento Privado, mientras que para el entrenamiento en grupo, la aplicación retendrá el importe total de la sesión de entrenamiento en grupo y antes de "n" horas de la fecha y hora de la reserva, la aplicación debitará el importe (Importe de la sesión de entrenamiento en grupo / Número total de participantes confirmados) y liberará el importe restante a la cuenta del usuario.

              <div class="image-container">
                <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/25coach.png" alt="coach"  width="200px" />
                <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/26coach1.png" alt="coach1"  width="200px" />
                <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/27coach2.png" alt="coach2"  width="200px" />
              </div>

              <div class="static-subtitle">
                Compra de Productos de Entrenamiento:
              </div>
              Los usuarios pueden comprar créditos de sesiones de entrenamiento adquiriendo el plan específico que prefieren.
              <br /><br />

              Según los productos de entrenamiento adquiridos, los usuarios pueden reservar la sesión de entrenamiento privado de forma gratuita con los entrenadores que sean elegibles para el plan en particular.
              <br /><br />

              Si los entrenadores están fuera de la categoría del plan adquirido, se cobrará a los usuarios por ello.
              <br /><br />

              Para cada compra de productos de entrenamiento, la aplicación aplicará un 20% de impuestos sobre el mismo.
              <br /><br />

              Los usuarios pueden reservar la sesión de entrenamiento de forma gratuita y obtener los beneficios de la sesión de entrenamiento gratuita como un paquete.
              <br /><br />

              Nota: Este producto de entrenamiento no se utilizará para Sesiones de Entrenamiento en Grupo.

              <div class="image-container">
                <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/17iPhone-13-mini-24.png" alt="coach"  width="200px" />
              </div>
`,
  },
  myBookings: {
    title: "MIS RESERVAS",
    content: `Todas las reservas confirmadas de Entrenamiento, Torneo y Reserva de Canchas/Unirse a un partido se reflejarán en la pantalla de Mis reservas en las pestañas correspondientes.
            <br /><br />

            Cuando las reservas están programadas y la fecha y hora actual no cruzan la fecha y hora de la reserva, el usuario puede ver esas reservas en estado Programado.
            <br /><br />

            Una vez que la fecha y hora actual cruce la fecha y hora de la reserva, la aplicación moverá las reservas al estado En progreso y cuando la fecha y hora actual cruce la fecha y hora de finalización de la reserva, se moverán al estado Completado.

            <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/28booking1.png" alt="bookings1"  width="200px" />
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/29booking2.png" alt="bookings2"  width="200px" />
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/30booking3.png" alt="bookings3"  width="200px" />
            </div>

            <div class="static-subtitle">
              Actualizaciones del Marcador del Partido:
            </div>
            Cuando los partidos se muevan a la pestaña En progreso/Completado, cualquiera de los participantes del partido podrá actualizar el marcador de cada set.
            <br /><br />

            Cuando cualquiera de los usuarios comience a introducir la actualización del marcador, los demás participantes en el partido no podrán actualizarlo. Pueden aceptar/Presentar un conflicto por las actualizaciones del marcador.
            <br /><br />

            Cuando se presenta un conflicto, la aplicación permitirá al usuario que haya introducido el marcador actualizarlo.
            <br /><br />

            Si ningún usuario responde a la actualización del marcador del partido, la aplicación confirmará automáticamente el marcador 6 horas después de la hora de finalización del partido.

            <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/31result.png" alt="result"  width="200px" />
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/32result1.png" alt="result1"  width="200px" />
            </div>

            <div class="static-subtitle">
              Cambiar el tipo de Partido de Privado a Público:
            </div>
            Los organizadores pueden cambiar el tipo de Partido de Privado a Público en la pestaña Mis reservas → estado Programado. Una vez que se cambie a público, todos los usuarios deberían poder ver el partido y pueden unirse si lo desean.

            <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/33private-match.png" alt="bookings1"  width="200px" />
            </div>

            <div class="static-subtitle">
              Cancelar el Partido:
            </div>
            Los usuarios finales pueden cancelar su lugar haciendo clic en el botón Cancelar en Mis reservas → página de detalles de la reserva en estado Programado.
            <br /><br />

            Si los usuarios cancelan la reserva antes del tiempo permitido para cancelar según lo definido en la política del club, recibirán el reembolso completo y si se supera el tiempo permitido para cancelar, no se reembolsará ningún monto, mientras que la reserva se moverá al estado cancelado.

            <div class="static-subtitle">
              Reprogramar las sesiones de entrenamiento:
            </div>
            Los usuarios finales pueden reprogramar su sesión de entrenamiento haciendo clic en el botón Reprogramar en Mis reservas → página de detalles del entrenamiento en estado Programado.
            <br /><br />

            Si los usuarios están reprogramando la reserva, la aplicación mostrará los detalles del entrenador y su disponibilidad. Basado en eso, el usuario puede elegir la fecha y hora para reprogramar y cuando la sesión sea reprogramada, la reserva se mostrará en la fecha y hora actualizadas.
          `,
  },
};
