import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useQueryClient } from "@tanstack/react-query";
import {
  useLocationQuery,
  useLocationQuerySet,
} from "app/shared/util/useLocationQuery";

const ContentContainer = styled("div")`
  flex: 1;
  padding: ${(props) => props.theme.spacing(2)};
  backgroudcolor: "#FFF";
`;

const InstagramAuthPage: React.FC = () => {
  const searchParams = useLocationQuery();
  const [accessTokenApiDone, setAccessTokenApiDone] = useState(true);

  const queryClient = useQueryClient();

  function postToInstagram(
    caption: any,
    code: any,
    clientId: any,
    clientSecret: any,
    redirectUrl: any
  ) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      const url = `https://api.instagram.com/oauth/access_token`;

      xhr.open("POST", url, true);

      // Set the Content-Type header for form data
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
      // Set CORS headers (although they might not be necessary for XMLHttpRequest)
      //xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
      // xhr.setRequestHeader(
      //   "Access-Control-Allow-Headers",
      //   "Content-Type, Accept"
      // );

      // Handle the response
      xhr.onreadystatechange = function () {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === 200) {
            const response = JSON.parse(xhr.responseText);
            console.log("Media Response:", response);
            localStorage.setItem("Instagram", response);
            resolve(response);
          } else {
            console.error("Error posting to Instagram:", xhr.statusText);
            reject(xhr.statusText);
          }
        }
      };

      xhr.onerror = function () {
        console.error("Network error");
        reject("Network error");
      };

      // Prepare the request body
      const params = new URLSearchParams({
        caption: caption,
        code: code,
        client_id: clientId,
        client_secret: clientSecret,
        grant_type: "authorization_code",
        redirect_uri: redirectUrl,
      }).toString();

      // Send the request with parameters in the body
      xhr.send(params);
    });
  }

  const getInstagramAccessToken = async (code?: string) => {
    let caption: any = "sdsdsds";

    const clientId = "437774082455260";
    const clientSecret = "933a83336cdbb645cf2c87caf306190b";
    const redirectUrl = "http://localhost:3000/instagram-auth";
    //const redirectUrl = "https://cpadmin-dev.casapadel.fr/instagram-auth";

    try {
      // Example usage

      //   {
      //     caption: caption,
      //     code,
      //     client_id: clientId,
      //     client_secret: clientSecret,
      //     grant_type: "grant_type",
      //     redirect_uri: redirectUrl,
      //   },

      postToInstagram(caption, code, clientId, clientSecret, redirectUrl)
        .then((mediaResponse) => {
          console.log(mediaResponse);
        })
        .catch((error) => {
          console.error(error);
        });

      // Step 1: Upload media to Instagram
      // const mediaResponse: any = await axios.post(
      //   `https://api.instagram.com/oauth/access_token`, // Replace with your Instagram user ID
      //   {
      //     caption: caption,
      //     code,
      //     client_id: clientId,
      //     client_secret: clientSecret,
      //     grant_type: "grant_type",
      //     redirect_uri: redirectUrl,
      //   },
      //   {
      //     headers: {
      //       "x-api-key": undefined,
      //       "Access-Control-Allow-Origin": "*",
      //       "Access-Control-Allow-Headers":
      //         "Origin, X-Requested-With, Content-Type, Accept",
      //     },
      //   }
      // );
    } catch (error) {
      console.error("Error uploading media", error);
    }
  };

  useEffect(() => {
    if (searchParams?.code && accessTokenApiDone) {
      //getInstagramAccessToken(searchParams?.code);
      setAccessTokenApiDone(false);
    }
  }, [searchParams?.code]);

  return <ContentContainer></ContentContainer>;
};

export default InstagramAuthPage;
