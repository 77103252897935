import React from "react";
import { Button } from "@mui/material";

interface ICustomButton {
  style?: any;
  onClick?: any;
  sx?: any;
  btnText?: any;
  type?: any;
}

const CustomButton: React.FC<ICustomButton> = ({
  style,
  onClick,
  sx,
  btnText,
  type,
}) => {
  return (
    <Button
      type={type === "submit" ? "submit" : "button"}
      variant="contained"
      style={{
        ...style,
        backgroundColor: "#000000",
        padding: "15px",
        fontWeight: 600,
        fontSize: "12px",
        lineHeight: "normal",
        borderRadius: "4px",
        textTransform: "capitalize",
        fontFamily: "Poppins",
      }}
      onClick={onClick}
      fullWidth
      sx={sx}
    >
      {btnText}
    </Button>
  );
};

export default CustomButton;
