import React from 'react'
import MentionsLegales from './MentionsLegales';

const LegalConditionsData = (legalConditionsContent:any = null) => {
  const legalData = legalConditionsContent.legalConditionsContent;
  return (
    <MentionsLegales
          title={legalData?.mentionsLegales?.title}
          content={legalData?.mentionsLegales?.content}
        />
  )
}

export default LegalConditionsData