import React from "react";
import { Typography, Box } from "@mui/material";

interface PageProps {
  title: string;
  content?: any;
}

const Payment: React.FC<PageProps> = ({ title, content }: any) => {
  return (
    <Box sx={{ marginTop: "2rem" }}>
      <Typography
        gutterBottom
        variant="h6"
        sx={{
          fontWeight: 500,
          marginBottom: "1rem",
          textTransform: "uppercase",
        }}
      >
        {title}
      </Typography>

      <Box>
        <Typography
          component="div"
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      </Box>

      {/* {lang === "fr" ? (
        <Box>
          <Typography>
            All purchase orders received by Resa Padel through the Website are
            subject to the Payment of the products and / or that no circumstance
            or cause of force majeure (clause nine of these Conditions) affects
            the supply of the same and / or the provision of services. If there
            are difficulties in the supply of products or no products remain in
            stock, Resa Padel undertakes to contact the User and reimburse any
            amount that may have been paid as an amount. This will be equally
            applicable in cases in which the rendering of a service becomes
            unrealizable.
          </Typography>
        </Box>
      ) : (
        <Box>
          <Typography>
            Users can add the Payment method for Payment. Following are the
            Payment options available in the application.
          </Typography>{" "}
          <ul style={{ listStyle: "number" }}>
            <li>Card Payment</li>
            <li>Payment using Gymlib</li>
            <li>Apple Pay</li>
          </ul>
          <Typography className="static-subtitle">Payment by Card:</Typography>
          <Typography>
            When the user wants to make the card Payment, the user needs to add
            the card by providing the required information like Name on Card,
            Card Number, Expiry date. Once it is added, the user confirms to
            pay, the application will debit the amount for the Payment and the
            booking will be confirmed. <br />
            <br />
            On successful confirmation of booking, the user will receive the
            confirmation email for the same and for every transaction happened
            in the stripe, the application will trigger the email communication
            as well.
          </Typography>{" "}
          <Typography className="static-subtitle">
            Payment using Gymlib:
          </Typography>{" "}
          <Typography>
            When the user wants to make the Payment by Gymlib, the user needs to
            key in the Gymlib code. The code will be validated by Gymlib and if
            it is valid, booking will be confirmed.
          </Typography>{" "}
          <Typography className="static-subtitle">
            Payment using Apple Pay:
          </Typography>{" "}
          <Typography>
            The user needs to choose the Payment method as Apple Pay and confirm
            the booking details and authenticate the transaction. In this way,
            booking can be confirmed using Apple Pay.
          </Typography>{" "}
          <Typography className="static-subtitle">
            Membership plans for court booking:
          </Typography>
          <Typography>Membership plans are available in 2 ways</Typography>
          <ul style={{ listStyle: "number" }}>
            <li>
              Monthly Membership (Current billing date will be the plan
              purchased date. Next billing date will the billing date of next
              month)
            </li>
            <li>
              Annual Membership. (current billing date will be the plan
              purchased date and next billing date will be the billing date of
              next year)
            </li>
          </ul>
          <Typography>
            Users can choose the Plan category and the available plans in the
            specific category will be displayed. Plans can be purchased as per
            the user’s wish (either Monthly or Annually).
            <br />
            <br />
            Users are allowed to purchase the plan (one time purchase) as well
            as if they wish, they can subscribe to it (Recurring Payment). If
            the user purchases the plan, the plan confirmation email will be
            sent to the user. Also the reminder email will be sent to the user
            before the user’s plan will expire. (5 days before the plan
            expiration date).
            <br />
            <br />
            Benefits of Purchasing Membership:
          </Typography>
          <ul>
            <li>
              1 free session up to 90 mins per day till the plan expiration
              date.
            </li>
            <li>No Payment transaction is needed for every booking. </li>
          </ul>
          <Typography>
            At a time, the users can have 2 free sessions in scheduled status
            and once any of the free sessions is completed then the user can
            book again using membership. If the user wants to make a booking on
            the same day where he has already had the free session then he needs
            to pay for the booking.
          </Typography>
          <Typography className="static-subtitle">
            What is User Player Level:
          </Typography>
          <Typography>
            When the user signs up, the application will show the list of
            questions and based on the user’s response, the application will
            calculate the player level and it will be updated based on the
            competitive match results.
          </Typography>
        </Box>
      )} */}
    </Box>
  );
};

export default Payment;
