// src/redux/apparelsReducer.ts
import { createSlice } from "@reduxjs/toolkit";

const extrasSlice = createSlice({
  name: "extras",
  initialState: { data: [], purchases: [], loading: false, error: null },
  reducers: {
    extrasRequested: (state) => {
      state.loading = true;
      state.error = null;
    },
    extrasReceived: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    extrasRequestFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateExtraProductData: (state: any, action) => {
      const updatedData = state?.data?.data?.map((x: any) =>
        x?._id === action?.payload?._id
          ? { ...x, ...action.payload, updated: true }
          : x
      );

      return {
        ...state,
        loading: false,
        data: { ...state?.data, data: updatedData },
      };
    },
    singleExtraProductReceived: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    extrasPurchaseRequested: (state) => {
      state.loading = true;
      state.error = null;
    },
    extrasPurchaseReceived: (state, action) => {
      state.loading = false;
      state.purchases = action.payload;
    },
    extrasPurchaseRequestFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    singleExtraPurchaseReceived: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    extrasReset: (state) => {
      state.loading = false;
      state.data = [];
    },
    purchasesReset: (state) => {
      state.loading = false;
      state.purchases = [];
    },
  },
});

export const {
  extrasRequested,
  extrasReceived,
  extrasRequestFailed,
  updateExtraProductData,
  singleExtraProductReceived,
  extrasPurchaseRequested,
  extrasPurchaseReceived,
  extrasPurchaseRequestFailed,
  singleExtraPurchaseReceived,
  extrasReset,
  purchasesReset,
} = extrasSlice.actions;

export default extrasSlice.reducer;
