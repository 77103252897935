import React, { useState } from "react";
import { Box, Tabs, Tab, TabScrollButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

interface ClubProductTabsProps {
  products: Array<any>;
}

const StyledTabScrollButton = styled(TabScrollButton)({
  "&.Mui-disabled": {
    opacity: 0.3,
  },
});

export const ClubProductTabs: React.FC<ClubProductTabsProps> = ({
  products,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleNext = () => {
    setValue((prevValue) => Math.min(prevValue + 1, products?.length - 1));
  };

  const handlePrevious = () => {
    setValue((prevValue) => Math.max(prevValue - 1, 0));
  };

  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <StyledTabScrollButton
          direction="left"
          onClick={handlePrevious}
          disabled={value === 0}
          orientation="horizontal"
        />
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable tabs"
        >
          {products?.map((product, index) => (
            <Tab key={index} label={product?.name} />
          ))}
        </Tabs>
        <StyledTabScrollButton
          direction="right"
          onClick={handleNext}
          disabled={value === products?.length - 1}
          orientation="horizontal"
        >
          <ArrowForwardIcon />
        </StyledTabScrollButton>
      </Box>
      <Box
        sx={{
          marginTop: "20px",
          display: "flex",
          overflowX: "scroll",
          columnGap: "25px",
          paddingBottom: "10px",
        }}
        className="horizontal-scroll"
      >
        {products?.[value]?.availableSizes?.map((x: any, index: number) => (
          <Box
            key={x}
            sx={{
              borderRadius: "4px",
              border: "1px solid #E4E4E4",
              background: "#FAFAFB",
              padding: "10px 15px",
              maxWidth: "130px",
            }}
          >
            <Typography sx={{ color: "#727272" }}>Size</Typography>
            <Typography
              sx={{
                margin: "5px 0",
                fontSize: "26px",
                fontWeight: 600,
                lineHeight: "normal",
              }}
            >
              {x?.size || "Default"}
            </Typography>
            <Typography sx={{ color: "#333" }}>
              {x?.availableUnits} quantity
            </Typography>
            <Typography
              sx={{
                color: "#219653",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "normal",
              }}
            >
              €{x?.unitPrice ? parseFloat(x?.unitPrice).toFixed(2) : "NA"}
            </Typography>
          </Box>
        ))}
      </Box>
    </>
  );
};
