import React from "react";
import AuthenticationScreenFields from "../../shared/components/authenticationScreenFields";
import { SUCCESS_PASSWORD_CHANGE_PAGE_TITLE } from "../../config/constants";

const SuccessPassword: React.FC = () => {
  return (
    <AuthenticationScreenFields
      screenType={SUCCESS_PASSWORD_CHANGE_PAGE_TITLE}
    />
  );
};

export default SuccessPassword;
