import React from 'react'
import GeneralInformation from "./GeneralInformation";
import CommonTitleContent from "./CommonTitleContent";

const GeneralConditionsData = (termsConditionsContent: any = null) => {
  const termsData = termsConditionsContent?.termsConditionsContent;
  return (
    <>
      <GeneralInformation
            title={termsData?.generalInfo?.title}
            content={termsData?.generalInfo?.content}
          />
          <CommonTitleContent
            title={termsData?.acceptanceAndModification?.title}
            content={termsData?.acceptanceAndModification?.content}
          />
          <CommonTitleContent
            title={termsData?.accountcreation?.title}
            content={termsData?.accountcreation?.content}
          />
          <CommonTitleContent
            title={termsData?.conditionsOfAccessToTheApplication?.title}
            content={termsData?.conditionsOfAccessToTheApplication?.content}
          />
          <CommonTitleContent
            title={termsData?.intellectualProperty?.title}
            content={termsData?.intellectualProperty?.content}
          />
          <CommonTitleContent
            title={termsData?.personalDataAndRespect?.title}
            content={termsData?.personalDataAndRespect?.content}
          />
          <CommonTitleContent
            title={termsData?.availabilityOfTheApplication?.title}
            content={termsData?.availabilityOfTheApplication?.content}
          />
          <CommonTitleContent
            title={termsData?.responsibility?.title}
            content={termsData?.responsibility?.content}
          />
          <CommonTitleContent
            title={termsData?.applicableLaw?.title}
            content={termsData?.applicableLaw?.content}
          />
        </>
  )
}

export default GeneralConditionsData