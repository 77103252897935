import { useEffect, useState } from "react";
import { Autocomplete, TextField, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { fetchExtraProductsList } from "app/entities/Extras/extrasApiService";
import { toCamelCase } from "app/shared/util/ToCamelCase";
import { AppDispatch } from "../../redux/store";
import { uniqBy } from "lodash";

export const AutoCompleteMultiSelect = ({
  name,
  label,
  onChange,
  value,
  multiple = false,
  placeHolder,
  apiName,
  disabled = false,
  apiCall = true,
  options = [],
  error = false,
}: any) => {
  const dispatch = useDispatch<AppDispatch>();
  const [datas, setDatas] = useState<any>([]);
  const [selectedDatas, setSelectedDatas] = useState<any>(multiple ? [] : null);

  useEffect(() => {
    setDatas(options);

    // Cleanup function
    return () => {
      setDatas([]);
    };
  }, [options]);

  // Fetch datas from API
  useEffect(() => {
    if (apiCall && apiName === "products")
      dispatch(
        fetchExtraProductsList(
          {
            pageSize: 1000,
            page: 1,
            filters: "status|active",
            filter_type: "extras",
          },
          (data: any) => {
            setDatas(uniqBy(data, "_id"));
          }
        )
      );
  }, []);

  // Map array of IDs to array of data objects
  useEffect(() => {
    if (value && !multiple) {
      // Check if it's a single selection
      // For single selection, find the data object corresponding to the selected ID
      const selectedDataObject: any | undefined = datas?.find(
        (data: any) => data?._id === value
      );
      setSelectedDatas(selectedDataObject || null);
    } else if (value && Array.isArray(value) && value?.length > 0) {
      // Check if it's a multiple selection
      const selectedDataObjects = datas?.filter((data: any) =>
        value?.includes(data?._id)
      );
      setSelectedDatas(selectedDataObjects);
    } else {
      setSelectedDatas(multiple ? [] : null);
    }

    // Cleanup function
    return () => {
      setSelectedDatas(null);
    };
  }, [value, datas, multiple]);

  // Handle change event
  const handleOnChange = (event: any, newValue: any) => {
    // Ensure newValue is always an array if multiple selections are allowed
    setSelectedDatas(newValue);

    onChange(
      multiple ? newValue?.map((data: any) => data?._id) : newValue?._id,
      newValue
    ); // Pass array of IDs to parent component
  };

  return (
    <>
      {label && (
        <Typography
          className="uppercase label"
          sx={{ marginBottom: "1rem", fontSize: "12px" }}
        >
          {label}
        </Typography>
      )}
      <Autocomplete
        id={name}
        options={datas}
        getOptionLabel={(option: any) => toCamelCase(option?.name)}
        multiple={multiple}
        value={selectedDatas}
        onChange={handleOnChange} // Pass the custom onChange handler
        disabled={disabled}
        renderInput={(params: any) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={placeHolder || "Select options"}
            error={error}
          />
        )}
      />
    </>
  );
};
