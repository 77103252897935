import React, { useState } from "react";
import {
  IconButton,
  Typography,
  Divider,
  Button,
  Grid,
  Box,
  Slider,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Form, Formik } from "formik";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";

import { Close as CloseIcon } from "@mui/icons-material";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";

import { FormSelectField } from "app/shared/atoms";
import { Gender, FilterByTag } from "app/config/constants";
import { enumtoArrayConverter } from "app/shared/util/common";

interface IFilterProps {
  callback?: any;
  defaultValue?: any;
}

const ButtonWrapper = styled("div")`
  display: flex;
  justify-content: flex-end;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiTypography-root {
    color: #727272;
  }
`;

const StyledSlider = styled(Slider)`
  .MuiSlider-rail {
    background-color: #cfd3d4;
  }
  &.MuiSlider-root {
    color: #333;
  }
`;

const brownButtonStyle = {
  backgroundColor: "#333333",
  color: "white", // Text color
};

export const BroadcastMessageDialog: React.FC<IFilterProps> = ({
  callback,
  defaultValue,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation();

  const MAX = 100;
  const MIN = 0;
  const minDistance = 2;

  const initialValues = {
    ...defaultValue,
  };

  const [open, setOpen] = React.useState(false);

  const [value1, setValue1] = React.useState<number[]>([10, 30]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  const handleReset = (resetForm: any) => (e: any) => {
    resetForm();

    callback();
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  function valuetext(value: number) {
    return `${value}`;
  }

  const handleChange1 = (
    event: Event,
    newValue: number | number[],
    activeThumb: number
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValue1([Math.min(newValue[0], value1[1] - minDistance), value1[1]]);
    } else {
      setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance)]);
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleClick}
        style={{
          cursor: "pointer",
          borderRadius: "4px",
          border: "1px solid #F2F2F2",
          color: "#727272",
        }}
      >
        <img src={FilterByTag} alt="filter by tags" />
      </Button>

      <Popper
        sx={{ zIndex: 1300 }}
        open={open}
        anchorEl={anchorEl}
        placement={"bottom-end"}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper style={{ padding: "30px", width: "500px" }}>
              <Grid container justifyContent={"space-between"}>
                <Grid>
                  <Typography variant="h6" style={{ flex: 1 }}>
                    {t("filterBy")}
                  </Typography>
                </Grid>
                <Grid>
                  <IconButton onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>

              <Divider style={{ marginTop: "1rem" }} />

              <Formik
                initialValues={initialValues}
                onSubmit={(values, actions) => {
                  callback({ ...values, ageRange: value1 });
                  handleClose();
                }}
                enableReinitialize
                //validationSchema={DateFilterValidationSchema}
              >
                {({ values, setFieldValue, resetForm }) => {
                  return (
                    <>
                      <Form>
                        <Box style={{ marginTop: "25px" }}>
                          <FormSelectField
                            label={t("gender")}
                            name="gender"
                            options={enumtoArrayConverter(Gender)}
                            isMultiple={true}
                            onChangeCallback={(val: any) =>
                              setFieldValue("gender", val)
                            }
                            value={values?.gender}
                          />
                        </Box>

                        <Grid item xs={12} sx={{ marginTop: "1rem" }}>
                          <StyledFormControlLabel
                            control={
                              <Checkbox
                                defaultChecked={values?.isProEmails}
                                onChange={(e) =>
                                  setFieldValue("isProEmails", e.target.checked)
                                }
                              />
                            }
                            label={"Pro emails"}
                          />
                        </Grid>

                        <Divider style={{ marginTop: "1rem" }} />

                        <Box sx={{ width: "100%", marginTop: "2rem" }}>
                          <Typography style={{ marginBottom: "1rem" }}>
                            {t("ageRange")}
                          </Typography>
                          <StyledSlider
                            getAriaLabel={() => "Minimum distance"}
                            value={value1}
                            onChange={handleChange1}
                            valueLabelDisplay="auto"
                            getAriaValueText={valuetext}
                            disableSwap
                            step={1}
                          />

                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              variant="body2"
                              //onClick={() => setVal(MIN)}
                              sx={{ cursor: "pointer" }}
                            >
                              {MIN}
                            </Typography>
                            <Typography
                              variant="body2"
                              //onClick={() => setVal(MAX)}
                              sx={{ cursor: "pointer" }}
                            >
                              {MAX}
                            </Typography>
                          </Box>
                        </Box>

                        <Grid
                          container
                          justifyContent={"flex-end"}
                          alignItems={"center"}
                          mt={"3rem"}
                        >
                          <Button
                            onClick={handleReset(resetForm)}
                            style={{
                              marginRight: "1rem",
                              color: "#EB5757",
                              fontWeight: 600,
                              textTransform: "capitalize",
                            }}
                          >
                            {t("resetFilters")}
                          </Button>
                          <ButtonWrapper>
                            <Button
                              variant="contained"
                              color="primary"
                              style={brownButtonStyle}
                              className="capitalize"
                              type="submit"
                            >
                              {t("applyFilters")}
                            </Button>
                          </ButtonWrapper>
                        </Grid>
                      </Form>{" "}
                    </>
                  );
                }}
              </Formik>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};
