/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import { Box, Button, Grid } from "@mui/material";

import { Form, Formik } from "formik";
import { useAuth } from "../../../../AuthContext";

import {
  CUSTOM_ERROR_MSG,
  CUSTOM_SUCCESS_MSG,
  HANDLE_ERROR,
  HTTP_CALL,
} from "../../../config/http";
import { POST, LOGIN } from "../../../config/API.constants";
import { USER_ROLE } from "../../../config/constants";
import { FormTextField } from "app/shared/atoms";
import { LoginValidationSchema } from "app/shared/validations/LoginValidationSchema";

interface ModalFormProps {
  callback?: any;
}

const brownButtonStyle = {
  backgroundColor: "#333333",
  color: "white", // Text color
};

const ChangePasswordForm: React.FC<ModalFormProps> = ({ callback }) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  /**
   * Handle Next on button click to load the next step if second page call the api
   *
   * @function
   * @returns void
   */
  const handleSubmit = async (values?: any, actions?: any) => {
    const changePasswordAPI =
      user?.userType === USER_ROLE.COACH || user?.userType === USER_ROLE.COACH
        ? LOGIN.COACH_USER_PASSWORD_CHANGE
        : LOGIN.ADMIN_USER_PASSWORD_CHANGE;
    let response: any = {};

    try {
      // Clear the session variable
      response = await HTTP_CALL(changePasswordAPI, POST, "", values);
      if (response.data.statusCode === 200) {
        if (response.data.data !== null) {
          CUSTOM_SUCCESS_MSG("Password successfully changed, Login again");
          setTimeout(() => {
            localStorage.removeItem("profileData");
            localStorage.removeItem("accessToken");
            localStorage.removeItem("username");
            localStorage.removeItem("settings");
            localStorage.removeItem("userType");

            window.location.href =
              user?.userType === USER_ROLE.COACH ? "/coach/signIn" : "/signIn";
          }, 2000);
        }
      } else {
        CUSTOM_ERROR_MSG(
          response.data.data.message
            ? response.data.data.message
            : response.data.statusCode
        );
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    } finally {
    }
    actions.setSubmitting(false);
  };

  return (
    <Box>
      <div>
        <Formik
          initialValues={{
            oldPassword: undefined,
            newPassword: undefined,
            confirmNewPassword: undefined,
          }}
          onSubmit={(values: any, actions) => {
            handleSubmit(values, actions);
          }}
          enableReinitialize
          validationSchema={LoginValidationSchema(`Change Password`)}
        >
          {({ values, errors, setFieldValue }: any) => {
            return (
              <>
                <Form>
                  <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    <Grid item xs={12}>
                      <FormTextField
                        label={t("oldPassword")}
                        name="oldPassword"
                        autoComplete="oldPassword"
                        placeholder={t("enterOldPassword")}
                        autoFocus
                        fullWidth
                        type="password"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormTextField
                        label={t("createNewPassword")}
                        name="newPassword"
                        autoComplete="createNewPassword"
                        placeholder={t("enterPassword")}
                        autoFocus
                        fullWidth
                        type="password"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormTextField
                        label={t("confirmNewPassword")}
                        name="confirmNewPassword"
                        autoComplete="confirmNewPassword"
                        placeholder={t("enterPassword")}
                        fullWidth
                        type="password"
                      />
                    </Grid>
                  </Grid>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid container spacing={2} style={{ marginTop: "15px" }}>
                      <Grid item xs={12} container justifyContent="flex-end">
                        <Button
                          variant="contained"
                          color="primary"
                          style={brownButtonStyle}
                          type="submit"
                          sx={{ textTransform: "capitalize" }}
                        >
                          {t("changePassword")}
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      </div>
    </Box>
  );
};

export default ChangePasswordForm;
