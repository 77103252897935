import _ from "lodash";

export const toCamelCase = (firstName?: any, lastName?: any) => {
  const capitalize = (str: string) => _.capitalize(_.lowerCase(str));
  if (lastName !== "") {
    return `${capitalize(firstName)} ${capitalize(lastName)}`;
  } else {
    return capitalize(firstName);
  }
};

export const getStatusLabel = (label: string, t?: any) => {
  // Convert "Delete" or "delete" to "Deleted"
  let status = label.toLowerCase() === "delete" ? "Deleted" : label;

  // Convert status to camelCase
  let newStatus: string = toCamelCase(status);

  // Ensure translation function is provided before calling it
  if (t && typeof t === "function") {
    return t(newStatus.trim()); // Translate and return the text or fallback
  } else {
    return newStatus; // Return the text without translation
  }
};
