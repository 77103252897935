import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isEmpty } from "lodash";
import {
  CUSTOM_ERROR_MSG,
  HANDLE_ERROR,
  HTTP_CALL,
} from "../../../config/http";
import { GET, CONSOLIDATED_USERS } from "../../../config/API.constants";
import { showToast } from "../../../shared/util/toastHelper";

export const fetchAllUsers = async () => {
  let response: any = {};
  const listAllEndUsersAPI = CONSOLIDATED_USERS.GET_ALL_USERS;
  const filteredSearchParams = new URLSearchParams({
    page: "1",
    limit: "10000",
    sort: "createdAt|DESC",
    filters: "status|active",
  });

  try {
    response = await HTTP_CALL(
      `${listAllEndUsersAPI}?${filteredSearchParams?.toString()}`,
      GET
    );
    const {
      data: { data: apiData, statusCode, message },
    } = response;

    if (statusCode === 200) {
      if (!isEmpty(apiData)) {
        return apiData;
      } else {
        showToast("Consolidated Users Data not available", "Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      CUSTOM_ERROR_MSG(message || statusCode);
    }
  } catch (error: any) {
    HANDLE_ERROR(error);
  }
};
