import { lazy, Suspense } from "react";
import { Routes, Route } from "react-router";
import { Navigate } from "react-router-dom";

import AuthenticatedLayout from "../entities/AuthenticatedLayout/AuthenticatedLayout";
import StaticPageLayout from "../entities/AuthenticatedLayout/StaticPageLayout";
import AuthenticationScreenFields from "app/shared/components/authenticationScreenFields";
import SignIn from "app/entities/SignIn/SignIn";
import ForgotPassword from "app/entities/ForgotPassword/ForgotPassword";
import OTPVerification from "app/entities/OTPVerification/OTPVerification";
import NewPassword from "app/entities/NewPassword/NewPassword";
import SuccessPassword from "app/entities/SuccessPassword/SuccessPassword";
import Help from "app/entities/Help/Help";
import Privacy from "app/entities/Privacy/Privacy";
//import FAQ from "app/entities/FAQ/FAQ";
//import HowToPlay from "app/entities/HowToPlay/HowToPlay";
import TermsConditions from "app/entities/TermsConditions/TermsConditions";
import TermsSales from "app/entities/TermsSales/TermsSales";
import LegalConditions from "app/entities/LegalConditions/LegalConditions";
import GeneralConditions from "app/entities/GeneralConditions/GeneralConditions";
import Transactions from "app/entities/Transactions/Transactions";

import FeedPage from "../entities/FeedPage/FeedPage";
import FeedTestPage from "../entities/FeedPage/FeedTestPage";
import FeedDetailPage from "../entities/FeedPage/FeedDetailPage";
import NewFacebookShare from "../entities/FeedPage/NewFacebookshare";
import InstagramAuthPage from "../entities/FeedPage/InstagramAuthPage";
import ChatMessages from "app/entities/Messages/Messages";
import ProfilePage from "app/entities/Profile/Profile";
import { USER_ROLE } from "app/config/constants";

// Lazy-loaded components

const Dashboard = lazy(() => import("app/entities/Dashboard/Dashboard"));

const Clubs = lazy(() => import("app/entities/Clubs/Clubs"));
const ClubDetailPage = lazy(() => import("app/entities/Clubs/ClubDetail"));

const Coaching = lazy(() => import("app/entities/Coaching/Coaching"));
const CoachingDetailPage = lazy(
  () => import("app/entities/Coaching/CoachingDetail")
);

const Overview = lazy(() => import("app/entities/Coaching/Overview"));

const Users = lazy(() => import("app/entities/Users/Users"));
const UserDetailPage = lazy(() => import("app/entities/Users/UserDetail"));

const Bookings = lazy(() => import("app/entities/Bookings/Bookings"));
const BookingDetailPage = lazy(
  () => import("app/entities/Bookings/BookingDetail")
);

const Tournaments = lazy(() => import("app/entities/Tournaments/Tournaments"));
const TournamentDetailPage = lazy(
  () => import("app/entities/Tournaments/TournamentDetail")
);

const Coupons = lazy(() => import("app/entities/Coupons/Coupons"));

const ExtrasPage = lazy(() => import("app/entities/Extras/Extras"));
const ExtrasDetailPage = lazy(() => import("app/entities/Extras/ExtrasDetail"));

const SessionsPage = lazy(() => import("app/entities/Sessions/Sessions"));
const SessionsDetailPage = lazy(
  () => import("app/entities/Sessions/SessionsDetail")
);

const ProductsPage = lazy(() => import("app/entities/CoachProducts/Products"));

const SubscriptionsPage = lazy(
  () => import("app/entities/Memberships/Memberships")
);
const SubscriptionsDetailPage = lazy(
  () => import("app/entities/Memberships/MembershipsDetail")
);

const TagsPage = lazy(() => import("app/entities/Tags/Tags"));

const ConfigurationsDetailPage = lazy(
  () => import("app/entities/Configurations/ConfigurationsDetail")
);

const DeepLinkingPage = lazy(
  () => import("app/entities/DeepLinking/DeepLinking")
);

export const UnauthenticatedRoutes = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/signIn" element={<SignIn />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/verifyOTP" element={<OTPVerification />} />
        <Route path="/:userType/signIn" element={<SignIn />} />
        <Route
          path="/app/*"
          element={
            <StaticPageLayout>
              <DeepLinkingPage />
            </StaticPageLayout>
          }
        />
        <Route
          path="/privacy/:lang"
          element={
            <StaticPageLayout>
              <Privacy />
            </StaticPageLayout>
          }
        />
        <Route
          path="/termsConditions/:lang"
          element={
            <StaticPageLayout>
              <TermsConditions />
            </StaticPageLayout>
          }
        />
        <Route
          path="/termsSales/:lang"
          element={
            <StaticPageLayout>
              <TermsSales />
            </StaticPageLayout>
          }
        />
        <Route
          path="/legalMentions/:lang"
          element={
            <StaticPageLayout>
              <LegalConditions />
            </StaticPageLayout>
          }
        />
        <Route
          path="/generalConditions/:lang"
          element={
            <StaticPageLayout>
              <GeneralConditions />
            </StaticPageLayout>
          }
        />

        {/* <Route path="/faq/:lang" element={<FAQ />} />
        <Route path="/howToPlay/:lang" element={<HowToPlay />} /> */}
        <Route
          path="/help/:lang"
          element={
            <StaticPageLayout>
              <Help />
            </StaticPageLayout>
          }
        />
        <Route
          path="/verifyOTP/:otp/:email"
          element={<AuthenticationScreenFields />}
        />
        <Route path="/feed/:id" element={<FeedDetailPage />} />
        <Route path="/newfeed/:id" element={<NewFacebookShare />} />
        <Route path="/changePassword" element={<NewPassword />} />
        <Route path="/successChangePassword" element={<SuccessPassword />} />
        <Route path="*" element={<Navigate to="/signIn" />} />
      </Routes>
    </Suspense>
  );
};

export const AuthenticatedRoutes = ({ userType }: any) => {
  return (
    <AuthenticatedLayout userType={userType}>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          {userType && userType === USER_ROLE.COACH ? (
            <>
              <Route index element={<SessionsPage />} />
              <Route path="/messages" element={<ChatMessages />} />
              <Route path="/profile" element={<ProfilePage />} />{" "}
              <Route path="/coachings" element={<Coaching />} />
              <Route path="/coachings/:id" element={<CoachingDetailPage />} />
              <Route path="*" element={<Navigate to="/" />} />
            </>
          ) : userType ? (
            <>
              <Route index element={<Dashboard />} />
              <Route path="/app/*" element={<DeepLinkingPage />} />
              <Route path="/clubs" element={<Clubs />} />
              <Route path="/clubs/:id" element={<ClubDetailPage />} />
              <Route path="/users" element={<Users />} />
              <Route path="/users/:id" element={<UserDetailPage />} />
              <Route path="/bookings" element={<Bookings />} />
              <Route path="/bookings/:id" element={<BookingDetailPage />} />
              <Route path="/coachings" element={<Coaching />} />
              <Route path="/coachings/:id" element={<CoachingDetailPage />} />
              <Route path="/court-bookings" element={<Overview />} />
              <Route path="/sessions" element={<SessionsPage />} />
              <Route path="/sessions/:id" element={<SessionsDetailPage />} />
              <Route path="/coach-products" element={<ProductsPage />} />
              <Route path="/feed" element={<FeedPage />} />
              <Route path="/feed-new" element={<FeedTestPage />} />
              <Route path="/feed/:id" element={<FeedDetailPage />} />
              <Route path="/newfeed/:id" element={<NewFacebookShare />} />
              <Route path="/instagram-auth" element={<InstagramAuthPage />} />
              <Route path="/tournaments" element={<Tournaments />} />
              <Route
                path="/tournaments/:id"
                element={<TournamentDetailPage />}
              />
              <Route path="/memberships" element={<SubscriptionsPage />} />
              <Route
                path="/memberships/:id"
                element={<SubscriptionsDetailPage />}
              />
              <Route path="/transactions" element={<Transactions />} />
              <Route path="/settings" element={<ConfigurationsDetailPage />} />
              <Route path="/tags" element={<TagsPage />} />
              <Route path="/coupons" element={<Coupons />} />
              <Route path="/coupon/:id" element={<BookingDetailPage />} />
              <Route path="/extras/*" element={<ExtrasPage />} />
              <Route path="/extras/:id" element={<ExtrasDetailPage />} />
              <Route path="/app/*" element={<div>Test</div>} />
              <Route path="/privacy/:lang" element={<Privacy />} />
              <Route
                path="/termsConditions/:lang"
                element={<TermsConditions />}
              />{" "}
               <Route
                path="/termsSales/:lang"
                element={<TermsSales />}
              />{" "}
              <Route
                path="/legalMentions/:lang"
                element={<LegalConditions />}
              />{" "}
              <Route
                  path="/generalConditions/:lang"
                  element={
                      <GeneralConditions />
                  }
                />
              {/* <Route path="/faq/:lang" element={<FAQ />} />
              <Route path="/howToPlay/:lang" element={<HowToPlay />} /> */}
              <Route path="/help/:lang" element={<Help />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="*" element={<Navigate to="/" />} />
            </>
          ) : null}
        </Routes>{" "}
      </Suspense>
    </AuthenticatedLayout>
  );
};
