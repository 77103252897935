import React from "react";
import { Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// Interface to have the props values
interface ICustomTypography {
  contentText?: any;
  element?: any;
  backToLogIn?: boolean;
}

const theme = createTheme();

theme.typography.h6 = {
  fontSize: "0.70rem",
  fontFamily: `"Helvetica"`,
  "@media (min-width:600px)": {
    fontSize: "0.85rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "0.85rem",
  },
};

const CustomTypography: React.FC<ICustomTypography> = ({
  contentText,
  element,
}) => {
  const primary = grey[400];
  return (
    <ThemeProvider theme={theme}>
      <Typography
        color={primary}
        sx={{
          fontSize: "14px",
          fontWeight: 400,
          color: "#727272",
          fontFamily: "Poppins",
        }}
      >
        {contentText} {element}
      </Typography>
    </ThemeProvider>
  );
};

export default CustomTypography;
