import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Grid,
  Divider,
  Paper,
  Link,
  Box,
  Button,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CustomTypography from "./CustomTypography";
import CustomButton from "./CustomButton";
import { FormTextField } from "app/shared/atoms";
import {
  LOGIN_PAGE_SUB_TITLE,
  FORGOT_PASSWORD_PAGE_SUB_TITLE,
  VERIFY_OTP_PAGE_SUB_TITLE,
  NEW_PASSWORD_PAGE_SUB_TITLE,
  SUCCESS_PASSWORD_CHANGE_PAGE_SUB_TITLE,
  FORGOT_PASSWORD,
  DIDNT_RECEIVED_OTP_TEXT,
  LoginCoverImage,
  CoachLoginCoverImage,
  LogoCasaPadel,
  LogoCoachCasaPadel,
  ForgotPasswordCoverImage,
  NewPasswordCoverImage,
  SuccessPasswordChangeCoverImage,
  SuccessPassword,
  USER_ROLE,
} from "../../config/constants";
import { POST, PUT, LOGIN } from "../../config/API.constants";
import { CUSTOM_ERROR_MSG, HANDLE_ERROR, HTTP_CALL } from "../../config/http";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showToast } from "../util/toastHelper";
import { useAuth } from "../../../AuthContext";
import { useLocationQuery } from "../../shared/util/useLocationQuery";
import { isTokenExpired, decodeToken } from "./authentication";
import FullPageLoader from "./FullPageLoader";
import { LoginValidationSchema } from "app/shared/validations/LoginValidationSchema";

// Interface to have the props values
interface IAuthenticationScreen {
  screenType?: any;
}

const Title = ({ text }: any) => {
  return (
    <Box
      sx={{
        fontSize: "26px",
        fontWeight: 600,
        letterSpacing: "-0.52px",
        margin: "3rem 0 0.5rem",
      }}
    >
      {text}
    </Box>
  );
};

const SubText = ({ text }: any) => {
  return (
    <Typography
      sx={{
        fontSize: "15px",
        marginBottom: "1rem",
        color: "#8F8F8F",
        fontFamily: "Poppins",
      }}
    >
      {text}
    </Typography>
  );
};

/* Function to dynamically load the form fields based on the screen type */
const AuthenticationScreenFields: React.FC<IAuthenticationScreen> = ({
  screenType,
}) => {
  const params = useParams();
  const { otp, email, userType } = useLocationQuery();
  const [initialValue, setInitialValue] = useState<any>();
  const [userEmail] = useState(email ? email : "");

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { login, setIsAuthenticated, setLoggedUserData } = useAuth();

  const imageArr = [
    {
      screenType: "Login",
      imgSrc:
        userType === "coach" || params?.userType === "coach"
          ? CoachLoginCoverImage
          : LoginCoverImage,
    },
    { screenType: "Forgot Password", imgSrc: ForgotPasswordCoverImage },
    { screenType: "Verify OTP", imgSrc: ForgotPasswordCoverImage },
    { screenType: "New Password", imgSrc: NewPasswordCoverImage },
    { screenType: "Change Password", imgSrc: NewPasswordCoverImage },
    {
      screenType: "Success Password Change",
      imgSrc: SuccessPasswordChangeCoverImage,
    },
  ];

  useEffect(() => {
    if (screenType === "Login") {
      setInitialValue({ username: undefined, password: undefined });
    } else if (screenType === "Forgot Password") {
      setInitialValue({ username: "" });
    } else if (screenType === "Verify OTP") {
      setInitialValue({ otp: otp || "" });
    } else {
      setInitialValue({
        email,
        otp,
        createNewPassword: "",
        confirmNewPassword: "",
      });
    }
  }, [otp, email, screenType]);

  const fetchCoverImage = (screenType: string) => {
    let imgSrc = "";
    imageArr?.forEach((ele, ind) => {
      if (ele?.screenType === screenType) {
        imgSrc = ele?.imgSrc;
      }
    });
    return imgSrc;
  };

  const handleSetNewPassword = async (values: any) => {
    const changePasswordAPI =
      userType === USER_ROLE.COACH || userType === USER_ROLE.COACH
        ? LOGIN.COACH_USER_PASSWORD_CHANGE
        : LOGIN.ADMIN_USER_PASSWORD_CHANGE;
    let response: any = {};

    try {
      const params = {
        email: values?.email || "",
        password: values?.createNewPassword,
        otp: values?.otp || "",
      };
      // Clear the session variable
      localStorage.removeItem("accessToken");
      response = await HTTP_CALL(changePasswordAPI, PUT, "", params);
      if (response.data.statusCode === 200) {
        if (response.data.data !== null) {
          const token = response?.data?.data?.accessToken;
          localStorage.setItem("accessToken", token);
          // Redirect to the success page
          navigate("/successChangePassword");
        }
      } else {
        CUSTOM_ERROR_MSG(
          response.data.data.message
            ? response.data.data.message
            : response.data.statusCode
        );
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    } finally {
      setLoading(false);
    }
  };

  const handleOTPVerification = async (values: any) => {
    const verifyOTPAPI =
      userType === USER_ROLE.COACH || userType === USER_ROLE.COACH
        ? LOGIN.COACH_VERIFY_OTP
        : LOGIN.VERIFY_OTP;
    let response: any = {};
    try {
      const params = {
        email: values?.email ? values?.email : userEmail,
        otp: values?.otp ? values?.otp : "",
      };
      response = await HTTP_CALL(verifyOTPAPI, POST, "", params);
      if (response.data.statusCode === 200) {
        if (response.data.data !== null) {
          navigate(
            "/changePassword?otp=" +
              values?.otp +
              "&email=" +
              userEmail +
              "&userType=" +
              userType || "admin"
          );
        }
      } else {
        CUSTOM_ERROR_MSG(
          response.data.data.message
            ? response.data.data.message
            : response.data.statusCode
        );
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = async (values?: any) => {
    const forgotPasswordAPI =
      userType === USER_ROLE.COACH || userType === USER_ROLE.COACH
        ? LOGIN.COACH_FORGOT_PASSWORD
        : LOGIN.ADMIN_FORGOT_PASSWORD;
    let response: any = {};
    try {
      const params = {
        email: userEmail || values?.username,
      };

      response = await HTTP_CALL(forgotPasswordAPI, POST, "", params);
      if (response.data.statusCode === 200) {
        if (response.data.data !== null) {
          showToast(
            "Your OTP has been successfully sent to your email",
            "Success",
            {
              position: toast.POSITION.BOTTOM_CENTER,
            }
          );
          navigate(
            `/verifyOTP?email=${userEmail||values?.username}&userType=${userType || "admin"}`
          );
        }
      } else {
        CUSTOM_ERROR_MSG(
          response.data.data.message
            ? response.data.data.message
            : response.data.statusCode
        );
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = async (values: any) => {
    try {
      await login(
        values?.username,
        values?.password,
        params?.userType || "admin"
      );
    } catch (error) {
      // Handle login error
      console.error("Login failed", error);
    } finally {
      setLoading(false);
    }
  };

  const handleContinueLogin = () => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken === null) {
      // Handle the case where accessToken is null
      // You may redirect the user to the login page or handle it as per your requirement
      navigate("/signIn");
      return;
    }

    if (isTokenExpired(accessToken)) {
      navigate("/signIn");
    } else {
      const decodedToken = decodeToken(accessToken);
      localStorage.setItem("userType", decodedToken?.userType);
      setLoggedUserData(decodedToken);
      navigate("/");
    }
  };

  let backToLoginUrl =
    params?.userType === USER_ROLE.COACH || userType === USER_ROLE.COACH
      ? "/coach/signIn"
      : "signIn";

  const BackToLogin = () => {
    return (
      <Box display="flex" alignItems="center">
        <Link
          href={backToLoginUrl}
          sx={{
            color: "#333333",
            textDecoration: "none",
            display: "flex",
            alignItems: "center",
            fontFamily: "Poppins",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
          }}
        >
          <KeyboardBackspaceIcon sx={{ marginRight: "5px" }} /> Back to Log In
        </Link>
      </Box>
    );
  };

  return (
    <Formik
      initialValues={initialValue}
      onSubmit={(values, actions) => {
        setLoading(true);
        if (screenType === "Login") {
          handleLogin(values); // Call your login function
        } else if (screenType === "Forgot Password") {
          handleForgotPassword(values);
        } else if (screenType === "Verify OTP") {
          handleOTPVerification(values);
        } else if (screenType === "New Password") {
          handleSetNewPassword(values);
        } else if (screenType === "Success Password Change") {
          handleContinueLogin();
          setIsAuthenticated(true);
        }
      }}
      enableReinitialize
      validationSchema={LoginValidationSchema(screenType)}
    >
      {() => {
        return (
          <>
            <Form>
              {loading && <FullPageLoader />}
              <Grid container>
                {/* Left Side: Login Form */}
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    spacing={2}
                    alignItems="center"
                    style={{ height: "100vh", margin: "0 auto", width: "79%" }}
                  >
                    <Paper
                      variant="elevation"
                      elevation={2}
                      style={{ width: "90%" }}
                      sx={{ boxShadow: "none" }}
                    >
                      <Grid item>
                        <img
                          src={
                            params?.userType === "coach" || userType === "coach"
                              ? LogoCoachCasaPadel
                              : LogoCasaPadel
                          }
                          alt="Resa Padel Logo"
                          width="157px"
                        />

                        <Divider
                          orientation="horizontal"
                          flexItem
                          sx={{ marginTop: "30px" }}
                        ></Divider>
                      </Grid>
                      {screenType === "Login" ? (
                        <>
                          <Grid item>
                            <Title text={"Hello Welcome!"} />
                          </Grid>
                          <Grid item>
                            <SubText text={LOGIN_PAGE_SUB_TITLE} />
                          </Grid>
                          <Grid item sx={{ marginTop: "2rem" }}>
                            <FormTextField
                              label="Email Address"
                              name="username"
                              autoComplete="username"
                              autoFocus
                              fullWidth
                            />
                          </Grid>
                          <Grid item sx={{ marginTop: "1.5rem" }}>
                            <FormTextField
                              label="Password"
                              name="password"
                              autoComplete="password"
                              type="password"
                              fullWidth
                            />
                          </Grid>

                          <Grid item sx={{ marginTop: "1.5rem" }}>
                            <CustomButton
                              type="submit"
                              sx={{ marginTop: "15px" }}
                              btnText="Login"
                            />
                          </Grid>
                          <Grid
                            item
                            sx={{ textAlign: "center", marginTop: "18px" }}
                          >
                            <CustomTypography
                              contentText={FORGOT_PASSWORD}
                              element={
                                <Link
                                  href={`/forgotPassword?userType=${
                                    params?.userType || "admin"
                                  }`}
                                  style={{
                                    color: "#333333",
                                    fontSize: "14px",
                                    textDecoration: "none",
                                    fontWeight: 600,
                                  }}
                                >
                                  Click Here
                                </Link>
                              }
                            />
                          </Grid>
                          <Grid
                            item
                            sx={{ textAlign: "center", marginTop: "18px" }}
                          >
                            <CustomTypography
                              contentText={""}
                              element={
                                <Link
                                  href={
                                    params?.userType === USER_ROLE.COACH
                                      ? `/signIn`
                                      : `/coach/signIn`
                                  }
                                  style={{
                                    color: "#333333",
                                    fontSize: "14px",
                                    textDecoration: "none",
                                    fontWeight: 600,
                                  }}
                                >
                                  {params?.userType === USER_ROLE.COACH
                                    ? "Admin"
                                    : "Coach"}{" "}
                                  Login
                                </Link>
                              }
                            />
                          </Grid>
                        </>
                      ) : screenType === "Forgot Password" ? (
                        <>
                          <Grid item>
                            <Title text={"Forgot your password?"} />
                          </Grid>
                          <Grid item>
                            <SubText text={FORGOT_PASSWORD_PAGE_SUB_TITLE} />
                          </Grid>

                          <Grid item sx={{ marginTop: "2rem" }}>
                            <FormTextField
                              label="Email Address"
                              name="username"
                              autoComplete="username"
                              autoFocus
                              fullWidth
                            />
                          </Grid>
                          <Grid item sx={{ marginTop: "1.5rem" }}>
                            <CustomButton
                              type="submit"
                              sx={{ marginTop: "15px" }}
                              btnText="Send OTP"
                            />
                          </Grid>
                          <Grid item sx={{ marginTop: "18px" }}>
                            <BackToLogin />
                          </Grid>
                        </>
                      ) : screenType === "Verify OTP" ? (
                        <>
                          <Grid item>
                            <Title text={"Enter Your OTP"} />
                          </Grid>
                          <Grid item>
                            <SubText text={VERIFY_OTP_PAGE_SUB_TITLE} />
                          </Grid>

                          <Grid item sx={{ marginTop: "4rem" }}>
                            <FormTextField
                              label="OTP"
                              name="otp"
                              autoComplete="otp"
                              autoFocus
                              fullWidth
                            />
                          </Grid>
                          <Grid item sx={{ marginTop: "1.5rem" }}>
                            <CustomButton
                              type="submit"
                              sx={{ marginTop: "15px" }}
                              btnText="Continue to create new password"
                            />
                          </Grid>
                          <Grid item sx={{ marginTop: "15px" }}>
                            <Grid container>
                              <Grid item xs={7}>
                                <Box display="flex" justifyContent="flex-start">
                                  <BackToLogin />
                                </Box>
                              </Grid>
                              <Grid item xs={5}>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      color: "#727272",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                    }}
                                  >
                                    {DIDNT_RECEIVED_OTP_TEXT}
                                  </Typography>
                                  <Button
                                    onClick={handleForgotPassword}
                                    style={{
                                      color: "#333333",
                                      fontWeight: 600,
                                      fontSize: "14px",
                                      textDecoration: "none",
                                    }}
                                  >
                                    RESEND OTP
                                  </Button>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      ) : screenType === "New Password" ? (
                        <>
                          <Grid item>
                            <Title text={"New Password"} />
                          </Grid>
                          <Grid item>
                            <SubText text={NEW_PASSWORD_PAGE_SUB_TITLE} />
                          </Grid>

                          <Grid item sx={{ marginTop: "2rem" }}>
                            <FormTextField
                              label="Create New Password"
                              name="createNewPassword"
                              autoComplete="createNewPassword"
                              placeholder="Enter password"
                              autoFocus
                              fullWidth
                              type="password"
                            />
                          </Grid>
                          <Grid item sx={{ marginTop: "2rem" }}>
                            <FormTextField
                              label="Confirm New Password"
                              name="confirmNewPassword"
                              autoComplete="confirmNewPassword"
                              placeholder="Enter password"
                              fullWidth
                              type="password"
                            />
                          </Grid>

                          <Grid item sx={{ marginTop: "1.5rem" }}>
                            <CustomButton
                              type="submit"
                              sx={{ marginTop: "15px" }}
                              btnText="Create New Password"
                            />
                          </Grid>
                          <Grid item sx={{ marginTop: "18px" }}>
                            <BackToLogin />
                          </Grid>
                        </>
                      ) : screenType === "Success Password Change" ? (
                        <>
                          <Grid item>
                            <Title text={"Congratulations!"} />
                          </Grid>
                          <Grid item>
                            <SubText
                              text={SUCCESS_PASSWORD_CHANGE_PAGE_SUB_TITLE}
                            />
                            <img
                              src={SuccessPassword}
                              alt="success password change"
                            />
                          </Grid>

                          <Grid item sx={{ marginTop: "1.5rem" }}>
                            <CustomButton
                              type="submit"
                              sx={{ marginTop: "15px" }}
                              btnText="Continue to Log in"
                            />
                          </Grid>
                        </>
                      ) : (
                        <></>
                      )}
                    </Paper>
                  </Grid>
                </Grid>

                {/* Right Side: Image */}
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <img
                    src={fetchCoverImage(screenType)}
                    alt="Login"
                    style={{
                      width: "100%",
                      height: "100vh",
                      objectFit: "cover",
                    }}
                  />
                </Grid>
              </Grid>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default AuthenticationScreenFields;
