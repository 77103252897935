// src/redux/coachingReducer.ts
import { createSlice } from "@reduxjs/toolkit";

const configurationSlice = createSlice({
  name: "configurations",
  initialState: { data: null, loading: false, error: null, profileData: null },
  reducers: {
    updateConfigurationData: (state: any, action) => {
      const updatedData = state?.data?.data?.map((x: any) =>
        x?._id === action?.payload?._id
          ? { ...x, ...action.payload, updated: true }
          : x
      );

      return {
        ...state,
        loading: false,
        data: { ...state?.data, data: updatedData },
      };
    },
    singleConfigurationRequested: (state) => {
      state.loading = true;
      state.error = null;
    },
    singleConfigurationReceived: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    singleConfigurationRequestFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    configurationsReset: (state) => {
      state.loading = false;
      state.data = null;
    },
    updateLoggedinUserData: (state: any, action) => {
      return {
        ...state,
        loading: false,
        profileData: action.payload,
      };
    },
    getLoggedinUserReceived: (state) => {
      state.loading = true;
      state.error = null;
    },
    getLoggedinUserRequested: (state, action) => {
      state.loading = false;
      state.profileData = action.payload;
    },
  },
});

export const {
  singleConfigurationRequested,
  singleConfigurationReceived,
  singleConfigurationRequestFailed,
  updateConfigurationData,
  configurationsReset,
  getLoggedinUserReceived,
  getLoggedinUserRequested,
} = configurationSlice.actions;

export default configurationSlice.reducer;
