import React, { useState } from "react";
import {
  IconButton,
  Typography,
  Divider,
  Button,
  Grid,
  Box,
  Slider,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Form, Formik } from "formik";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";

import { Close as CloseIcon } from "@mui/icons-material";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";

import { FormSelectField, FormAutocompleteField } from "app/shared/atoms";
import { Gender, FilterByTag } from "app/config/constants";
import { enumtoArrayConverter } from "app/shared/util/common";

interface IFilterProps {
  callback?: any;
  defaultValue?: any;
  clubsList?: any;
  localityList?: any;
}

const ButtonWrapper = styled("div")`
  display: flex;
  justify-content: flex-end;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiTypography-root {
    color: #727272;
  }
`;

const PrettySlider = styled(Slider)({
  color: "#333333",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#333333",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#333333",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

const brownButtonStyle = {
  backgroundColor: "#333333",
  color: "white", // Text color
};

export const FeedPostFilterDialog: React.FC<IFilterProps> = ({
  callback,
  defaultValue,
  clubsList,
  localityList,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation();

  const MAX = 100;
  const MIN = 0;
  const minDistance = 2;

  const initialValues = {
    ...defaultValue,
  };

  const [open, setOpen] = React.useState(false);

  const [value1, setValue1] = React.useState<any>([0, 0]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  const handleReset = (resetForm: any) => (e: any) => {
    resetForm();
    setValue1([0, 0]);
    callback();
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  function valuetext(value: number) {
    return `${value}`;
  }

  const handleChange1 = (
    event: Event,
    newValue: number | number[],
    activeThumb: number
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValue1([Math.min(newValue[0], value1[1] - minDistance), value1[1]]);
    } else {
      setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance)]);
    }
  };

  const localityOptions = localityList?.map(
    (x: any) =>
      (x = {
        value: x?._id || x?.id,
        label: x?._id || x?.id,
        title: x?._id || x?.id,
      })
  );

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleClick}
        style={{
          cursor: "pointer",
          borderRadius: "50%",
          border: "1px solid #F2F2F2",
          color: "#727272",
          height: "40px",
          width: "40px",
          minWidth: "40px",
        }}
      >
        <img src={FilterByTag} alt="filter by tags" />
      </Button>

      <Popper
        sx={{ zIndex: 1300 }}
        open={open}
        anchorEl={anchorEl}
        placement={"auto"}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper style={{ padding: "30px", width: "600px" }}>
              <Grid container justifyContent={"space-between"}>
                <Grid>
                  <Typography variant="h6" style={{ flex: 1 }}>
                    {t("filterBy")}
                  </Typography>
                </Grid>
                <Grid>
                  <IconButton onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>

              <Divider style={{ marginTop: "1rem" }} />

              <Formik
                initialValues={initialValues}
                onSubmit={(values, actions) => {
                  callback({ ...values, ageRange: value1 });
                  handleClose();
                }}
                enableReinitialize
                //validationSchema={DateFilterValidationSchema}
              >
                {({ values, setFieldValue, resetForm }) => {
                  return (
                    <>
                      <Form>
                        <Box style={{ marginTop: "25px" }}>
                          <FormSelectField
                            label={t("club")}
                            name="clubs"
                            options={clubsList?.map(
                              (x: any) =>
                                (x = { value: x?._id || x?.id, label: x?.name })
                            )}
                            isMultiple={true}
                            onChangeCallback={(val: any) =>
                              setFieldValue("clubs", val)
                            }
                            value={values?.clubs}
                          />
                        </Box>

                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Box style={{ marginTop: "25px" }}>
                              <FormSelectField
                                label={t("gender")}
                                name="gender"
                                options={enumtoArrayConverter(Gender)}
                                //isMultiple={true}
                                onChangeCallback={(val: any) =>
                                  setFieldValue("gender", val)
                                }
                                value={values?.gender}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <Box style={{ marginTop: "25px" }}>
                              {/* <Autocomplete
                                multiple
                                options={top100Filmsdd}
                                disableCloseOnSelect
                                getOptionLabel={(option: any) => option.title}
                                onChange={(event, value) => {
                                  setFieldValue("locality", value);
                                }}
                                value={values?.locality || []}
                                renderOption={(
                                  props: any,
                                  option,
                                  { selected }
                                ) => {
                                  const { key, ...optionProps } = props;
                                  return (
                                    <li key={key} {...optionProps}>
                                      <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                      />
                                      {option.title}
                                    </li>
                                  );
                                }}
                                style={{ width: 500 }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Checkboxes"
                                    placeholder="Favorites"
                                  />
                                )}
                              /> */}

                              <FormAutocompleteField
                                label={t("locality")}
                                name="locality"
                                options={localityOptions}
                                isMultiple={true}
                                limitTags={2}
                                onChangeCallback={(val: any) => {
                                  setFieldValue("locality", val);
                                }}
                                value={values?.locality || []}
                              />
                            </Box>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sx={{ marginTop: "1rem" }}>
                          <StyledFormControlLabel
                            control={
                              <Checkbox
                                defaultChecked={values?.isProEmails}
                                onChange={(e) =>
                                  setFieldValue("isProEmails", e.target.checked)
                                }
                              />
                            }
                            label={t("PostToProEmails")}
                          />
                        </Grid>

                        <Divider style={{ marginTop: "1rem" }} />

                        <Box sx={{ width: "100%", marginTop: "2rem" }}>
                          <Typography style={{ marginBottom: "1rem" }}>
                            {t("ageRange")}
                          </Typography>
                          <PrettySlider
                            getAriaLabel={() => "Minimum distance"}
                            value={value1}
                            onChange={handleChange1}
                            valueLabelDisplay="auto"
                            getAriaValueText={valuetext}
                            disableSwap
                            step={1}
                          />

                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              variant="body2"
                              //onClick={() => setVal(MIN)}
                              sx={{ cursor: "pointer" }}
                            >
                              {MIN}
                            </Typography>
                            <Typography
                              variant="body2"
                              //onClick={() => setVal(MAX)}
                              sx={{ cursor: "pointer" }}
                            >
                              {MAX}
                            </Typography>
                          </Box>
                        </Box>

                        <Grid
                          container
                          justifyContent={"flex-end"}
                          alignItems={"center"}
                          mt={"3rem"}
                        >
                          <Button
                            onClick={handleReset(resetForm)}
                            style={{
                              marginRight: "1rem",
                              color: "#EB5757",
                              fontWeight: 600,
                              textTransform: "capitalize",
                            }}
                          >
                            {t("resetFilters")}
                          </Button>
                          <ButtonWrapper>
                            <Button
                              variant="contained"
                              color="primary"
                              style={brownButtonStyle}
                              className="capitalize"
                              type="submit"
                            >
                              {t("applyFilters")}
                            </Button>
                          </ButtonWrapper>
                        </Grid>
                      </Form>{" "}
                    </>
                  );
                }}
              </Formik>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};
