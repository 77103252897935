import { capitalize } from "lodash";
import { IConsolidatedUser } from "../../entities/FeedPage/models/FeedPageModels";

export const renderPhoneNumber = (countryCode: string, phone: string) => {
  return countryCode && phone ? `+${countryCode} ${phone || "NA"}` : "NA";
};

export const enumtoArrayConverter = (enumData: any) => {
  const enumDataArray = Object.keys(enumData).map((key) => ({
    value: enumData[key],
    label: capitalize(key.replace(/[_-]/g, " ")), // Capitalize the label
  }));

  return enumDataArray;
};

export const replaceUserPatternWithName = (
  text: string,
  users: IConsolidatedUser[]
): string => {
  let result = text;
  if (!users || users.length === 0) return result;

  for (const user of users) {
    const { firstName, lastName, userType, userId } = user;
    const fullName = `${firstName} ${lastName}`;
    const isAdmin = userType === "super_admin" || userType === "club_admin";
    const link = `/users/${userId}?isAdmin=${isAdmin}`;
    const hyperlink = `<a href="${link}" target="_blank" style="text-decoration: underline; margin-left: 5px;">@${fullName}</a>`;

    const regex = new RegExp(`@[a-zA-Z0-9_]+__${userId}\\^`, "g");
    result = result.replace(regex, hyperlink);
  }

  return result;
};

export const roundToTwoDecimals = (amount: any) => {
  return Math.round(parseFloat(amount) * 100) / 100;
};
