// src/redux/coachingReducer.ts
import { createSlice } from "@reduxjs/toolkit";

const membershipSlice = createSlice({
  name: "memberships",
  initialState: { data: [], userMemberships: [], loading: false, error: null },
  reducers: {
    membershipsRequested: (state) => {
      state.loading = true;
      state.error = null;
    },
    membershipsReceived: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    membershipsRequestFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateMembershipData: (state: any, action) => {
      const updatedData = state?.data?.data?.map((x: any) =>
        x?._id === action?.payload?._id
          ? { ...x, ...action.payload, updated: true }
          : x
      );

      return {
        ...state,
        loading: false,
        data: { ...state?.data, data: updatedData },
      };
    },
    singleMembershipRequested: (state) => {
      state.loading = true;
      state.error = null;
    },
    singleMembershipReceived: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    singleMembershipRequestFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    userMembershipsRequested: (state) => {
      state.loading = true;
      state.error = null;
    },
    userMembershipsReceived: (state, action) => {
      state.loading = false;
      state.userMemberships = action.payload;
    },
    userMembershipsRequestFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    updateUserMembershipData: (state: any, action) => {
      const updatedData = state?.userMemberships?.data?.map((x: any) =>
        x?._id === action?.payload?._id
          ? { ...x, ...action.payload, updated: true }
          : x
      );

      return {
        ...state,
        loading: false,
        data: { ...state?.data, data: updatedData },
      };
    },
    membershipsReset: (state) => {
      state.loading = false;
      state.data = [];
      state.userMemberships = [];
    },
  },
});

export const {
  membershipsRequested,
  membershipsReceived,
  membershipsRequestFailed,
  singleMembershipRequested,
  singleMembershipReceived,
  singleMembershipRequestFailed,
  updateMembershipData,
  updateUserMembershipData,
  userMembershipsRequested,
  userMembershipsReceived,
  userMembershipsRequestFailed,
  membershipsReset,
} = membershipSlice.actions;

export default membershipSlice.reducer;
