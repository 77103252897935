import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Grid, TextField, Typography, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { styled } from "@mui/material/styles";
import ModalFeedFilter from "./modals/ModalFeedFilter";
import { brownButtonStyle } from "../../../shared/uiConst/uniconst";

const ButtonWrapper = styled("div")`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${(props) => props.theme.spacing(2)};
`;

function FeedPageHeader({
  setCreatePostModalOpen,
  handleApplyFilter,
  localSelectedClub,
  setLocalSelectedClub,
  localSelectedFromDate,
  setLocalSelectedFromDate,
  localSelectedToDate,
  setLocalSelectedToDate,
  setSearchQuery,
}: {
  setCreatePostModalOpen: any;
  handleApplyFilter: any;
  localSelectedClub: any;
  setLocalSelectedClub: any;
  localSelectedFromDate: any;
  setLocalSelectedFromDate: any;
  localSelectedToDate: any;
  setLocalSelectedToDate: any;
  setSearchQuery: any;
}) {
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState("");
  const [debouncedSearchText, setDebouncedSearchText] = useState("");

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedSearchText(searchText);
    }, 1000);

    return () => {
      clearTimeout(timerId);
    };
  }, [searchText]);

  useEffect(() => {
    setSearchQuery(debouncedSearchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchText]);

  return (
    <Grid container spacing={2} alignItems="center" mt="0">
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={7}>
          <Typography
            style={{
              fontWeight: 600,
              color: "#45464E",
              fontSize: "26px",
            }}
          >
            {t("feedManagement")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={2}
          justifyContent="flex-end"
          style={{ marginTop: "10px" }}
        >
          <TextField
            label={t("typeToSearch")}
            fullWidth
            value={searchText}
            onChange={(e: any) => setSearchText(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={1} container justifyContent="flex-end"></Grid>
        <Grid
          item
          xs={12}
          sm={2}
          container
          justifyContent="flex-end"
          style={{ marginTop: "20px" }}
        >
          <ButtonWrapper>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setCreatePostModalOpen(true)}
              style={brownButtonStyle}
            >
              <AddIcon />
              {t("createNewPost")}
            </Button>
          </ButtonWrapper>

          <ModalFeedFilter
            onApply={handleApplyFilter}
            selectedClub={localSelectedClub}
            selectedFromDate={localSelectedFromDate}
            selectedToDate={localSelectedToDate}
            onSelectedClubChange={setLocalSelectedClub}
            onSelectedFromDateChange={setLocalSelectedFromDate}
            onSelectedToDateChange={setLocalSelectedToDate}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default FeedPageHeader;
