import React from "react";
import {
  FormControl,
  Typography,
  Autocomplete,
  TextField,
  Checkbox,
} from "@mui/material";
import { useField, FieldAttributes } from "formik";
import { uniqBy } from "lodash";

interface FormikSelectProps extends FieldAttributes<any> {
  label?: string;
  options?: { value: string | number; label: string }[];
  onChangeCallback?: (value: any, valueObj?: any) => void;
  placeHolder?: string;
  isMultiple?: boolean;
  customWidth?: any;
  customMarginLeft?: any;
  disabled?: any;
  limitTags?: any;
}

export const FormAutocompleteField: React.FC<FormikSelectProps> = ({
  label,
  options,
  placeHolder,
  onChangeCallback,
  isMultiple = false,
  customWidth,
  customMarginLeft,
  disabled,
  limitTags = 100,
  ...props
}) => {
  const [field, meta, helpers] = useField(props as FieldAttributes<{}>);

  const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
    let newValue = isMultiple ? uniqBy(value, "value") : value;

    helpers.setValue(value);

    if (onChangeCallback && typeof onChangeCallback === "function") {
      onChangeCallback(newValue);
    }
  };

  const error = meta.touched && meta.error ? true : false;

  return (
    <FormControl fullWidth>
      <Typography
        className="uppercase label"
        sx={{ marginBottom: "1rem" }}
        fontSize={"0.8rem"}
      >
        {label}
      </Typography>
      <Autocomplete
        {...field}
        {...props}
        limitTags={limitTags}
        disableCloseOnSelect={true}
        options={options || []}
        getOptionLabel={(option: any) => option?.label}
        //isOptionEqualToValue={(option: any, value) => option?.value === value}
        onChange={handleChange}
        multiple={isMultiple}
        disabled={disabled}
        value={field?.value || (isMultiple ? [] : null)} // Ensure the value is correctly initialized
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeHolder || "Select"}
            error={error}
            helperText={
              error && meta.error ? (
                <span
                  className="MuiFormHelperText-root Mui-error"
                  style={{ color: "#d32f2f" }}
                >
                  {meta.error}
                </span>
              ) : (
                ""
              )
            }
          />
        )}
        renderOption={(props, option: any, { selected }) => (
          <li {...props} key={option?.value}>
            {isMultiple && (
              <Checkbox checked={selected} style={{ marginRight: 8 }} />
            )}{" "}
            {option?.label}
          </li>
        )}
      />
    </FormControl>
  );
};
