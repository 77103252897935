import React, { useEffect, useState } from "react";
import {
  Grid,
  CircularProgress,
  Button,
  LinearProgress,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useInView } from "react-intersection-observer";

import ModalFeedUpdate from "./components/modals/ModalFeedUpdate";
import FeedPageHeader from "./components/FeedPageHeader";
import FeedItemCard from "./components/FeedItemCard";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { FEED_ITEMS_LIST } from "../../config/constants";
import { fetchFeedItems } from "./apiService/feedAPIService";
import { IFeedItem, IFeedQueryParams } from "./models/FeedPageModels";
import { Box } from "@mui/system";
import { brownButtonStyle } from "../../shared/uiConst/uniconst";
import { Refresh } from "@mui/icons-material";

const ContentContainer = styled("div")`
  flex: 1;
  padding: ${(props) => props.theme.spacing(2)};
  backgroudcolor: "#FFF";
`;

const FeedPage: React.FC = () => {
  const [localSelectedClub, setLocalSelectedClub] = useState("");
  const [localSelectedFromDate, setLocalSelectedFromDate] =
    useState<Date | null>(null);
  const [localSelectedToDate, setLocalSelectedToDate] = useState<Date | null>(
    null
  );
  const [creating, setCreating] = useState(false);
  const [editing, setEditing] = useState(false);
  const [editPostId, setEditPostId] = useState("");
  const [editPostDetails, setEditPostDetails] = useState<any | null>(null);
  const [feedLoading, setFeedLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const queryClient = useQueryClient();

  const {
    data: feedData,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: [FEED_ITEMS_LIST],
    queryFn: async ({ pageParam = 1 }) => {
      const parameters: IFeedQueryParams = {
        sort: "createdAt|DESC",
        status: "published",
        isDeleted: false,
        clubs: localSelectedClub,
        textSearch: searchQuery,
        fromDate: localSelectedFromDate,
        toDate: localSelectedToDate,
        limit: 10,
        page: pageParam,
      };
      const response = await fetchFeedItems(parameters);
      return {
        ...response,
        page: pageParam,
        limit: parameters.limit,
      };
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      const totalCount = lastPage.totalCount;
      const currentPage = lastPage.page;
      const limit = lastPage.limit;
      const hasMore = currentPage * limit < totalCount;

      return hasMore ? currentPage + 1 : undefined;
    },
  });

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: [FEED_ITEMS_LIST] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    localSelectedClub,
    localSelectedFromDate,
    localSelectedToDate,
    searchQuery,
  ]);

  const { ref } = useInView({
    threshold: 0,
    triggerOnce: false,
    rootMargin: "0px",
    onChange: (inView) => {
      if (inView && hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
  });

  const handleApplyFilter = (
    selectedClub: string,
    selectedFromDate: Date | null,
    selectedToDate: Date | null
  ) => {
    setLocalSelectedClub(selectedClub);
    setLocalSelectedFromDate(selectedFromDate);
    setLocalSelectedToDate(selectedToDate);
  };

  const reload = async () => {
    await queryClient.invalidateQueries({ queryKey: [FEED_ITEMS_LIST] });
  };

  const handleEdit = (feedItem: IFeedItem) => {
    // Fetch the post details based on postId if needed and set them in the state
    setEditPostId(feedItem._id);
    setEditing(true);
    setEditPostDetails(feedItem);
  };

  const handleCloseEdit = () => {
    setEditing(false);
  };

  return (
    <ContentContainer>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {/* Feeds page title & search, filter */}
          <FeedPageHeader
            setCreatePostModalOpen={setCreating}
            handleApplyFilter={handleApplyFilter}
            localSelectedClub={localSelectedClub}
            setLocalSelectedClub={setLocalSelectedClub}
            localSelectedFromDate={localSelectedFromDate}
            setLocalSelectedFromDate={setLocalSelectedFromDate}
            localSelectedToDate={localSelectedToDate}
            setLocalSelectedToDate={setLocalSelectedToDate}
            setSearchQuery={setSearchQuery}
          />
          {feedLoading && <LinearProgress color="secondary" />}
          <Grid container spacing={2} style={{ marginTop: "10px" }}>
            {/* Main content grid items */}
            {isLoading && (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{ minHeight: "50vh" }}
              >
                <CircularProgress />
                <LinearProgress color="secondary" />
              </Grid>
            )}

            {isError && (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{ minHeight: "50vh" }}
              >
                <Box>
                  <h3>Failed to load feeds</h3>
                  <button onClick={() => fetchNextPage()}>Retry</button>
                </Box>
              </Grid>
            )}

            {feedData?.pages && (
              <Grid container spacing={2}>
                {/* Render feed items */}
                {feedData?.pages?.map((page, index) => (
                  <React.Fragment key={`${index}-${page[index]}`}>
                    {page?.data?.map((feedItem: any) => (
                      <Grid item xs={12} md={6} key={feedItem._id}>
                        <FeedItemCard
                          post={feedItem}
                          onEdit={() => handleEdit(feedItem)}
                          onFeedChange={reload}
                          setFeedLoading={setFeedLoading}
                        />
                      </Grid>
                    ))}
                  </React.Fragment>
                ))}
              </Grid>
            )}

            {/* Intersection observer reference */}
          </Grid>
          <Grid container style={{ marginTop: "20px" }}>
            {hasNextPage ? (
              <Button
                ref={ref}
                variant="contained"
                color="primary"
                onClick={() => fetchNextPage()}
                disabled={isFetchingNextPage || !hasNextPage}
                style={{
                  ...brownButtonStyle,
                  margin: "auto",
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Refresh />
                {isFetchingNextPage ? " Loading more..." : " Load more"}
              </Button>
            ) : (
              <Grid item xs={12}>
                <Divider />
                <h3 style={{ textAlign: "center" }}>End of feed</h3>
                <Divider />
                {!hasNextPage && (
                  <Button
                    ref={ref}
                    variant="contained"
                    color="primary"
                    onClick={() => reload()}
                    style={{
                      ...brownButtonStyle,
                      margin: "auto",
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <Refresh />
                    Reload
                  </Button>
                )}
              </Grid>
            )}
          </Grid>

          {creating && (
            <ModalFeedUpdate
              open={creating}
              onClose={() => setCreating(false)}
              setFeedLoading={setFeedLoading}
            />
          )}
          {editing && (
            <ModalFeedUpdate
              open={editing}
              onClose={handleCloseEdit}
              postId={editPostId}
              postDetails={editPostDetails}
              setFeedLoading={setFeedLoading}
            />
          )}
        </Grid>
      </Grid>
    </ContentContainer>
  );
};

export default FeedPage;
