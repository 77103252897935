/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import "react-toastify/dist/ReactToastify.css";
import {
  Modal,
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  IconButton,
  FormHelperText,
  FormControlLabel,
  Chip,
  Avatar,
  Autocomplete,
  Radio,
  TextField,
} from "@mui/material";
import { styled } from "@mui/system";
import { Form, Formik } from "formik";
import CloseIcon from "@mui/icons-material/Close";

import { AppDispatch } from "../../../redux/store";
import { BroadcastMessageDialog } from "./BroadcastMessageDialog";
import { BroadcastConfirmationModal } from "./BroadcastConfirmationModal";
import { toCamelCase } from "app/shared/util/ToCamelCase";
import { QuillEditor } from "app/shared/atoms";
import { sendBroadcastMessage } from "app/entities/Dashboard/dashboardApiService";
import { fetchUserList } from "app/entities/Users/usersApiService";

interface ModalFormProps {
  open: boolean;
  onClose: () => void;
  formData?: any;
  callback?: any;
  action?: any;
}

const brownButtonStyle = {
  backgroundColor: "#333333",
  color: "white", // Text color
};

const StyledTextField = styled(TextField)({
  borderColor: "white",
  "& label.Mui-focused": {
    color: "black", // Change the label color when focused
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "unset", // Change the outline border color when focused
  },
  "& .MuiTextField-root": {
    marginTop: 0, // Set top margin to 0
    marginBottom: 0, // Set bottom margin as needed
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "white", // Change the border color
  },
  "& .MuiOutlinedInput-root": {
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "white", // Change the border color on hover
      },
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "white", // Change the border color on focus
      },
    },
    "&.Mui-focused.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "white", // Change the border color when focused visible
      },
    },
  },
});

export const BroadcastMessageModal: React.FC<ModalFormProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [showConfirmationMessage, setConfirmationMessage] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [searchUserText, setUserSearchText] = useState<string>("");

  useEffect(() => {
    dispatch(
      fetchUserList({ pageSize: 1000, page: 1, listAll: "true" }, (data) => {
        setUsersList(data);
      })
    );
  }, []);

  /**
   * Handle Next on button click to load the next step if second page call the api
   *
   * @function
   * @returns void
   */
  const handleSubmit = async (values?: any) => {
    let newValue: any = values;

    if (
      values?.isProEmails === null &&
      values?.gender?.length === 0 &&
      !values?.ageRange?.from &&
      !values?.ageRange?.to &&
      values?.users?.length === 0
    ) {
      newValue.isPublic = true;
    } else {
      newValue.isPublic = false;
    }

    dispatch(
      await sendBroadcastMessage(newValue, () => {
        onClose();
      })
    );
  };

  /**
   * Handle close on button click
   *
   * @function
   * @returns void
   */
  const handleCloseModal = (event?: any, reason?: any) => {
    if (reason !== "backdropClick") onClose();
  };

  const handleSearchChange =
    (objectKey: string) =>
    (event: React.SyntheticEvent<Element, Event>, value: string) => {
      setUserSearchText(value);
    };

  return (
    <Modal open={open} onClose={onClose}>
      <div
        style={{
          position: "absolute",
          top: "10%",
          left: "15%",
          width: "70%",
          borderRadius: "5px",
          background: "white",
        }}
      >
        {/* Content of the sliding modal */}
        <Box sx={{ padding: "1rem 2rem" }}>
          <Typography
            sx={{ color: "#292E3A", fontSize: "18px", fontWeight: 600 }}
          >
            {t("BroadcastMessage")}
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseModal}
            aria-label="close"
            sx={{
              position: "absolute",
              top: "15px",
              right: "24px",
              cursor: "pointer",
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        <Box sx={{ padding: "1rem 2rem" }}>
          <Formik
            initialValues={{
              users: [],
              message: "",
              isProEmails: null,
              gender: [],
              ageRange: null,
            }}
            onSubmit={(values) => {
              setConfirmationMessage(true);
            }}
            enableReinitialize
            validationSchema={yup.object().shape({
              messageText: yup.string().required(`${t("messageRequired")}`),
            })}
          >
            {({
              values,
              errors,
              setFieldValue,
              setValues,
              submitCount,
            }: any) => {
              return (
                <>
                  <Form>
                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                      {" "}
                      <Grid item xs={12}>
                        <Grid container alignItems={"center"}>
                          <Grid
                            item
                            xs={0.3}
                            sx={{
                              color: "#1B1D28",
                              fontSize: "16px",
                              fontWeight: 500,
                            }}
                          >
                            To :
                          </Grid>
                          <Grid item xs={11.4}>
                            <Box>
                              <Autocomplete
                                onChange={(e, selectedUser: any) => {
                                  // Use the selected user value to update the form field
                                  setFieldValue("users", selectedUser);
                                }}
                                multiple={true}
                                options={usersList}
                                fullWidth
                                inputValue={searchUserText}
                                onInputChange={(e, newInputValue) => {
                                  // Handle changes in the input value for searching
                                  handleSearchChange("user")(e, newInputValue);
                                }}
                                getOptionLabel={(option: any) =>
                                  toCamelCase(
                                    option?.firstName,
                                    option?.lastName
                                  )
                                }
                                renderOption={(props, user, { selected }) => (
                                  // Customize how each option is displayed in the dropdown
                                  <li {...props} key={user?._id}>
                                    <FormControlLabel
                                      control={<Radio checked={selected} />}
                                      label={
                                        <Grid
                                          container
                                          alignItems="center"
                                          spacing={2}
                                        >
                                          <Grid item>
                                            <Avatar
                                              src={user?.profilePic}
                                              alt={`${user?.firstName} ${user?.lastName}`}
                                            />
                                          </Grid>
                                          <Grid item>
                                            <Typography variant="body1">
                                              {toCamelCase(
                                                user?.firstName,
                                                user?.lastName
                                              )}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      }
                                    />
                                  </li>
                                )}
                                renderInput={(params) => (
                                  <StyledTextField
                                    {...params}
                                    InputLabelProps={{
                                      children: "Search for a user",
                                      ...(params.InputLabelProps as object),
                                    }}
                                  />
                                )}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={0.3}>
                            <BroadcastMessageDialog
                              callback={(filterValues: any) => {
                                setValues({ ...values, ...filterValues });
                              }}
                              defaultValue={{
                                isProEmails:
                                  values?.isProEmails !== null
                                    ? values?.isProEmails
                                    : null,
                                gender: values?.gender || [],
                                ageRange:
                                  values?.ageRange !== null
                                    ? values?.ageRange
                                    : null,
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Divider />
                      </Grid>{" "}
                      {(values?.isProEmails !== null ||
                        values?.gender?.length > 0) && (
                        <Grid item xs={12}>
                          <Grid container alignItems={"center"}>
                            <Grid
                              item
                              xs={0.5}
                              sx={{
                                color: "#1B1D28",
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              Filter :{" "}
                            </Grid>
                            <Grid item xs={11.5}>
                              {values?.gender?.map((val: any, key: number) => (
                                <Chip
                                  key={`chip-${key}`}
                                  label={val}
                                  style={{
                                    borderRadius: "8px",
                                    background: "#3745571A",
                                    marginRight: "8px",
                                    color: "#333333",
                                    textTransform: "capitalize",
                                  }}
                                  onDelete={() => {
                                    let newValues = values?.gender?.filter(
                                      (x: any) => x !== val
                                    );
                                    setFieldValue("gender", newValues);
                                  }}
                                />
                              ))}
                              {values?.isProEmails && (
                                <Chip
                                  key={`chip-proEmails`}
                                  label={"Pro Emails"}
                                  style={{
                                    borderRadius: "8px",
                                    background: "#3745571A",
                                    marginRight: "8px",
                                    color: "#333333",
                                  }}
                                  onDelete={() => {
                                    setFieldValue("isProEmails", null);
                                  }}
                                />
                              )}
                              {values?.ageRange && (
                                <Chip
                                  key={`chip-ageRange`}
                                  label={`Age ${values?.ageRange?.[0]}-${values?.ageRange?.[1]}`}
                                  style={{
                                    borderRadius: "8px",
                                    background: "#3745571A",
                                    marginRight: "8px",
                                    color: "#333333",
                                  }}
                                  onDelete={() => {
                                    setFieldValue("ageRange", null);
                                  }}
                                />
                              )}
                            </Grid>
                          </Grid>
                          <Divider sx={{ marginTop: "1rem" }} />
                        </Grid>
                      )}
                      <Grid item xs={12} sx={{ marginTop: "1rem" }}>
                        <QuillEditor
                          initialContent={""}
                          placeholder={t("StartNewMessageHere")}
                          isExternalAttachment={true}
                          onContentChange={(value, text) => {
                            setFieldValue(
                              "messageText",
                              text.replace(/(\r\n|\n|\r)/gm, "")
                            );
                            setFieldValue("message", value);
                          }}
                        />
                        <FormHelperText>
                          {submitCount && errors?.messageText ? (
                            <span
                              className="MuiFormHelperText-root Mui-error"
                              style={{ color: "#d32f2f" }}
                            >
                              {errors?.messageText}
                            </span>
                          ) : (
                            ""
                          )}
                        </FormHelperText>
                      </Grid>
                    </Grid>

                    <BroadcastConfirmationModal
                      openModal={showConfirmationMessage}
                      onClose={() => setConfirmationMessage(false)}
                      callback={() => {
                        handleSubmit({
                          ...values,
                          ageRange: {
                            from: values?.ageRange?.[0],
                            to: values?.ageRange?.[1],
                          },
                          users: values?.users?.map((x: any) => x?._id),
                        });
                      }}
                    />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid container spacing={2} style={{ marginTop: "15px" }}>
                        <Grid item xs={12} container justifyContent="flex-end">
                          <Button
                            variant="contained"
                            color="primary"
                            style={brownButtonStyle}
                            type="submit"
                            sx={{ textTransform: "capitalize" }}
                          >
                            {t("BroadcastMessage")}
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </Box>
      </div>
    </Modal>
  );
};
