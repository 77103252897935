import { createSlice } from "@reduxjs/toolkit";

const clubsSlice = createSlice({
  name: "clubs",
  initialState: {
    clubsData: [],
    clubsFeaturesData: [],
    loading: false,
    error: null,
    currentPage: 1,
  },
  reducers: {
    clubsRequested: (state) => {
      state.loading = true;
      state.error = null;
    },
    clubsReceived: (state, action) => {
      state.clubsData = action.payload;
      state.loading = false;
      state.currentPage = 0;
    },
    clubsRequestFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    clubsFeaturesRequested: (state) => {
      state.loading = true;
      state.error = null;
    },
    clubsFeaturesReceived: (state, action) => {
      state.loading = false;
      state.clubsFeaturesData = action.payload;
    },
    clubsFeaturesRequestFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    clubsReset: (state) => {
      state.loading = false;
      state.clubsData = [];
    },
  },
});

export const {
  clubsRequested,
  clubsReceived,
  clubsRequestFailed,
  clubsFeaturesReceived,
  clubsFeaturesRequestFailed,
  clubsFeaturesRequested,
  clubsReset,
} = clubsSlice.actions;

export default clubsSlice.reducer;
