import axios from "axios";

export const loadFacebookSDK = () => {
  const id = "facebook-jssdk";
  if (document.getElementById(id)) return;

  const js = document.createElement("script");
  js.id = id;
  js.src = "https://connect.facebook.net/en_US/sdk.js";
  js.async = true;
  js.onload = () => {
    window.FB.init({
      appId: process.env.REACT_APP_FACEBOOK_ID,
      autoLogAppEvents: true,
      xfbml: true,
      version: "v12.0",
    });
  };

  document.body.appendChild(js);
};

const generatePostUrl = (postId) => {
  return `${process.env.REACT_APP_FEED_URL}/feed/${postId}`;
};

export const handleShareFacebook = (post, postUrl, onClose) => {
  let option = {
    method: "share",
    href: generatePostUrl(post?._id ?? ""),
  };

  if (window.FB) {
    //let quote = post?.message ?? "";
    
    option = {
      ...option,
      method: "share",
      // quote: quote?.replace(/^(.{100}).*/, "$1"),
      // hashtag: quote?.replace(/^(.{100}).*/, "$1"),
    };

    window.FB.ui(option, function (response) {
      if (response && !response.error_message) {
        console.log("Posting completed.");
      } else if (response && response.error_message) {
        console.log("Error while posting:", response.error_message);
      } else {
        console.log("User may have cancelled the share dialog.");
      }
    });
  }
  onClose();
};

export const facebookLogin = async () => {
  if (window.FB) {
    window.FB.getLoginStatus(function (response) {
      console.log("response", response);
      if (response?.status === "connected") {
        // the user is logged in and has authenticated your
        // app, and response.authResponse supplies
        // the user's ID, a valid access token, a signed
        // request, and the time the access token
        // and signed request each expire
        var uid = response.authResponse.userID;
        var accessToken = response.authResponse.accessToken;
        return accessToken;
      } else {
        // window.FB.login(
        //   function (response) {
        //     if (response.authResponse) {
        //       const accessToken = response.authResponse.accessToken;
        //       console.log("Access Token:", accessToken);
        //       return accessToken;
        //     } else {
        //       console.log("User cancelled login or did not fully authorize.");
        //     }
        //   },
        //   { scope: "email,public_profile" }
        // );
      }
    });
  }
};

export const handleShareInstagram = async (post, onClose) => {
  // Constructing the Instagram share URL

  let facebookToken = await facebookLogin();

  console.log("facebookToken &&", facebookToken);

  if (facebookToken) {
    let url = "https://cpadmin-dev.casapadel.fr/feed/dsd";
    let title = "dddd";
    let caption = "sdsdsds";
    let accessToken = facebookToken;
    let mediaUrl =
      "https://dvrmlh8n4iueq.cloudfront.net/media/feed/images/1719824668163_Screenshot_2024-07-01_at_2.34.12_PM_1.png";
    const shareUrl = `https://www.instagram.com/share?url=${encodeURIComponent(
      url
    )}&title=${encodeURIComponent(title)}`;

    try {
      // Step 1: Upload media to Instagram
      const mediaResponse = await axios.post(
        `https://graph.facebook.com/v16.0/17841400000000000/media`, // Replace with your Instagram user ID
        {
          image_url: mediaUrl,
          caption: caption,
          access_token: accessToken,
        }
      );

      const mediaId = mediaResponse.data.id;

      // Step 2: Publish media to Instagram
      const publishResponse = await axios.post(
        `https://graph.facebook.com/v16.0/17841400000000000/media_publish`, // Replace with your Instagram user ID
        {
          creation_id: mediaId,
          access_token: accessToken,
        }
      );

      console.log("Publish response", publishResponse.data);
    } catch (error) {
      console.error("Error uploading media", error);
    }
  }
};
