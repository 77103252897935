import { useEffect, useState } from "react";
import { Autocomplete, TextField, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetchClubList } from "app/entities/Clubs/clubsApiService";
import { AppDispatch } from "../../redux/store";
import { uniqBy, capitalize, sortBy, lowerCase } from "lodash";

export const ClubsMultiSelect = ({
  name,
  label,
  onChange,
  value,
  multiple = false,
  error = false,
  isFilter = false,
  initialSetParam = false,
  clearIcon = false,
  isSetStorage = false
}: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [clubs, setClubs] = useState<any>([]);
  const [selectedClubs, setSelectedClubs] = useState<any>(multiple ? [] : null);

  // Fetch clubs data from API
  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      dispatch(
        fetchClubList(
          {
            pageSize: 10000,
            page: 1,
            isFilter,
          },
          (data: any) => {
            let clubsList = uniqBy(data, "_id");
            let sortedClubs:any = sortBy(clubsList, ({ name }: any) => lowerCase(name));
            setClubs(sortedClubs);
            if(isSetStorage){
              sessionStorage.setItem('clubList', JSON.stringify(sortedClubs || []))
            }
            if (initialSetParam) {
              let list = data?.filter((x: any) => x?.status === "active");
              initialSetParam(list?.[0]?._id);
            }
          },
          true
        )
      );
    }
  }, []);

  // Map array of IDs to array of club objects
  useEffect(() => {
    if (value && !multiple) {
      // Check if it's a single selection
      // For single selection, find the club object corresponding to the selected ID
      const selectedClubObject: any | undefined = clubs?.find(
        (club: any) => club?._id === value
      );
      setSelectedClubs(selectedClubObject || null);
    } else if (value && Array.isArray(value) && value?.length > 0) {
      // Check if it's a multiple selection
      const selectedClubObjects = clubs?.filter((club: any) =>
        value?.includes(club?._id)
      );
      setSelectedClubs(selectedClubObjects);
    } else {
      setSelectedClubs(multiple ? [] : null);
    }

    // Cleanup function
    return () => {
      setSelectedClubs(null);
    };
  }, [value, clubs, multiple]);

  // Handle change event
  const handleOnChange = (event: any, newValue: any) => {
    // Ensure newValue is always an array if multiple selections are allowed
    setSelectedClubs(newValue);

    onChange(
      multiple ? newValue?.map((club: any) => club?._id) : newValue?._id,
      newValue
    ); // Pass array of IDs to parent component
  };

  function customCamelCase(word: any) {
    return word
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (letter: any, index: any) {
        return index === 0 ? capitalize(letter) : capitalize(letter);
      })
      .replace(/\s+/g, "");
  }

  // Filter active users for options
  const activeClubs = clubs?.filter(
    (club: any) =>
      club?.status === "active" ||
      club?._id === value ||
      value?.includes(club?._id)
  );

  return (
    <>
      {label && (
        <Typography
          className="uppercase label"
          sx={{ marginBottom: "1rem", fontSize: "12px" }}
        >
          {label}
        </Typography>
      )}
      <Autocomplete
        id={name}
        options={activeClubs}
        getOptionLabel={(option: any) => {
          const result = option?.name
            .split(" ")
            .map((word: any) => customCamelCase(word))
            .join(" ");
          return result;
        }}
        multiple={multiple}
        value={selectedClubs}
        onChange={handleOnChange} // Pass the custom onChange handler
        renderInput={(params: any) => (
          <TextField
            {...params}
            error={Boolean(error)}
            variant="outlined"
            placeholder={t("selectClubs")}
          />
        )}
        clearIcon={clearIcon}
      />
    </>
  );
};
