export const TermsSalesFR = {
	generalInfo: {
		title: 'CONDITIONS GENERALES D’UTILISATION',
		content: `L’application « RESAPADEL » (ci-après « <strong>l’Application</strong> ») est éditée par <strong>LRD</strong>, société par
              actions simplifiée au capital social de 229.966 euros, dont le siège social est situé 24, quai
              du 4 septembre – 92100 Boulogne Billancourt, immatriculée au registre du commerce et des
              sociétés de NANTERRE sous le numéro 820 434 561 (ci-après la « <strong>Société</strong> » ou
              « <strong>RESAPADEL</strong> »).<br /><br />

              La Société exploite directement ou indirectement des centres de padel sous l’enseigne
              « CASA PADEL » en France métropolitaine (ci-après les « <strong>Centres Casa Padel</strong> »). Les
              Centres Casa Padel proposent la location de terrains de padel et la mise à disposition de
              services associés tels que la vente ou la location de matériel de padel, la mise à disposition
              de prestations de coaching, etc.<br /><br />

              L’Application constitue un outil de réservation de prestations de services au sein des Centres
              Casa Padel.<br /><br />
              
              Les présentes conditions générales de vente (ci-après les « <strong>CGV</strong> ») s’appliquent à toutes les
              ventes et réservations réalisées par un utilisateur inscrit sur l’Application (ci-après le ou les
              « <strong>Client(s)</strong> »).<br /><br />

              RESAPADEL et le Client sont désignés individuellement ou collectivement la « Partie » ou
              les « Parties ».
`,
	},
	acceptanceAndModification: {
		title: 'ARTICLE 1. &nbsp;&nbsp;&nbsp;&nbsp; OBJET',
		content: `Les présentes CGV ont pour objet de régir les conditions des prestations de services
              réservées par le Client par le biais de l’Application au sein de Centres Casa Padel ainsi que
              les droits et obligations y afférents pour chacune des Parties.<br /> <br />

              Toute réservation de prestations ou tout achat réalisé par le Client à partir de l’Application
              implique l’acceptation sans réserve des présentes CGV en vigueur à la date de l’achat ou de
              la réservation par le Client, qui déclare et reconnaît en avoir pris connaissance et qu’elles lui
              sont opposables.<br /> <br />

              En tout état de cause, l’inscription du Client sur l’Application et/ou tout commencement dans
              l’utilisation des services réservés par l’intermédiaire de l’Application a pour conséquence de
              soumettre le Client aux CGV.<br /> <br />

              Les CGV sont opposables pendant toute la durée d’utilisation de l’Application par le Client et
              jusqu&#39;à ce que de nouvelles CGV les modifient ou les remplacent.<br /><br />

              <strong>Toute réservation à partir de l’Application implique également que le Client déclare ne
              pas avoir de contre-indication médicale pour la pratique d’une activité sportive telle
              que le padel.</strong>
`,
	},
	accountcreation: {
		title: 'Article 2. &nbsp;&nbsp;&nbsp;&nbsp; <u>PRESTATIONS DE RESERVATION</u>',
		content: `Après avoir procédé au téléchargement de l’Application et à la création d’un compte
              utilisateur en suivant le processus de création de compte jusqu’à son terme, le Client accède
              aux services payants proposés par l’Application (ci-après les « <strong>Services</strong> »).<br /><br />

              Ces Services permettent la réservation de terrains de padel, de sessions de jeu sur des
              terrains de padel, de sessions de coaching par un coach professionnel et de produits ou
              services accessoires.<br /><br />

              La réservation d’un terrain de padel permet l’accès d’un maximum de quatre (4) joueurs pour
              la session réservée. Les quatre (4) joueurs doivent être les mêmes du début à la fin de la
              session de jeu réservée.<br /><br />

              La réservation d’un terrain de padel ne comprend pas la mise à disposition du petit matériel
              nécessaire à la session de jeu (raquettes, balles, etc.). Ce matériel peut être soit amené par
              le Client, soit loué par le Client auprès du Centre Casa Padel au moment de la réservation
              de la session de jeu sur l’Application, ou sur place avant le début de la session de jeu sous
              réserve du stock disponible.<br /><br />

              Toute réservation est considérée comme confirmée dès la réception par CASA PADEL du
              paiement par le Client, lequel recevra une confirmation par courrier électronique. La facture
              correspondante sera adressée au Client uniquement après la réalisation de la session de
              jeu.<br /><br />

              Chaque Service acheté ou réservé par le Client via l’Application l’est à titre intuitu personae.
              En conséquence, il est expressément convenu que le Client ne peut céder ou transférer à un
              tiers le bénéfice du Service payé.<br /><br />

              L’accès aux Centres Casa Padel est interdit aux mineurs de moins de 6 ans.<br /><br />
    
              <strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 2.1 &nbsp;&nbsp;&nbsp;&nbsp; Réservation de terrain pour match privatif</strong><br /><br />
              
              Le Client peut procéder à la réservation de terrains de padel pour l’organisation d’un match
              privatif dans le Centre Casa Padel de son choix et aux heures et jours ouvrés disponibles à
              la réservation sur l’Application.<br /><br />
             
              La réservation d’un terrain pour un match privatif donne lieu à la mise à disposition d’un
              terrain de padel au sein du Centre Casa Padel choisi par le Client pour une durée de 90
              (quatre-vingt-dix) minutes.<br /><br />
             
              Pour que la réservation soit effective, le Client doit réaliser le processus de réservation
              jusqu’à son terme en sélectionnant son moyen de paiement, en reconnaissant avoir pris
              connaissance des CGV et en déclarant ne pas avoir de contre-indication médicale à
              l’exercice du padel.<br /><br />
             
              Le Client peut choisir de payer uniquement sa part de location du terrain ou peut payer
              l’intégralité de la somme correspondant à la réservation totale du terrain. Dans l’hypothèse
              où le Client règle uniquement sa part de location du terrain, il conviendra que le complément
              du prix de la location pour la même session de jeu soit réglé par un ou plusieurs autres
              joueurs afin que la réservation puisse effectivement être validée.<br /><br />
             
              A défaut de règlement de l’intégralité du prix de réservation au plus tard 8 (huit) heures avant
              l’heure programmée de la session réservée, RESAPADEL procédera à l’annulation de la
              réservation et au remboursement du ou des Clients ayant réglé leur part.<br /><br />
             
              Le remboursement du Client s’effectuera dans un délai indicatif de 8 (huit) jours ouvrés à
              compter de la notification d’annulation adressée par email au Client.<br /><br />
             
              L’utilisation de l’Application est uniquement ouverte aux personnes majeures. Toutefois,
              dans le cadre d’un match privatif, le Client majeur peut réserver pour son compte et celui de
              plusieurs personnes mineures sous réserve que :<br />
                <ul style="list-style-type: '-  ';">
                  <li style="margin-bottom : .3rem">
                    Les mineurs soient âgés de plus de 6 ans ; et que
                  </li>
                  <li style="margin-bottom : .3rem">
                    le Client majeur soit présent pendant toute la durée du match privatif, le joueur
                    mineur étant sous sa responsabilité.
                  </li>
                </ul>
                <br />
                
              <strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 2.2 &nbsp;&nbsp;&nbsp;&nbsp; Réservation de terrain pour match compétitif</strong><br /><br />
              
              Lors de la création de son compte utilisateur, le Client a répondu à un certain nombre de
              questions ayant vocation à déterminer le niveau estimé du joueur, ce niveau étant
              matérialisé par une note sur 10 visible sur son espace personnel.<br /><br />

              Cette note a pour objectif de permettre à des utilisateurs de l’Application souhaitant jouer des
              matchs dits « compétitifs » de padel avec d’autres Clients, d’être mis en relation avec des
              joueurs de niveau équivalent qui disposent d’une note de niveau identique ou similaire de
              plus ou moins 1 point.<br /> <br />

              Par l’intermédiaire de l’Application le Client peut ainsi :<br />
                <ul style="list-style-type: '-  ';">
                  <li style="margin-bottom : .3rem">
                    soit réserver une place pour participer à un match compétitif créé par un autre
                    utilisateur de l’Application, sous réserve qu’il ait le niveau requis par le match
                    compétitif concerné ;
                  </li>
                  <li style="margin-bottom : .3rem">
                    soit créer un match compétitif dans le Centre Casa Padel de son choix en
                    sélectionnant le jour et l’heure auxquels il souhaiterait réserver le terrain concerné
                    et en indiquant si le match créé est ouvert aux femmes uniquement, aux hommes
                    uniquement ou est mixte.
                  </li>
                </ul>
                <br />
              
              Si un Client souhaite s’inscrire à un match compétitif pour lequel il n’a pas le niveau requis, il
              peut en faire la demande spécifique par le biais de l’Application. Il appartiendra au Client
              créateur du match compétitif concerné de valider ou non la possibilité pour le Client joueur
              de niveau différent de rejoindre ledit match.<br /> <br />
              
              Pour que la réservation d’une place par le Client dans un match compétitif soit effective (que
              le Client soit créateur du match concerné ou qu’il souhaite seulement participer à un match
              proposé par un autre client), le Client doit réaliser le processus de réservation jusqu’à son
              terme en sélectionnant son moyen de paiement, en reconnaissant avoir pris connaissance
              des CGV et en déclarant ne pas avoir de contre-indication médicale à l’exercice du padel.<br /> <br />

              Si un match compétitif ne comporte pas 4 (quatre) Clients inscrits au plus tard 8 (huit) heures
              avant l’heure programmée de la session réservée, la réservation du terrain pour le match
              compétitif concerné sera automatiquement annulée et RESAPADEL procédera au
              remboursement du ou des Clients ayant réglé leur part.<br /> <br />

              Le remboursement du Client s’effectuera dans un délai indicatif de 8 (huit) jours ouvrés à
              compter de la notification d’annulation du match compétitif adressée par email par
              RESAPADEL au Client.<br /> <br />
              
              <strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 2.3 &nbsp;&nbsp;&nbsp;&nbsp; Réservation de sessions de coaching</strong><br /><br />

              Le Client peut procéder à la réservation de sessions de coaching par l’intermédiaire de
              l’Application dans le Centre Casa Padel de son choix.<br /> <br />

              Le Client peut choisir de réserver une session unique de coaching ou d’acheter un pack de
              plusieurs sessions de coaching à utiliser à des dates de son choix sur une période d’un (1)
              an.<br /> <br />

              La réservation de sessions de coaching dans le cadre d’un pack de plusieurs sessions ne
              peut se faire qu’au sein du Centre Casa Padel sélectionné au moment de l’achat sur
              l’Application.<br /> <br />

              La réservation d’une session de coaching comprend :<br />
                <ul style="list-style-type: '-  ';">
                  <li style="margin-bottom : .3rem">
                    une session de 60 (soixante) ou 90 (quatre-vingt-dix) minutes de cours particulier
                    avec un coach de padel professionnel, selon l’offre disponible dans le Centre
                    Casa Padel concerné ;
                  </li>
                  <li style="margin-bottom : .3rem">
                    la mise à disposition du terrain de padel ;
                  </li>
                  <li style="margin-bottom : .3rem">
                    la mise à disposition de balles de padel ;
                  </li>
                  <li style="margin-bottom : .3rem">
                    l’accès aux équipements du Centre Casa Padel concerné, lesdits équipements
                    étant listés au moment de la réservation.
                  </li>
                </ul>
                <br />

              Selon le Centre Casa Padel où le Client souhaite réaliser sa session de coaching, le Client
              peut avoir l’opportunité de choisir entre plusieurs coachs.<br /> <br />

              Dans l’hypothèse où le coach sélectionné par le Client lors du processus de réservation
              serait indisponible pour quelque raison que ce soit, le Centre Casa Padel pourra prendre
              toute mesure nécessaire pour assurer son remplacement sans que cela n’ouvre droit à une
              demande de remboursement au bénéfice du Client.<br /> <br />

              Cependant, si par extraordinaire, le Centre Casa Padel concerné ne parvenait pas à assurer
              le remplacement du coach sélectionné par le Client pendant un délai d’un (1) mois, alors le
              Client pourra requérir le remboursement des sessions de coaching payées.<br /> <br />
              
              <strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 2.4 &nbsp;&nbsp;&nbsp;&nbsp; Location de petits matériels et achat de produits</strong><br /><br />

              Dans le cadre des processus de réservation de matchs privés, de matchs compétitifs ou de
              sessions de coaching, le Client peut, s’il le souhaite et selon les stocks disponibles du Centre
              Casa Padel sélectionné :<br />
                <ul style="list-style-type: '-  ';">
                  <li style="margin-bottom : .3rem">
                    acheter certains produits (bouteilles d’eau, balles, accessoires de raquettes tels
                    que des housses et des grips, vêtements, etc.) ;
                  </li>
                  <li style="margin-bottom : .3rem">
                    louer une ou plusieurs raquettes.
                  </li>
                </ul>
                <br />

              Le matériel mis à disposition en location doit être restitué dans le même état qu’au moment
              de sa mise à disposition. En cas de non-restitution du matériel mis à disposition,
              RESAPADEL se réserve le droit de bloquer la possibilité pour le Client de procéder à de
              nouvelles réservations par le biais de l’Application jusqu’à restitution complète ou
              remboursement du prix de la raquette.<br /> <br />

              En cas de casse par le Client du matériel mis à disposition, RESAPADEL se réserve le droit
              de demander au Client le remboursement du matériel endommagé.
              `,
	},
	conditionsOfAccessToTheApplication: {
		title: 'Article 3. &nbsp;&nbsp;&nbsp;&nbsp; <u>TARIFS ET MODALITES DE PAIEMENT</u>',
		content: `<strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 3.1 &nbsp;&nbsp;&nbsp;&nbsp; Tarifs</strong><br /><br />

              Les tarifs des Services proposés sur l’Application sont indiqués en euros et toutes taxes
              comprises.<br /><br />

              RESAPADEL se réserve le droit de modifier les prix affichés sur l’Application à tout moment,
              mais la prestation sélectionnée par le Client sera facturée sur la base du tarif en vigueur au
              moment de la validation de la réservation, sous réserve que le processus de paiement soit
              effectivement mené à son terme.<br /><br />

              <strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 3.2 &nbsp;&nbsp;&nbsp;&nbsp; Paiement par carte bancaire</strong><br /><br />
              
              Le paiement des réservations, de l’Abonnement visé à l’article 3.4 ou d’un pack de crédits
              « CASA » comme prévu à l’article 3.5 se fait sur l’Application par carte bancaire.<br /><br />

              Les données de paiement ne sont conservées ni par RESAPADEL ni par l’organisme chargé
              de la transaction financière. L’encaissement du prix est immédiat.<br /><br />

              Les informations que le Client communique sur l’Application lors de la passation de sa
              commande et du paiement par carte bancaire font l&#39;objet d&#39;un traitement automatisé de
              données par le biais de son partenaire Stripe. Ce traitement automatisé de données a pour
              finalité de définir un niveau d&#39;analyse des transactions et de lutter contre la fraude à la carte
              bancaire, usurpation et utilisation abusive de votre identité.<br /><br />

              Stripe et RESAPADEL sont les seuls destinataires des données en rapport avec la
              commande payée par carte bleue par le Client. Le numéro de carte bancaire du Client
              n’est jamais transmis ni utilisé tel quel mais bénéficie d&#39;un système d&#39;encodage et de
              cryptage sécurisé. Les standards de sécurité les plus élevés sont appliqués au stockage des
              données et sont tous conformes à la norme Payment Card Industry Data Security Standard
              (PCI DSS).<br /><br />

              Les cartes bancaires émises par des banques domiciliées hors de France doivent
              obligatoirement être des cartes bancaires internationales (Visa ou Mastercard).
              En cas d’erreur ou d’impossibilité de débiter la carte, la transaction est immédiatement
              résolue de plein droit et la réservation est annulée.<br /><br />

              <strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 3.3 &nbsp;&nbsp;&nbsp;&nbsp; Paiement par Apple Pay</strong><br /><br />
              
              Le Client a la possibilité de régler sa réservation via son compte Apple Pay qui est intégré à
              l’appareil Apple compatible du Client et s’appuie sur le portefeuille électronique Apple
              présent sur son appareil Apple compatible.<br /><br />

              Lors du processus de réservation, le Client peut sélectionner le paiement via Apple Pay, et
              est alors automatiquement dirigé sur une page dédiée, lui permettant de valider le paiement
              via les informations bancaires renseignées par lui au préalable sur son appareil Apple
              compatible.<br /><br />

              <strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 3.4 &nbsp;&nbsp;&nbsp;&nbsp; Souscription d’un Abonnement</strong><br /><br />
              
              RESAPADEL propose la souscription à un abonnement (ci-après « <strong>l’Abonnement</strong> »)
              permettant de bénéficier de la possibilité de réserver une session de 90 minutes par jour,
              non cumulable et non reportable, sans frais supplémentaires, pendant une durée d’un (1) an
              ou pendant des durées d’un (1) mois renouvelables sans engagement de durée.<br /><br />
              
              Au moment de la souscription de son Abonnement, le Client devra choisir le Centre Casa
              Padel auquel il souhaite s’affilier (ci-après le « <strong>Centre Casa Padel Affilié</strong> »).<br /><br />
              
              Les tarifs de l’Abonnement varient selon que le Centre Casa Padel Affilié sélectionné par le
              Client se situe ou non en Île de France.<br />
                <ul style="list-style-type: '-  ';">
                  <li style="margin-bottom : .3rem">
                    Si le Centre Casa Padel Affilié choisi par le Client est situé en Île de France, le Client
                    pourra utiliser son Abonnement au sein de tous les Centre Casa Padel de France.
                  </li>
                  <li style="margin-bottom : .3rem">
                    Si le Centre Casa Padel Affilié choisi par le Client est situé hors d’Île de France, le
                    Client ne pourra bénéficier de l’Abonnement qu’au sein de son Centre Casa Padel
                    Affilié.
                  </li>
                </ul>
                <br />
              
              Le paiement peut se faire en une seule fois ou peut donner lieu à des paiements mensuels
              automatisés par carte bancaire.<br /><br />
              
              L’Abonnement n’inclut pas la mise à disposition de petits matériels tels que les raquettes ou
              les balles qui devront faire l’objet de réservations complémentaires par l’Application si le
              Client souhaite en bénéficier.<br /><br />
              
              Un Abonnement souscrit pour une période annuelle peut être suspendu sur simple demande
              du Client dès lors que celui-ci peut justifier d’un problème de santé ou d’un empêchement
              physique, comme une grossesse, dans la limite d’une durée maximale de (12) mois de
              suspension. Passé ce délai, le temps de l’Abonnement restant à échoir reprendra son cours.<br /><br />
              
              Un Abonnement souscrit pour une période annuelle peut en outre être résilié si le Client
              justifie d’un déménagement dans une zone éloignée du Centre Casa Padel Affilié, d’une
              mutation professionnelle ou d’une incapacité définitive à la pratique sportive. Les sommes
              restant à échoir sur la période annuelle ne seront pas dues par le Client à compter de la date
              de notification de son justificatif à RESAPADEL.<br /><br />
              
              L’Abonnement est nominatif. Le Client ne peut céder ou transférer à quiconque de quelque
              manière que ce soit à titre gratuit ou onéreux l’Abonnement souscrit ni permettre à des tiers
              de bénéficier des avantages liés à son Abonnement en ses lieu et place.<br /><br />

              <strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 3.5 &nbsp;&nbsp;&nbsp;&nbsp; Achat de pack de crédits « CASA »</strong><br /><br />
              
              Le Client peut procéder à l’achat de « CASA » qui correspondent à des crédits à dépenser
              sur l’Application pour procéder à la réservation de terrains pour des matchs privés et à la
              réservation pour la participation à des matchs compétitifs.<br /><br />
              
              Un CASA correspond à un euro TTC.<br /><br />
              
              Les CASA peuvent être utilisés par le Client sans limitation de durée tant que ce dernier
              dispose d’un compte actif sur l’Application.<br /><br />

              <strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 3.6 &nbsp;&nbsp;&nbsp;&nbsp; Réservation via un Pass GymLib</strong><br /><br />
              
              Certains Centres Casa Padel sont partenaires de GymLib et acceptent que les réservations
              soient réglées par le biais du pass GymLib (ci-après le <strong>« Pass GymLib »</strong>).<br /><br />
              
              Le Pass Gymlib consiste à permettre aux abonnés d’assister à des séances ou cours de
              sport organisés par des partenaires de Gymlib – dont certains Centres Casa Padel font
              partie –, dans des infrastructures mises à disposition par ces partenaires.<br /><br />
              
              <strong>Les Centres Casa Padel acceptant la réservation par l’intermédiaire du Pass GymLib
              sont ceux proposant la réservation par le biais de ce moyen de paiement lors du
              processus d’achat sur l’Application.</strong><br /><br />
              
              Les Clients qui bénéficient d’un Pass Gymlib peuvent bénéficier des services offerts par
              l’Application, dans les limites de l’abonnement qu’ils ont souscrit avec GymLib.<br /><br />
              
              Les conditions générales de vente Gymlib validées et acceptées par les abonnés au Pass
              Gymlib précisent expressément que ces derniers sont soumis aux conditions définies par le
              partenaire.<br /><br />
              
              Les CGV, les CGU, ainsi que la Politique de confidentialité de RESAPADEL, sont donc
              applicables aux abonnés du Pass Gymlib dès lors qu’ils peuvent bénéficier des services
              offerts par un Centre Casa Padel.<br /><br />
              
              Pour valider son créneau de réservation en utilisant son Pass GymLib, le Client doit
              sélectionner « GymLib » parmi les moyens de paiement disponibles et insérer le code
              correspondant à son Pass GymLib.<br /><br />
              
              À son arrivée dans le Centre Casa Padel concerné pour la séance réservée par
              l’intermédiaire de son Pass GymLib, le Client doit confirmer son paiement par
              géolocalisation.<br /><br />
              
              En cas de non-présentation du Client à trois réservations effectuées avec le Pass GymLib,
              RESAPADEL se réserve le droit de bloquer la possibilité de toute nouvelle réservation par ce
              moyen. La réouverture des réservations pour le Client par l’intermédiaire de son Pass
              GymLib sera ensuite rendue à nouveau possible à la seule discrétion de RESAPADEL.`,
	},
	intellectualProperty: {
		title: 'Article 4. &nbsp;&nbsp;&nbsp;&nbsp; RETARDS ET CONDITIONS D’ANNULATION',
		content: `<strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 4.1 &nbsp;&nbsp;&nbsp;&nbsp; Retards</strong><br /><br />

              Le début de la session de padel de 90 minutes réservée par le Client court à compter du
              début de l’heure de la réservation telle que mentionnée sur le récapitulatif de réservation
              disponible dans l’email de confirmation adressée par RESAPADEL et non pas à compter de
              l’arrivée du Client au sein du Centre Casa Padel concerné ou du début effectif de la séance.<br /><br />

              Par conséquent, tout retard du Client ou d’un ou plusieurs autres joueurs est insusceptible
              de donner lieu à remboursement, même partiel, ou à une prolongation de la durée de la
              session de jeu réservée par le Client. Ainsi, le Client et les autres joueurs en retard perdront
              les minutes de jeu en raison de leur retard.<br /><br />

              Il est donc recommandé d’arriver plusieurs minutes avant l’heure effective du début de la
              réservation si le Client et les joueurs souhaitent utiliser les vestiaires et être sur le terrain à
              l’heure de leur réservation.<br /><br />

              <strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 4.2 &nbsp;&nbsp;&nbsp;&nbsp; Conditions d’annulation</strong><br /><br />

              Le Client peut annuler sa réservation de match privé, de match compétitif ou de séance de
              coaching au plus tard 4 (quatre) à 8 (huit) heures avant la l’heure programmée de la
              réservation. Le délai précis pour annuler une session dépend des conditions de chaque
              Centre Casa Padel et est explicitement rappelé au moment de la réservation.<br /><br />

              Dans l’hypothèse où le Client respecte le délai d’annulation susmentionné, RESAPADEL
              procédera alors à la restitution de 100% de la somme versée par le Client dans un délai de 8
              (huit) jours ouvrés à compter de la date à laquelle RESAPADEL recevra la notification écrite
              d’annulation de la réservation, adressée par le Client par le biais de l’Application.<br /><br />

              Dans l’hypothèse où le Client annulerait sa réservation d’un match privé ou compétitif ou
              d’une session de coaching dans un délai ne respectant pas le délai minimum imposé par le
              Centre Casa Padel concerné pour bénéficier d’un remboursement, RESAPADEL conservera
              100% de la somme versée à la réservation par le Client et ce, quel que soit le motif de
              l’annulation du Client.<br /><br />

              Les réservations prises par le Client ne sont pas modifiables, qu’il s’agisse notamment du
              lieu, de la date ou de l’heure. Toute demande de modification sera donc considérée comme
              une annulation et traitée conformément aux conditions indiquées ci-dessus.`,
	},
	personalDataAndRespect: {
		title: 'Article 5. &nbsp;&nbsp;&nbsp;&nbsp; OBLIGATIONS DU CLIENT ',
		content: `Avant sa première séance et de manière régulière, RESAPADEL recommande au Client de
              faire vérifier son aptitude à la pratique d’une activité sportive.<br /><br />

              Le Client bénéficie des équipements et installations du Centre Casa Padel concerné et mis à
              sa disposition dans le cadre des Services sous sa seule responsabilité.<br /><br />

              Le Client est tenu d’utiliser les équipements et installations du Centre Casa Padel concerné
              en bon père de famille et s’engage à faire respecter par les autres joueurs les dispositions du
              règlement intérieur du Centre Casa Padel concerné.<br /><br />

              Le Client est notamment tenu de :<br />
                <ul style="list-style-type: '-  ';">
                  <li style="margin-bottom : .3rem">
                    ne procéder à aucune modification d’aménagement ou d’agencement du terrain
                    réservé et des équipements le garnissant, d’utiliser l’ensemble des équipements
                    mis à disposition pour l’usage qui leur est destiné ;
                  </li>
                  <li style="margin-bottom : .3rem">
                    ne pas dépasser le nombre de joueurs sur un match tel qu’indiqué au moment de la
                    réservation ;
                  </li>
                  <li style="margin-bottom : .3rem">
                    respecter l’ensemble des consignes de sécurité des équipements mis à sa
                    disposition et de demander conseil à un professionnel du Centre Casa Padel
                    concerné en cas de doute ;
                  </li>
                  <li style="margin-bottom : .3rem">
                    disposer d’une tenue et de chaussures raisonnablement adaptés à la pratique
                    sportive ;
                  </li>
                  <li style="margin-bottom : .3rem">
                    procéder aux changements vestimentaires au sein des vestiaires et resté vêtu au
                    sein des zones communes ;
                  </li>
                  <li style="margin-bottom : .3rem">
                    respecter les consignes de sécurité et d’hygiène et notamment l’interdiction de
                    fumer ; l’interdiction d’amener ou de faire entrer par tout moyen des animaux ;
                    l’interdiction d’amener ou de consommer des boissons alcoolisées ; l’interdiction de
                    créer un trouble pouvant gêner la jouissance des autres joueurs du Centre Casa
                    Padel où le terrain est situé ou tout trouble à l’ordre public ; l’interdiction de salir,
                    dégrader, casser, détruire et/ou porter atteinte d’une quelque manière que ce soit
                    aux objets, mobiliers, biens, sols, murs ainsi qu’à toutes infrastructures du terrain ;
                    l’obligation de respecter la propreté et la destination des locaux.
                  </li>
                </ul>
                <br />

              Les équipements ne peuvent en aucune manière être utilisés par des mineurs sans la
              surveillance du Client majeur, une telle utilisation étant en tout état de cause sous la seule
              responsabilité du Client majeur responsable.`,
	},
	availabilityOfTheApplication: {
		title: 'Article 6. &nbsp;&nbsp;&nbsp;&nbsp; SERVICE CLIENT',
		content: `Pour toute réclamation relative à une réservation dans un Centre Casa Padel sur
              l’Application, un service client du Centre Casa Padel concerné est à la disposition du Client
              ou de tout utilisateur intéressé et, joignable aux coordonnées (numéro de téléphone ou
              adresse de contact) visibles sur l’Application.`,
	},
	responsibility: {
		title: 'Article 7. &nbsp;&nbsp;&nbsp;&nbsp; DONNEES PERSONNELLES',
		content: `Les informations recueillies par RESAPADEL lors de la réservation de prestations via
              l’Application font l’objet d’un traitement automatisé et sont destinées à RESAPADEL, à ses
              entités, à ses partenaires exploitant un Centre Casa Padel, et à ses prestataires sous-
              traitants (notamment aux prestataires de paiement en ligne).<br /><br />

              Conformément aux dispositions de la loi n°78-17 du 6 janvier 1978 dite loi « Informatique et
              libertés », les Clients disposent d’un droit d’accès, de modification, de rectification ainsi que
              d’un droit d’opposition au traitement de leurs données à caractère personnel en adressant un
              courrier postal à l’adresse suivante : 24, quai du 4 septembre – 92100 Boulogne Billancourt
              ou un email à <a href="mailto:contact@casapadel.fr">contact@casapadel.fr</a>.<br /><br />

              Le Client reconnaît avoir pris connaissance de la Politique de Confidentialité disponible à
              l’adresse suivante, et avoir été informé de ses droits, ainsi que des modalités de leur
              exercice qui y sont exposées : <a href="https://casapadel.fr/" target="_blank">https://casapadel.fr</a>.`,
	},
	applicableLaw: {
		title: 'Article 8. &nbsp;&nbsp;&nbsp;&nbsp; ASSURANCE',
		content: `Il incombe au Client de pourvoir à l’assurance notamment de sa personne, de ses propres
              biens, des tiers, en ce compris les mineurs qui l’accompagnent, et à la couverture de sa
              responsabilité dans le cadre du bénéfice des Services.<br /><br />

              Sous réserve du parfait respect des dispositions du présent règlement intérieur du Centre
              Casa Padel concerné, les Clients des Centres Casa Padel bénéficient du contrat
              d’assurance responsabilité civile dudit Centre Casa Padel concerné.`,
	},
	withdrawaldeadline: {
		title: 'Article 9. &nbsp;&nbsp;&nbsp;&nbsp; DELAI DE RETRACTATION',
		content: `Le Client est informé, qu’en application de l’article L. 221-28, 12° du Code de la
              consommation, la fourniture d’activités de loisirs à une date ou une période déterminée ne
              bénéficie pas du droit de rétractation prévue à l’article L. 221-18 dudit Code. Par conséquent,
              le Client ne saurait rétracter sa ou ses réservations réalisées via l’Application.<br /><br />

              L’adhésion à l’Abonnement par le Client ouvre droit à un délai de rétractation de 14 jours,
              s’agissant d’un contrat souscrit à distance. Ce droit de rétractation peut s’exercer en
              adressant un email à l’adresse : <a href="mailto:contact@casapadel.fr">contact@casapadel.fr</a>, dans les quatorze (14) jours
              suivant le jour de la souscription et en y joignant le formulaire de rétractation figurant en
              Annexe 1, à la fin des présentes CGV.<br /><br />

              RESAPADEL remboursera le Client au plus tard dans les quatorze (14) jours qui suivent la
              date à laquelle RESAPADEL a reçu la notification d’exercice de son droit de rétractation par
              le Client.<br /><br />

              Cependant, conformément aux dispositions de l’article L. 221-28-1° du Code de la
              consommation, si le Client décide de bénéficier des Services offerts par l’Application avec
              son Abonnement avant l’expiration du délai de 14 jours, il reconnait renoncer à son droit de
              rétractation en commençant à exécuter le contrat qui le lie à RESAPADEL.`,
	},
	terminationformisconduct: {
		title: 'Article 10. &nbsp;&nbsp;&nbsp;&nbsp; RESILIATION POUR FAUTE',
		content: `RESAPADEL se réserve la faculté de résilier les Services souscrits, que cela soit à l’unité ou
              sous forme d’un Abonnement, de plein droit et sans formalité, pour non-respect de l’une des
              dispositions des présentes CGV ou du règlement intérieur du Centre Casa Padel concerné,
              quinze (15) jours après réception par le Client d’une mise en demeure écrite adressée en
              lettre simple ou par courrier électronique et indiquant l’intention de faire jouer la présente
              clause.<br /><br />

              RESAPADEL pourra également refuser l’accès à l’Application au Client concerné et
              procéder à la suppression de son compte utilisateur.<br /><br />

              En cas de résiliation pour faute du Client, le Client sera inscrit sur un fichier contentieux
              intitulé « Incidents » établi par RESAPADEL dans le respect des dispositions de la loi n°78-
              17 du 6 janvier 1978 dite loi « Informatique et Libertés ».<br /><br />

              En cas de résiliation pour faute, RESAPADEL pourra déduire du remboursement du prorata
              d’un Abonnement, d’un pack de sessions de coaching ou d’un pack d’achat de « CASA », le
              montant d’éventuels préjudices subis. Plus particulièrement, en cas de non-paiement des
              sommes dues par le Client ou en cas d’infractions graves, la résiliation n’entraînera aucun
              remboursement mais au contraire le Client restera tenu du paiement des sommes
              contractuellement dues jusqu’à l’échéance de l’Abonnement ou de la prestation concernée.`,
	},
	partialvoid: {
		title: 'Article 11. &nbsp;&nbsp;&nbsp;&nbsp; NULLITE PARTIELLE',
		content: `L’annulation de l’une des stipulations des CGV n’entraînerait l’annulation de celles-ci dans
              leur ensemble, que pour autant que la stipulation litigieuse puisse être considérée, dans
              l’esprit des Parties, comme substantielle et déterminante, et que son annulation remette en
              cause l’équilibre contractuel général.<br /><br />

              En cas d’annulation d’une des stipulations contractuelles entre les Parties, considérée
              comme non-substantielle, les Parties s’efforceront de négocier une clause économiquement
              équivalente.`,
	},
  disputes: {
    title: 'Article 12. &nbsp;&nbsp;&nbsp;&nbsp; LITIGES',
    content: `En cas de désaccord ou litige entre les Parties, les CGV sont soumises au droit français.<br /><br />

              Le Client déclare avoir reçu une traduction des CGV dans une langue qu’il a choisie et qu’il
              comprend parmi celles qui lui ont été proposées lors de la création de son compte, étant
              précisé que la rédaction française des CGV et l’interprétation qui en découle prévalent sur
              toute traduction.<br /><br />

              Tout litige découlant de la validité, exécution, résiliation des contrats liant les Parties est
              soumis aux tribunaux compétents dans les conditions de droit commun.<br /><br />

              A défaut de solution amiable, le Client peut recourir gratuitement, dans les conditions
              prévues aux articles L. 612-1 et suivants et R. 612-1 et suivants du Code de la
              consommation à un médiateur de la consommation en vue de la résolution amiable de tout
              litige l&#39;opposant à RESA PADEL en lien avec les présentes.<br /><br />

              Dans l’attente de sa nomination, RESAPADEL renvoie vers le lien suivant :
              <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=FR" target="_blank">https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=FR</a><br /><br />

              Avant de saisir le médiateur, le Client doit avoir adressé au préalable une réclamation écrite
              à RESAPADEL.<br /><br />
              
              <div style="text-align: center;">
                <strong>ANNEXE 1 – FORMULAIRE DE RETRACTATION ABONNEMENT <br /> <br />
                 <i>A envoyer par lettre recommandée avec accusé de réception dans les 14 jours du
                    lendemain de la souscription de l’Abonnement RESAPADEL à l’adresse postale : 24,
                    quai du 4 septembre – 92100 Boulogne Billancourt ou par email
                    à <a href="mailto:contact@casapadel.fr">contact@casapadel.fr</a></i>
                </strong>
              </div><br /><br />

              Madame, Monsieur,<br /><br />

              Je notifie par la présente ma rétractation du contrat portant sur ma souscription à
              l’Abonnement RESAPADEL que j'ai conclu sur l'Application RESAPADEL.<br /><br />

              Mes informations client sont les suivantes :<br /><br />

              NOM :<br />
              Prénom :<br />
              Date de souscription de l'Abonnement :<br />
              Numéro d'Abonnement :<br />
              Centre Casa Padel Affilié :<br />
              Email :<br />
              Adresse Postale :<br />
              Téléphone :<br /><br />
              Fait à ____________, le _____________<br /><br />
              Signature obligatoire :`,
  },
};
