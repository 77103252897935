import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

interface SvgImageProps extends SvgIconProps {
  src?: string;
  fill?: string;
  backgroundColor?: string;
}

const SvgImage: React.FC<SvgImageProps> = ({
  src,
  width,
  height,
  fill,
  ...rest
}) => {
  return (
    <SvgIcon width={width} height={height} {...rest}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill={fill}
        viewBox={`0 0 ${width} ${height}`.replaceAll("px", "")}
      >
        {/* Dynamically load the SVG content from the provided src */}
        {src ? (
          <image href={src} width="100%" height="100%" />
        ) : (
          <rect width="100%" height="100%" fill={fill} />
        )}
      </svg>
    </SvgIcon>
  );
};

export default SvgImage;
