import { useQuery } from "@tanstack/react-query";
import { fetchDataFromApi } from "../../Clubs/clubsApiService";
import { CLUBS } from "../../../config/API.constants";

const listClubsAPI = CLUBS.LIST_CLUBS_LEAN;

const useFetchClubsLean = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: ["clubsListLean"],
    queryFn: async () => {
      const result = await fetchDataFromApi(listClubsAPI, 0, 1000000, true);

      const clubRowArr: any = (result as any[] | undefined)
        ?.filter((club: any) => club.status === "active")
        .map((club: any) => ({
          id: club?._id,
          name: club?.name,
        }));

      return clubRowArr;
    },
    refetchOnWindowFocus: false,
    refetchInterval: false,
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchOnMount: true,
  });

  return { data, isLoading, error };
};

export default useFetchClubsLean;
