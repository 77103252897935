import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, Grid, Typography, Button, FormControl } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/es'; // Import the Spanish locale
import 'dayjs/locale/fr'; // Import the French locale
import 'dayjs/locale/en'; // Import the English locale
import { toast } from 'react-toastify';
import { showToast } from 'app/shared/util/toastHelper';
import moment from 'moment';
import { useAuth } from '../../../AuthContext';

import { Form, Formik, ErrorMessage } from 'formik';

import * as XLSX from 'xlsx';
import exportExcel from 'app/shared/components/exportExcel';

import { fetchTransactionsList } from './transactionsApiService';
import { AppDispatch } from 'app/redux/store';

const brownButtonStyle = {
	backgroundColor: '#333333',
	color: 'white', // Text color
};

const Transactions: React.FC = () => {
	const dispatch = useDispatch<AppDispatch>();
	const { t } = useTranslation();

	const { user, profileData } = useAuth();

	const [datePickerFromOpen, setDatePickerFromOpen] = useState(false);
	const [datePickerToOpen, setDatePickerToOpen] = useState(false);

	const [fromDate, setFromDate] = useState<any>(dayjs());
	const [toDate, setToDate] = useState<any>(dayjs());

	const createTableData = (response: any, fromDate: any, toDate: any) => {
		const header2 = [
			t('date'),
			t('name'),
			t('club'),
			t('bookingDate'),
			t('bookingInitiatedBy'),
			t('resourcePaidFor'),
			t('paymentType'),
			t('amount'),
			t('status'),
			t('credits'),
			t('casa'),
		];

		const body2 = response?.map((item: any) => [
			item?.date || '',
			item?.name || '',
			item?.club || '',
			item?.startTime || '',
			item?.bookingInitiatedBy || '',
			item?.resourcePaidFor || '',
			item?.paymentType || '',
			item?.amount || '',
			item?.status || '',
			item?.credits || 0,
			item?.casaCredits || 0,
		]);

		const finalTablesBody = [header2, ...body2, []];

		const FileName = `Transactions_${moment(fromDate).format('DD-MM-YYYY')}_${moment(
			toDate
		).format('DD-MM-YYYY')}.xlsx`;

		const wb = exportExcel(`Transactions`, finalTablesBody); // Use the utility function
		// Export the workbook
		XLSX.writeFile(wb, FileName);

		showToast('Transactions report downloaded successfully', 'Success', {
			position: toast.POSITION.TOP_RIGHT,
		});
	};

	return (
		<>
			<Grid container spacing={2} sx={{ marginTop: '0.5rem' }}>
				<Grid item xs={12} md={8}>
					<Typography
						variant="h4"
						sx={{ fontSize: '26px', color: '#45464E', fontWeight: 600 }}
					>
						{t('transactions')}
					</Typography>
				</Grid>
			</Grid>

			<Grid container spacing={2} sx={{ marginTop: '0.5rem' }}>
				<Grid item xs={12} md={12}>
					<Card
						elevation={3}
						style={{
							marginBottom: 8,
							marginTop: 15,
							boxShadow: ' 0px 2px 11px 0px rgba(0, 0, 0, 0.15)',
						}}
					>
						<CardContent>
							<Grid container spacing={2} style={{ marginTop: '10px' }}>
								<Grid item xs={8} md={8}>
									<div>
										<Formik
											initialValues={{ fromDate: dayjs(), toDate: dayjs() }}
											onSubmit={(values, actions) => {}}
											enableReinitialize
										>
											{({ values }: any) => {
												return (
													<>
														<Form>
															<Grid
																container
																spacing={2}
																style={{ marginTop: '10px' }}
															>
																<Grid item xs={12}>
																	<Grid
																		container
																		spacing={2}
																		alignItems={'end'}
																	>
																		<Grid item xs={4}>
																			{' '}
																			<Typography
																				sx={{
																					flex: 1,
																					fontSize:
																						'12px',
																					textTransform:
																						'uppercase',
																				}}
																				className="label"
																			>
																				{t('fromDate')}
																			</Typography>
																			<LocalizationProvider
																				dateAdapter={
																					AdapterDayjs
																				}
																				adapterLocale={
																					localStorage.getItem(
																						'language'
																					) || 'en'
																				}
																			>
																				<FormControl
																					fullWidth
																					style={{
																						marginTop:
																							'10px',
																					}}
																				>
																					<DatePicker
																						onChange={(
																							date: any
																						) => {
																							setFromDate(
																								date
																							);
																						}}
																						format="DD/MM/YYYY"
																						value={
																							fromDate
																						}
																						maxDate={dayjs()} // Disable future dates
																						open={
																							datePickerFromOpen
																						}
																						onOpen={() =>
																							setDatePickerFromOpen(
																								true
																							)
																						}
																						onClose={() =>
																							setDatePickerFromOpen(
																								false
																							)
																						}
																						slotProps={{
																							textField:
																								{
																									onClick:
																										() =>
																											setDatePickerFromOpen(
																												true
																											),
																								},
																						}}
																					/>
																				</FormControl>
																			</LocalizationProvider>
																			<ErrorMessage
																				name={`fromDate`}
																				component="div"
																				className="error-message"
																				render={(
																					msg: any
																				) => (
																					<span
																						className="MuiFormHelperText-root Mui-error"
																						style={{
																							color: '#d32f2f',
																							marginRight:
																								'10px',
																							fontSize:
																								'12px',
																						}}
																					>
																						{msg}
																					</span>
																				)}
																			/>
																		</Grid>{' '}
																		<Grid item xs={4}>
																			{' '}
																			<Typography
																				sx={{
																					flex: 1,
																					fontSize:
																						'12px',
																					textTransform:
																						'uppercase',
																				}}
																				className="label"
																			>
																				{t('toDate')}
																			</Typography>
																			<LocalizationProvider
																				dateAdapter={
																					AdapterDayjs
																				}
																				adapterLocale={
																					localStorage.getItem(
																						'language'
																					) || 'en'
																				}
																			>
																				<FormControl
																					fullWidth
																					style={{
																						marginTop:
																							'10px',
																					}}
																				>
																					<DatePicker
																						onChange={(
																							date: any
																						) => {
																							setToDate(
																								date
																							);
																						}}
																						format="DD/MM/YYYY"
																						value={
																							toDate
																						}
																						maxDate={dayjs()} // Disable future dates
																						open={
																							datePickerToOpen
																						}
																						onOpen={() =>
																							setDatePickerToOpen(
																								true
																							)
																						}
																						onClose={() =>
																							setDatePickerToOpen(
																								false
																							)
																						}
																						slotProps={{
																							textField:
																								{
																									onClick:
																										() =>
																											setDatePickerToOpen(
																												true
																											),
																								},
																						}}
																					/>
																				</FormControl>
																			</LocalizationProvider>
																			<ErrorMessage
																				name={`toDate`}
																				component="div"
																				className="error-message"
																				render={(
																					msg: any
																				) => (
																					<span
																						className="MuiFormHelperText-root Mui-error"
																						style={{
																							color: '#d32f2f',
																							marginRight:
																								'10px',
																							fontSize:
																								'12px',
																						}}
																					>
																						{msg}
																					</span>
																				)}
																			/>
																		</Grid>
																		<Grid item xs={4}>
																			<Button
																				variant="contained"
																				color="primary"
																				style={
																					brownButtonStyle
																				}
																				sx={{
																					height: '55px',
																				}}
																				//type="submit"
																				onClick={() => {
																					let params: any =
																						{
																							...values,
																							// fromDate:
																							// 	'2024-09-04T00:50:37.250',
																							// toDate: '2024-09-30T07:50:37.250',
																							fromDate:
																								fromDate
																									? dayjs(
																											fromDate
																									  )
																											.startOf(
																												'day'
																											)
																											.format(
																												'YYYY-MM-DDTHH:mm:ss.SSS'
																											)
																									: dayjs()
																											.startOf(
																												'day'
																											)
																											.format(
																												'YYYY-MM-DDTHH:mm:ss.SSS'
																											),
																							toDate: toDate
																								? dayjs(
																										toDate
																								  )
																										.endOf(
																											'day'
																										)
																										.format(
																											'YYYY-MM-DDTHH:mm:ss.SSS'
																										)
																								: dayjs()
																										.endOf(
																											'day'
																										)
																										.format(
																											'YYYY-MM-DDTHH:mm:ss.SSS'
																										),
																						};

																					if (
																						user?.userType ===
																							'club_admin' &&
																						profileData
																							?.clubs
																							?.length >
																							0
																					) {
																						params.clubs =
																							profileData?.clubs?.join(
																								','
																							);
																					}

																					dispatch(
																						fetchTransactionsList(
																							params,
																							(
																								data: any
																							) => {
																								createTableData(
																									data,
																									params?.fromDate,
																									params?.toDate
																								);
																							}
																						)
																					);
																				}}
																			>
																				{t('download')}
																			</Button>
																		</Grid>
																	</Grid>
																</Grid>
															</Grid>
														</Form>
													</>
												);
											}}
										</Formik>
									</div>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</>
	);
};

export default Transactions;
