import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import translationEN from "../../languages/en/translation.json";
import translationFR from "../../languages/fr/translation.json";
import translationES from "../../languages/es/translation.json";

const languages = ["en", "fr", "es"];

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    lng: localStorage.getItem("language") || "en", // Default language
    fallbackLng: localStorage.getItem("language") || "en", // Fallback language
    supportedLngs: languages,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
    resources: {
      en: {
        translation: translationEN,
      },
      fr: {
        translation: translationFR,
      },
      es: {
        translation: translationES,
      },
    },
  });

export default i18n;
