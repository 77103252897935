// src/redux/feedReducer.ts
import { createSlice } from '@reduxjs/toolkit';

const feedSlice = createSlice({
  name: 'feed',
  initialState: { data: [], loading: false, error: null },
  reducers: {
    feedRequested: (state) => {
        state.loading = true;
        state.error = null;
      },
      feedReceived: (state, action) => {
        state.loading = false;
        state.data = action.payload;
      },
      feedRequestFailed: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      },
  },
});

export const { feedRequested,
    feedReceived,
    feedRequestFailed, } = feedSlice.actions;

export default feedSlice.reducer;
