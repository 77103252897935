import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate} from 'react-router-dom';
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

const LanguageSwitcher: React.FC = () => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  const changeLanguage = (
    event: SelectChangeEvent<string>,
    child: React.ReactNode
  ) => {
    i18n.changeLanguage(event.target.value);
    localStorage.setItem("language", event.target.value);

    if(window.location.pathname?.includes('/termsConditions/')){
      navigate(`/termsConditions/${event.target.value}`)
    }
    if(window.location.pathname?.includes('/privacy/')){
      navigate(`/privacy/${event.target.value}`)
    }
  };

  return (
    <Select
      value={i18n.language}
      onChange={changeLanguage}
      MenuProps={{
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      }}
    >
      <MenuItem value="en" selected>
        {t("English")}
      </MenuItem>
      <MenuItem value="fr">{t("French")}</MenuItem>
      <MenuItem value="es">{t("Spanish")}</MenuItem>
    </Select>
  );
};

export default LanguageSwitcher;
