import React from "react";
import { Typography, Box } from "@mui/material";

interface PageProps {
  title: string;
  content?: any;
}

const HowToSignup: React.FC<PageProps> = ({ title, content }: any) => {
  return (
    <Box sx={{ marginTop: "2rem" }}>
      <Typography
        gutterBottom
        variant="h6"
        sx={{
          fontWeight: 500,
          marginBottom: "1rem",
          textTransform: "uppercase",
        }}
      >
        {title}
      </Typography>

      <Box>
        <Typography
          component="div"
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      </Box>
    </Box>
  );
};

export default HowToSignup;
