import axios, {
  AxiosProgressEvent,
  AxiosRequestConfig,
  AxiosError,
} from "axios";
import {
  IPresignedUrlRequest,
  IPresignedUrlResponse,
} from "../models/FeedPageModels";
import { MEDIA_UPLOAD_MANAGEMENT } from "../../../config/API.constants";
import { SERVER_API_URL } from "../../../config/constants";
import { showToast } from "app/shared/util/toastHelper";

interface FileUploadData {
  file: File;
  url: string;
  setUploadProgress: (progress: number) => void;
}
export const uploadTos3PresignedUrl = async (
  fileUploadData: FileUploadData
): Promise<any> => {
  const { file, url, setUploadProgress } = fileUploadData;

  const headers = {
    "Content-Type": file?.type,
  };

  const result = await axios?.put(url, file, {
    headers,
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      if (progressEvent?.total && progressEvent?.total !== 0) {
        const fileProgress = Math.round(
          (progressEvent?.loaded * 100) / progressEvent?.total
        );
        setUploadProgress(fileProgress);
      }
    },
  });

  if (result.status !== 200) {
    throw new Error("Failed to upload file to S3");
  }
  return result;
};

export const generatePresignedUrl = async (
  input: IPresignedUrlRequest
): Promise<IPresignedUrlResponse | null> => {
  const presignedUrlsAPI =
    MEDIA_UPLOAD_MANAGEMENT.API_END_POINTS.GET_PRE_SIGNED_URL;
  const apiURL = `${SERVER_API_URL}${presignedUrlsAPI}`;
  const accessToken = localStorage?.getItem("accessToken");
  const axiosConfig: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken ? `Bearer ${accessToken}` : "",
    },
  };

  try {
    const response = await axios?.post(apiURL, input, axiosConfig);
    return response?.data;
  } catch (error: unknown) {
    // Check if error is an AxiosError
    if (axios?.isAxiosError(error)) {
      const axiosError = error as AxiosError; // Cast error to AxiosError
      if (axiosError?.response && axiosError?.response?.data) {
        // Extract error message from response data if known
        if (
          typeof axiosError?.response?.data === "object" &&
          "message" in axiosError?.response?.data
        ) {
          const errorMessage = axiosError?.response?.data?.message;
          showToast(`${errorMessage}`, "Error");
        } else {
          console.log("Unknown error response:", axiosError?.response?.data);
        }
      } else {
        // The request was made but no response was received
        console.error("No response received:", axiosError?.request);
      }
    } else {
      // Handle other types of errors (e.g., network error, request setup error)
      console.error("Error:", error);
    }
    return null;
  }
};
