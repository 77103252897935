import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, Typography } from '@mui/material';

import GeneralInformation from './GeneralInformation';
// import UserResponsibilities from "./UserResponsibilities";
// import PurchaseProcess from "./PurchaseProcess";
import PremiumPlan from './PremiumPlan';
import Availability from './Availability';
import PricesPayment from './PricesPayment';
import Promotions from './Promotions';
import Errors from './Errors';
import PurchaseBonus from './PurchaseBonus';
import Cancellations from './Cancellations';
import DisclaimerLiability from './DisclaimerLiability';
import Indemnity from './Indemnity';
import CommunicationsNotifications from './CommunicationsNotifications';
import Waiver from './Waiver';
import Nullity from './Nullity';
import CompleteAgreement from './CompleteAgreement';
import DataProtection from './DataProtection';
import LegislationJurisdiction from './LegislationJurisdiction';
import ComplaintsClaims from './ComplaintsClaims';

import { TermsSalesFR } from 'app/languages/fr/terms-sales-translation';
import CommonTitleContent from './CommonTitleContent';
import MentionsLegales from './MentionsLegales';
import ConfidentialityAndPersonalData from './ConfidentialityAndPersonalData';
import { LegalConditionsFR } from 'app/languages/fr/legal-conditions-translation';
import { PrivacyFR } from 'app/languages/fr/privacy-translation';
import LegalConditionsData from '../LegalConditions/LegalConditionsData';
import PrivacyData from '../Privacy/PrivacyData';

const TermsConditions: React.FC = () => {
	const navigate = useNavigate();
	const { i18n, t } = useTranslation();
	const { lang } = useParams<{ lang: string }>();

	const selectedLanguage: string = localStorage.getItem('accessToken')
		? i18n.language || lang || 'en'
		: lang || 'en';

	useEffect(() => {
		// Set the i18n language if it's not the same as the selected language
		if (selectedLanguage && i18n.language !== selectedLanguage) {
			i18n.changeLanguage(selectedLanguage);
		}

		// Navigate to a route with the selected language if needed
		// navigate(`/termsConditions/${selectedLanguage}`);
	}, [selectedLanguage, i18n, navigate]);

	// let termsSalesContent =
	//   selectedLanguage === "es"
	//     ? TermsConditionsES
	//     : selectedLanguage === "fr"
	//     ? TermsConditionsFR
	//     : TermsConditionsEN;

	let termsSalesContent = TermsSalesFR;
	let legalConditionsContent = LegalConditionsFR;
	let PrivacyContent = PrivacyFR;

	return (
		<>
			{' '}
			<Container maxWidth="md" sx={{ marginTop: '2rem', marginBottom: '2rem' }}>
				<table style={{width: "100%", borderCollapse: "collapse", textAlign: "center", margin: "1rem auto"}}>
					<tbody>
						<tr>
							<td style={{border: "1px solid #ccc", padding: "25px 0", verticalAlign: "top"}}>
							<strong>CONDITIONS GENERALES DE VENTE</strong>
							<div style={{margin: "16px 0"}}><strong>APPLICATION RESAPADEL</strong></div>
							<u style={{fontSize: "14px"}}>Date de dernière mise à jour : 1 er octobre 2024</u>
							</td>
						</tr>
					</tbody>
				</table>
				<GeneralInformation
					title={termsSalesContent?.generalInfo?.title}
					content={termsSalesContent?.generalInfo?.content}
				/>
				<CommonTitleContent
					title={termsSalesContent?.acceptanceAndModification?.title}
					content={termsSalesContent?.acceptanceAndModification?.content}
				/>
				<CommonTitleContent
					title={termsSalesContent?.accountcreation?.title}
					content={termsSalesContent?.accountcreation?.content}
				/>
				<CommonTitleContent
					title={termsSalesContent?.conditionsOfAccessToTheApplication?.title}
					content={termsSalesContent?.conditionsOfAccessToTheApplication?.content}
				/>
				<CommonTitleContent
					title={termsSalesContent?.intellectualProperty?.title}
					content={termsSalesContent?.intellectualProperty?.content}
				/>
				<CommonTitleContent
					title={termsSalesContent?.personalDataAndRespect?.title}
					content={termsSalesContent?.personalDataAndRespect?.content}
				/>
				<CommonTitleContent
					title={termsSalesContent?.availabilityOfTheApplication?.title}
					content={termsSalesContent?.availabilityOfTheApplication?.content}
				/>
				<CommonTitleContent
					title={termsSalesContent?.responsibility?.title}
					content={termsSalesContent?.responsibility?.content}
				/>
				<CommonTitleContent
					title={termsSalesContent?.applicableLaw?.title}
					content={termsSalesContent?.applicableLaw?.content}
				/>
				<CommonTitleContent
					title={termsSalesContent?.withdrawaldeadline?.title}
					content={termsSalesContent?.withdrawaldeadline?.content}
				/>
				<CommonTitleContent
					title={termsSalesContent?.terminationformisconduct?.title}
					content={termsSalesContent?.terminationformisconduct?.content}
				/>
				<CommonTitleContent
					title={termsSalesContent?.partialvoid?.title}
					content={termsSalesContent?.partialvoid?.content}
				/>
				<CommonTitleContent
					title={termsSalesContent?.disputes?.title}
					content={termsSalesContent?.disputes?.content}
				/>
			</Container>
		</>
	);
};

export default TermsConditions;
