import React from "react";
import Modal from "@mui/material/Modal";
import { Button, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { AlertCircle } from "../../config/constants";
import { useTranslation } from "react-i18next";

interface DeleteModalProps {
  id?: any;
  openModal?: any;
  onClose?: any;
  modalTxt?: any;
  entityName?: any;
  action?: () => void;
  refreshFeeds?: () => void;
}

const brownButtonStyle = {
  backgroundColor: "#333333",
  color: "white", // Text color
  marginTop: "30px",
};

const colorLessButtonStyle = {
  backgroundColor: "#FFFFFF",
  color: "#333333", // Text color
  marginTop: "30px",
  border: "1px solid #333333",
};

const DeletePopup: React.FC<DeleteModalProps> = ({
  openModal,
  onClose,
  id,
  modalTxt,
  entityName,
  refreshFeeds,
  action,
}) => {
  const { t } = useTranslation();

  const handleCloseConfirmation = () => {
    handleClose();
  };

  const handleConfirm = async () => {
    // Close the confirmation dialog
    handleCloseConfirmation();
    switch (entityName) {
      case "feeds":
        if (action) {
          action();
        }
        handleClose();
        if (refreshFeeds) {
          refreshFeeds(); // Call refreshFeeds function
        }
        break;
      case "bookings":
        // TODO: Why feed is getting deleted here?
        // await deleteFeed(id);
        handleClose();
        if (refreshFeeds) {
          refreshFeeds(); // Call refreshFeeds function
        }
        break;
      default:
        break;
    }

    handleClose();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: "rgba(0,0,0,0.4)",
            },
          },
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            textAlign: "center",
            left: "50%",
            width: "25%",
            height: "25vh",
            borderRadius: "5px",
            transform: "translate(-50%, -50%)",
            padding: 16,
            background: "rgba(255, 255, 255, 0.9)",
            overflowY: "auto",
          }}
        >
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            sx={{
              position: "absolute",
              top: "15px",
              right: "24px",
              cursor: "pointer",
            }}
          >
            <CloseIcon />
          </IconButton>
          <div>
            <img
              width="100"
              alt="delete_confirmation_image"
              src={AlertCircle}
            />
          </div>
          <Typography style={{ marginBottom: "16px" }}>
            {t("areYouSureWantToDeleteThisFeed")}
          </Typography>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {" "}
            {/* Adjusted styling */}
            <Button
              onClick={handleCloseConfirmation}
              style={colorLessButtonStyle}
            >
              {t("cancel")}
            </Button>
            <Button onClick={handleConfirm} style={brownButtonStyle}>
              {t("delete")}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DeletePopup;
