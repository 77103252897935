export const options = {
  weekday: "short" as const,
  year: "numeric" as const,
  month: "long" as const,
  day: "numeric" as const,
};

export const formatTimestamp = (timestamp: any) => {
  const date = new Date(timestamp);
  const formattedDate = date?.toLocaleDateString("en-US", options);

  // Format the time in 12-hour format with AM or PM
  const hours = date?.getHours();
  const minutes = date?.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedTime = `${hours % 12 || 12}:${minutes
    ?.toString()
    .padStart(2, "0")} ${ampm}`;

  // Combine the formatted date and time
  const formattedDateTime = `${formattedDate} ${formattedTime}`;

  return formattedDateTime;
};
