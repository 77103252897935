// src/redux/dashboardReducer.ts
import { createSlice } from "@reduxjs/toolkit";

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: { data: [], loading: false, error: null },
  reducers: {
    dashboardRequested: (state) => {
      state.loading = true;
      state.error = null;
    },
    dashboardReceived: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    dashboardRequestFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    dashboardData: (state) => {
      state.loading = false;
      state.data = [];
    },
  },
});

export const { dashboardRequested, dashboardReceived, dashboardRequestFailed } =
  dashboardSlice.actions;

export default dashboardSlice.reducer;
