import React from 'react'
import { Typography } from "@mui/material";
import CommonTitleContent from "./CommonTitleContent";
import ConfidentialityAndPersonalData from "./ConfidentialityAndPersonalData";

const PrivacyData = (PrivacyContent:any = null) => {
  const content = PrivacyContent.PrivacyContent;
  return (
    <>
    <Typography variant="h5" sx={{ fontWeight: 500, marginBottom: "1rem", textTransform: 'capitalize' }}>
        politique de confidentialité
        </Typography>
        {/* <GeneralInformation
          title={PrivacyContent?.generalInfo?.title}
          content={PrivacyContent?.generalInfo?.content}
        /> */}
        {/* <CommonTitleContent
          title={PrivacyContent?.acceptanceAndModification?.title}
          content={PrivacyContent?.acceptanceAndModification?.content}
        />
        <CommonTitleContent
          title={PrivacyContent?.accountcreation?.title}
          content={PrivacyContent?.accountcreation?.content}
        />
        <CommonTitleContent
          title={PrivacyContent?.conditionsOfAccessToTheApplication?.title}
          content={PrivacyContent?.conditionsOfAccessToTheApplication?.content}
        />
        <CommonTitleContent
          title={PrivacyContent?.intellectualProperty?.title}
          content={PrivacyContent?.intellectualProperty?.content}
        />
        <CommonTitleContent
          title={PrivacyContent?.personalDataAndRespect?.title}
          content={PrivacyContent?.personalDataAndRespect?.content}
        />
        <CommonTitleContent
          title={PrivacyContent?.availabilityOfTheApplication?.title}
          content={PrivacyContent?.availabilityOfTheApplication?.content}
        />
        <CommonTitleContent
          title={PrivacyContent?.responsibility?.title}
          content={PrivacyContent?.responsibility?.content}
        />
        <CommonTitleContent
          title={PrivacyContent?.applicableLaw?.title}
          content={PrivacyContent?.applicableLaw?.content}
        /> */}
          {/* <MentionsLegales
          title={PrivacyContent?.mentionsLegales?.title}
          content={PrivacyContent?.mentionsLegales?.content}
        /> */}
                <ConfidentialityAndPersonalData
          title={content?.confidentialityAndPersonalData?.title}
          content={content?.confidentialityAndPersonalData?.content}
        />
        <CommonTitleContent
          title={content?.whoIsTheController?.title}
          content={content?.whoIsTheController?.content}
        />
        <CommonTitleContent
          title={content?.howAreYourPersonal?.title}
          content={content?.howAreYourPersonal?.content}
        />
        <CommonTitleContent
          title={content?.whatPersonalDataIsLikelyToBeCollected?.title}
          content={content?.whatPersonalDataIsLikelyToBeCollected?.content}
        />
        <CommonTitleContent
          title={content?.personalDataStorageDuration?.title}
          content={content?.personalDataStorageDuration?.content}
        />
        <CommonTitleContent
          title={content?.thirdPartyIntervention?.title}
          content={content?.thirdPartyIntervention?.content}
        />
        <CommonTitleContent
          title={content?.placeOfStorageOfYourData?.title}
          content={content?.placeOfStorageOfYourData?.content}
        />
        <CommonTitleContent
          title={content?.yourRightsRegardingPersonalData?.title}
          content={content?.yourRightsRegardingPersonalData?.content}
        />
        <CommonTitleContent
          title={content?.contactResponsableConformite?.title}
          content={content?.contactResponsableConformite?.content}
        />
        {/* <PurchaseProcess
          title={content?.purchaseProcess?.title}
          content={content?.purchaseProcess?.content}
        />
        <PremiumPlan
          title={content?.premiumPlan?.title}
          content={content?.premiumPlan?.content}
        />
        <Availability
          title={content?.availability?.title}
          content={content?.availability?.content}
        />{" "}
        <PricesPayment
          title={content?.pricesPayment?.title}
          content={content?.pricesPayment?.content}
        />
        <Promotions
          title={content?.promotions?.title}
          content={content?.promotions?.content}
        />
        <PurchaseBonus
          title={content?.purchaseBonus?.title}
          content={content?.purchaseBonus?.content}
        />
        <Errors
          title={content?.errors?.title}
          content={content?.errors?.content}
        />{" "}
        <Cancellations
          title={content?.cancellations?.title}
          content={content?.cancellations?.content}
        />
        <DisclaimerLiability
          title={content?.disclaimerLiability?.title}
          content={content?.disclaimerLiability?.content}
        />
        <Indemnity
          title={content?.indemnity?.title}
          content={content?.indemnity?.content}
        />
        <CommunicationsNotifications
          title={content?.communications?.title}
          content={content?.communications?.content}
        />
        <Waiver
          title={content?.waiver?.title}
          content={content?.waiver?.content}
        />
        <Nullity
          title={content?.nullity?.title}
          content={content?.nullity?.content}
        />
        <CompleteAgreement
          title={content?.completeAgreement?.title}
          content={content?.completeAgreement?.content}
        />
        <DataProtection
          title={content?.dataProtection?.title}
          content={content?.dataProtection?.content}
        />
        <LegislationJurisdiction
          title={content?.legislation?.title}
          content={content?.legislation?.content}
        />
        <ComplaintsClaims
          title={content?.complaintClaims?.title}
          content={content?.complaintClaims?.content}
        /> */}
    </>
  )
}

export default PrivacyData