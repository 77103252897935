import { USER_ROLE } from "app/config/constants";
import { POST, LOGIN } from "../../config/API.constants";
import { CUSTOM_ERROR_MSG, HANDLE_ERROR, HTTP_CALL } from "../../config/http";
import { isEmpty } from "lodash";
interface TokenData {
  exp: number; // expiration time in seconds
  userType: string;
  userId: string;
  email: string;
  expiresIn: string;
  iat: string;
  // Include other token claims as needed
}

export const handelLogin = async (
  username: string,
  password: string,
  userType = "admin"
) => {
  try {
    const loginAPI =
      userType === USER_ROLE.COACH
        ? LOGIN.COACH_USER_LOGIN
        : LOGIN.ADMIN_USER_LOGIN;
    let response: any = {};
    const params = {
      email: username,
      password: password,
    };
    // Clear the session variable
    localStorage.removeItem("accessToken");
    response = await HTTP_CALL(loginAPI, POST, "", params);
    const {
      data: { data: apiData, statusCode, message },
    } = response;
    if (statusCode === 200) {
      if (!isEmpty(apiData)) {
        const token = apiData?.accessToken;
        localStorage.setItem("accessToken", token);
        localStorage.setItem(
          "username",
          apiData?.firstName
            ? `${apiData?.firstName} ${apiData?.lastName}`
            : username
        );
        return token;
      }
    } else {
      CUSTOM_ERROR_MSG(message ? message : statusCode);
    }
  } catch (error: any) {
    HANDLE_ERROR(error);
  }
};

export const isTokenExpired = (token: string): boolean => {
  // Your token expiration logic here
  // For simplicity, let's assume a token is expired after 24 hours
  const expirationTime = 3600 * 1000 * 24; // 24 hour in milliseconds
  const currentTime = Date.now();
  const tokenData = decodeToken(token); // Assuming you have a function to decode the token
  return currentTime > tokenData.exp * 1000 + expirationTime;
};

export const decodeToken = (token: string): TokenData => {
  try {
    const decodedToken = token ? JSON.parse(atob(token?.split(".")[1])) : "";
    return decodedToken;
  } catch (error) {
    throw new Error("Failed to decode token");
  }
};

export const getCurrentUser = () => {
  try {
    const user = decodeToken(localStorage.getItem("accessToken") || "");
    return user;
  } catch (error) {
    return {};
  }
};

export const logout = (): void => {
  let userType = localStorage.getItem("userType");

  localStorage.removeItem("profileData");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("username");
  localStorage.removeItem("settings");
  localStorage.removeItem("userType");

  window.location.href =
    userType === USER_ROLE.COACH ? "/coach/signIn" : "/signIn";
};
