import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Autocomplete,
  TextField,
  Typography,
  Grid,
  Avatar,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { fetchAdminList } from "app/entities/Users/usersApiService";
import { toCamelCase } from "app/shared/util/ToCamelCase";
import { AppDispatch } from "../../redux/store";
import { uniqBy, sortBy } from "lodash";
import { fetchEndUsers } from "app/entities/Users/usersApiService";

export const UsersMultiSelect = ({
  name,
  label,
  onChange,
  value,
  multiple = false,
  disabled = false,
  isShowAdminUsers = true,
  listAll = false,
}: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [users, setUsers] = useState<any>([]);
  const [selectedUsers, setSelectedUsers] = useState<any>(multiple ? [] : null);

  // Fetch users data from API
  useEffect(() => {
    if (isShowAdminUsers) {
      dispatch(
        fetchAdminList(
          {
            pageSize: 10000,
            page: 1,
          },
          (data: any) => {
            let newData = uniqBy(data, "_id");
            setUsers(sortBy(newData, "firstName") || []);
          },
          true
        )
      );
    } else {
      const fetchUsersData = async (page: any, limit: any) => {
        try {
          let userApiData = await fetchEndUsers(page, limit, listAll); // Call the data fetching function
          userApiData = userApiData?.filter(
            (x: any) => x?.status === "active" && x?.firstName
          );
          setUsers(sortBy(userApiData, "firstName"));
        } catch (error) {}
      };
      fetchUsersData(0, 10000);
    }
  }, []);

  // Map array of IDs to array of user objects
  useEffect(() => {
    if (value && !multiple) {
      // Check if it's a single selection
      // For single selection, find the user object corresponding to the selected ID
      const selectedUserObject: any | undefined = users?.find(
        (user: any) => user?._id === value
      );
      setSelectedUsers(selectedUserObject || null);
    } else if (value && Array.isArray(value)) {
      // Check if it's a multiple selection
      const selectedUserObjects = users.filter((user: any) =>
        value?.includes(user?._id)
      );
      setSelectedUsers(selectedUserObjects);
    } else {
      setSelectedUsers([]);
    }

    // Cleanup function
    return () => {
      setSelectedUsers(null);
    };
  }, [value, users, multiple]);

  // Handle change event
  const handleOnChange = (event: any, newValue: any) => {
    // Ensure newValue is always an array if multiple selections are allowed
    setSelectedUsers(newValue);

    onChange(
      multiple ? newValue?.map((user: any) => user?._id) : newValue?._id
    ); // Pass array of IDs to parent component
  };

  return (
    <>
      {label && (
        <Typography className="uppercase label" sx={{ marginBottom: "1rem" }}>
          {label}
        </Typography>
      )}
      <Autocomplete
        id={name}
        options={users || []}
        getOptionLabel={(option: any) =>
          toCamelCase(option?.firstName, option?.lastName)
        }
        multiple={multiple}
        value={selectedUsers}
        onChange={handleOnChange} // Pass the custom onChange handler
        renderInput={(params: any) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={t("selectUsers")}
          />
        )}
        renderOption={(props, user, { selected }) => (
          // Customize how each option is displayed in the dropdown
          <li {...props} key={user?._id}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Avatar
                  src={user?.profilePic}
                  alt={`${user?.firstName} ${user?.lastName}`}
                />
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  {toCamelCase(user?.firstName, user?.lastName)}
                </Typography>
              </Grid>
              {user?.playerLevel ? (
                <Grid item sx={{ marginLeft: "1rem" }}>
                  <Typography variant="body1">
                    [Player Level: {parseFloat(user?.playerLevel)?.toFixed(2)}]
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
          </li>
        )}
        disabled={disabled}
      />
    </>
  );
};
