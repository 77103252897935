import React from "react";
import { IconButton } from "@mui/material";
import { getAllEmojis } from "../../../../shared/util/emojiGenerator";

interface EmojiSelectorProps {
  onSelect: (emoji: string) => void;
}

const EmojiSelector: React.FC<EmojiSelectorProps> = ({ onSelect }) => {
  // Load your emojis data or component here

  const emojis = getAllEmojis(); // Example emojis

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "5px",
        width: "300px", // Set your desired width
        height: "72px", // Set your desired height
        overflowY: "auto", // Enable vertical scrolling
        border: "1px solid #ccc",
        padding: "5px",
        borderRadius: "8px",
      }}
    >
      {emojis?.map((emoji, index) => (
        <IconButton
          key={emoji[index]}
          onClick={() => onSelect(emoji)}
          style={{ fontSize: "24px" }}
        >
          {emoji}
        </IconButton>
      ))}
    </div>
  );
};

export default EmojiSelector;
