export const getAllEmojis = () => {
  const emojis = [];
  for (let i = 0x1f600; i <= 0x1f64f; i++) {
    emojis.push(String.fromCodePoint(i));
  }
  for (let i = 0x1f300; i <= 0x1f5ff; i++) {
    emojis.push(String.fromCodePoint(i));
  }
  for (let i = 0x1f680; i <= 0x1f6ff; i++) {
    emojis.push(String.fromCodePoint(i));
  }
  for (let i = 0x1f700; i <= 0x1f77f; i++) {
    emojis.push(String.fromCodePoint(i));
  }
  // ... add more ranges as needed

  return emojis;
};
