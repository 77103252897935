import { useQuery } from "@tanstack/react-query";
import { fetchFeedItemComments } from "../apiService/feedAPIService";
import { FEED_ITEM_COMMENTS_LIST } from "../../../config/constants";

const useFetchFeedItemComments = (feedId: string) => {
  const { data, isLoading, error } = useQuery({
    queryKey: [FEED_ITEM_COMMENTS_LIST, feedId],
    queryFn: async () => await fetchFeedItemComments(feedId),
  });

  return { data, isLoading, error };
};

export default useFetchFeedItemComments;
