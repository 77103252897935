const NewFacebookShare = () => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `
      <head>
        <meta
          property="og:url"
          content="https://cpadmin-dev.casapadel.fr/feed/dsd"
        />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="When Great Minds Don’t Think Alike"
        />
        <meta
          property="og:description"
          content="How much does culture influence creative thinking?"
        />
        <meta
          property="og:image"
          content="http://static01.nyt.com/images/2015/02/19/arts/international/19iht-btnumbers19A/19iht-btnumbers19A-facebookJumbo-v2.jpg"
        />
      </head>

      <h1>Meta Developer Documentation</h1>
      <p>
        Explore Meta Developer documentation for AR/VR, Business, Chat, Games,
        Machine Learning, Monetization, Open Source Social.
      </p>
    </>`,
      }}
    ></div>
  );
};

export default NewFacebookShare;
