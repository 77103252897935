import WebSocket from "isomorphic-ws";

const WS_URL =
  process.env.REACT_APP_WEBSOCKET_URL ||
  "wss://xso9c1tyr3.execute-api.eu-west-1.amazonaws.com/dev/";

let WSService: WebSocketService | null | any = null;

class WebSocketService {
  websocket: any;
  messageListeners: any;
  isOpen: boolean;

  constructor() {
    this.websocket = null;
    this.messageListeners = [];
    this.isOpen = false;
  }

  /**
   *  Set up WebSocket connection for a new user and
   *  basic listeners to handle events
   */
  initSocket = (conversationId: any) => {
    this.websocket = new WebSocket(
      `${WS_URL}?conversationId=${conversationId}`
    ) as WebSocket | null;
    this.websocket.onopen = this.onConnOpen;
    this.websocket.onmessage = this.onMessage;
    this.websocket.onclose = this.onConnClose;
  };

  /**
   *  Show connection status to user
   */
  onConnOpen = () => {
    this.isOpen = true;
  };

  /**
   *  Log lost connection for now
   */
  onConnClose = () => {
    this.websocket?.close();
  };

  /**
   *  Used by application to send message to the WebSocket API Gateway
   *  @param routeKey The route key for WebSocket API Gateway
   *  @param message String message
   *  message {
   *    room,
   *    type,
   *    msg,
   *    username,
   *    for
   *  }
   */
  sendMessage = (message: any) => {
    if (this.websocket && this.isOpen) {
      console.log(`Sending websocket message: ${JSON.stringify(message)}`);
      this.websocket.send(JSON.stringify(message));
    } else {
      console.log(`Websocket connection not found!!`);
    }
  };

  /**
   *  Used by application to register different listeners for
   *  different message types [To be used later]
   *  @param room Conversation
   *  @param type Message type ['all']
   *  @param listener Function to handle message type
   */
  addMessageListener = (room: string, type: string, listener: Function) => {
    if (!type || !room || typeof listener !== "function") {
      return;
    }
    this.messageListeners.push({
      room,
      type,
      listener,
    });
  };

  /**
   * Handler that receives the actual messages from the WebSocket API
   * For now it simply returns the parsed message body
   * @param data Message body received from WebSocket
   */
  onMessage = (data: { data: string }) => {
    if (data) {
      const message = JSON.parse(data.data);

      const typeListener: any = this.messageListeners.find(
        (listener: { type: string; listener: Function }) =>
          listener.type === "all"
      );

      if (typeListener && typeof typeListener?.listener === "function") {
        typeListener?.listener(message);
      } else {
        console.log("No handler found for message type");
      }
    }
  };

  static initWSService(conversationId: any) {
    if (WSService) {
      // Close existing connection, if any and reopen a new one for the new conversation
      WSService.websocket.close();

      WSService = new WebSocketService();
      WSService.initSocket(conversationId);
      return WSService;
    }

    if (!WSService) {
      WSService = new WebSocketService();
      WSService.initSocket(conversationId);
      return WSService;
    }

    return WSService;
  }
}

export const getWSService = WebSocketService.initWSService;
