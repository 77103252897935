import React, { useEffect } from "react";
import {
  IconButton,
  Typography,
  Divider,
  Box,
  Modal,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FacebookIcon from "@mui/icons-material/Facebook";
import { useTranslation } from "react-i18next";

import { IModalFeedItemShareProps } from "../../models/FeedPageModels";
import {
  loadFacebookSDK,
  handleShareFacebook,
  handleShareInstagram,
} from "./ShareFacebook";

declare global {
  interface Window {
    FB: {
      init: (params: {
        appId: string;
        autoLogAppEvents: boolean;
        xfbml: boolean;
        version: string;
      }) => void;
      ui: (
        params: {
          method: string;
          href: string;
          quote?: string;
          hashtag?: string;
        },
        callback?: (response: any) => void
      ) => void;
    };
  }
}

const prodUrl = "https://cpadmin.casapadel.fr";

const ModalFeedItemShare: React.FC<IModalFeedItemShareProps> = ({
  open,
  onClose,
  post,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!window.FB) {
      loadFacebookSDK();
    }
  }, []);

  const handleShareClick = (type = "facebook") => {
    if (type === "instagtam") handleShareInstagram(post, prodUrl);
    else handleShareFacebook(post, prodUrl, onClose);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "80%",
          maxWidth: "400px",
          maxHeight: "80%",
          borderRadius: "5px",
          transform: "translate(-50%, -50%)",
          padding: 3,
          backgroundColor: "white",
          textAlign: "center",
          overflow: "auto",
        }}
      >
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
          }}
        >
          <CloseIcon />
        </IconButton>

        <Typography variant="h6" component="div" fontWeight="bold" gutterBottom>
          {t("SharePost")}
        </Typography>
        <Divider sx={{ marginY: 2 }} />

        <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            startIcon={<FacebookIcon />}
            onClick={() => handleShareClick("facebook")}
          >
            Share on Facebook
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalFeedItemShare;
