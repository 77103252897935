import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Divider,
} from "@mui/material";

import ChangePasswordForm from "./modals/ChangePasswordForm";
import { useAuth } from "../../../AuthContext";

const ProfilePage: React.FC = () => {
  const { t } = useTranslation();
  const { profileData } = useAuth();

  if (!profileData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Grid container spacing={2} style={{ marginTop: "15px" }}>
        <Grid item xs={8}>
          <Typography
            sx={{ fontSize: "26px", color: "#45464E", fontWeight: 600 }}
          >
            {t("profile")}
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card
            elevation={3}
            style={{ marginBottom: 16, marginTop: 16, marginRight: 16 }}
          >
            <CardContent sx={{ padding: "25px" }}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Typography
                    gutterBottom
                    className="uppercase"
                    sx={{ color: "#8F8F8F", fontSize: "12px" }}
                  >
                    {t("firstName")}
                  </Typography>
                  <Box
                    className="capitalize"
                    sx={{
                      fontSize: "14px",
                      color: "#333",
                      fontWeight: 500,
                    }}
                  >
                    {profileData?.firstName || ""}
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    gutterBottom
                    className="uppercase"
                    sx={{ color: "#8F8F8F", fontSize: "12px" }}
                  >
                    {t("lastName")}
                  </Typography>
                  <Box
                    className="capitalize"
                    sx={{
                      fontSize: "14px",
                      color: "#333",
                      fontWeight: 500,
                    }}
                  >
                    {profileData?.lastName || ""}
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    gutterBottom
                    className="uppercase"
                    sx={{ color: "#8F8F8F", fontSize: "12px" }}
                  >
                    {t("email")}
                  </Typography>
                  <Box
                    sx={{
                      fontSize: "14px",
                      color: "#333",
                      fontWeight: 500,
                    }}
                  >
                    {profileData?.email || ""}
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    gutterBottom
                    className="uppercase"
                    sx={{ color: "#8F8F8F", fontSize: "12px" }}
                  >
                    {t("phone")}
                  </Typography>
                  <Box
                    className="capitalize"
                    sx={{
                      fontSize: "14px",
                      color: "#333",
                      fontWeight: 500,
                    }}
                  >
                    {`( ${profileData?.countryCode || ""} )`}
                    {"  "}
                    {`${profileData?.phone || ""}`}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                {/* Change Password start */}
                <Grid item xs={12}>
                  <Typography
                    gutterBottom
                    className="label"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600!important",
                      color: "#333",
                    }}
                  >
                    {t("changePassword")}
                  </Typography>
                </Grid>

                <Grid item xs={5}>
                  <ChangePasswordForm
                    callback={() => {
                      // dispatch(
                      //   getSingleConfiguration((data: any) => {
                      //     setConfigurationDetails(data);
                      //   })
                      // );
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default ProfilePage;
