// src/redux/bookingsReducer.ts
import { createSlice } from "@reduxjs/toolkit";

const bookingsSlice = createSlice({
  name: "bookings",
  initialState: { data: [], loading: false, error: null },
  reducers: {
    bookingsRequested: (state) => {
      state.loading = true;
      state.error = null;
    },
    bookingsReceived: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    bookingsRequestFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    bookingsReset: (state) => {
      state.loading = false;
      state.data = [];
    },
    courtBookingsRequested: (state) => {
      state.loading = true;
      state.error = null;
    },
    courtBookingsReceived: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const {
  bookingsRequested,
  bookingsReceived,
  bookingsRequestFailed,
  bookingsReset,
  courtBookingsRequested,
  courtBookingsReceived,
} = bookingsSlice.actions;

export default bookingsSlice.reducer;
