// src/redux/coachingReducer.ts
import { createSlice } from "@reduxjs/toolkit";

const tagSlice = createSlice({
  name: "tags",
  initialState: { data: [], loading: false, error: null },
  reducers: {
    tagsRequested: (state) => {
      state.loading = true;
      state.error = null;
    },
    tagsReceived: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    tagsRequestFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    updateTagData: (state: any, action) => {
      const updatedData = state?.data?.data?.map((x: any) =>
        x?._id === action?.payload?._id
          ? { ...x, ...action.payload, updated: true }
          : x
      );

      return {
        ...state,
        loading: false,
        data: { ...state?.data, data: updatedData },
      };
    },
    singleTagRequested: (state) => {
      state.loading = true;
      state.error = null;
    },
    singleTagReceived: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    singleTagRequestFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    tagsReset: (state) => {
      state.loading = false;
      state.data = [];
    },
  },
});

export const {
  tagsRequested,
  tagsReceived,
  tagsRequestFailed,
  singleTagRequested,
  singleTagReceived,
  singleTagRequestFailed,
  updateTagData,
  tagsReset,
} = tagSlice.actions;

export default tagSlice.reducer;
