import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'; // Import the Provider
import store from './app/redux/store';
import './index.css';
import App from './App';
import i18n from './app/shared/data/i18n';
import { I18nextProvider } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ResizeObserverErrorHandler from './app/shared/components/ResizeObserverErrorHandler';
import * as Sentry from '@sentry/react';
import { APP_ENV } from './app/config/constants';

if (APP_ENV !== 'development' && APP_ENV !== 'develop') {
	Sentry.init({
		dsn: 'https://22840b7b1e22ec0cd674e2ea54b59a82@o4506659360079872.ingest.us.sentry.io/4506903612620800',
		tracesSampleRate: 1.0,
		tracePropagationTargets: ['localhost', /^https:\/\/amplifyapp\.com/],
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
		environment: APP_ENV,
		debug: true,
		sampleRate: 1.0,
		maxBreadcrumbs: 100,
		attachStacktrace: true,
		autoSessionTracking: true,
		maxValueLength: 10000,
		enableTracing: true,
		profilesSampleRate: 1.0,

		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration({
				maskAllText: false,
				blockAllMedia: false,
			}),
		],
	});
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
	<I18nextProvider i18n={i18n}>
		<ResizeObserverErrorHandler />
		<Provider store={store}>
			<App /> <ToastContainer />
		</Provider>
	</I18nextProvider>
);
// Handle errors
i18n.on('languageChanged', () => {
	if (i18n.services.backendConnector.state[`${i18n.language}`] === 'failedLoading') {
		// Handle the error (e.g., show a message to the user)
	}
});
