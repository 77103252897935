import { useQuery } from "@tanstack/react-query";
import { fetchAllUsers } from "../apiService/allUsersApiService";

const useFetchAllUsers = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: ["allUsersList"],
    queryFn: async () => await fetchAllUsers(),
    refetchOnWindowFocus: false,
    refetchInterval: false,
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchOnMount: true,
  });

  return { data, isLoading, error };
};

export default useFetchAllUsers;
