// apiService.ts
import { Dispatch, AnyAction } from "redux";

import { CUSTOM_ERROR_MSG, HANDLE_ERROR, HTTP_CALL } from "../../config/http";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isEmpty } from "lodash";

import { dashboardReceived } from "app/redux/dashboardReducer";

import { GET, POST, DASHBOARD } from "../../config/API.constants";
import { showToast } from "app/shared/util/toastHelper";

type AppDispatch = Dispatch<AnyAction>;

export const fetchDashboard =
  (params: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};

    const filterParamsObj = {
      ...params,
      limit: params?.pageSize || 10,
      page: params?.page || 1,
    };

    const filteredSearchParams = new URLSearchParams(filterParamsObj);

    try {
      response = await HTTP_CALL(
        `${DASHBOARD.GET_DASHBOARD}?${filteredSearchParams.toString()}`,
        GET
      );

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (typeof callback === "function") {
        callback(apiData);
      }

      dispatch(dashboardReceived(response?.data));

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

export const fetchDashboardTournaments =
  (params: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};

    const filterParamsObj = {
      ...params,
      limit: params?.pageSize || 10,
      page: params?.page || 1,
    };

    const filteredSearchParams = new URLSearchParams(filterParamsObj);

    try {
      response = await HTTP_CALL(
        `${
          DASHBOARD.GET_DASHBOARD_TOURNAMENTS
        }?${filteredSearchParams.toString()}`,
        GET
      );

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (typeof callback === "function") {
        callback(apiData);
      }

      dispatch(dashboardReceived(response?.data));

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

export const sendBroadcastMessage =
  (values: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};
    try {
      response = await HTTP_CALL(
        DASHBOARD.SEND_BROADCAST_MESSAGE,
        POST,
        "",
        values
      );

      showToast("Message Sent Successfully", "Success", {
        position: toast.POSITION.TOP_RIGHT,
      });

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (typeof callback === "function") {
        callback(response);
      }

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };
