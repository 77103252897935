import React from "react";
import { CircularProgress, Box } from "@mui/material";

const FullPageLoader = () => {
  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      bgcolor="rgba(0, 0, 0, 0.5)"
      zIndex={9999}
    >
      <CircularProgress color="primary" />
    </Box>
  );
};

export default FullPageLoader;
