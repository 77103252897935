import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Typography } from "@mui/material";

import HowToSignup from "./HowToSignup";
import HowToSignupSocialAccounts from "./HowToSignupSocialAccounts";
import HowtoReserveCourt from "./HowtoReserveCourt";
import Payment from "./Payment";
import HowtoJointheMatch from "./HowtoJointheMatch";
import HowtoJointhePrivateMatch from "./HowtoJointhePrivateMatch";
import HowtoJointheTournament from "./HowtoJointheTournament";
import HowtoBookCoachingSession from "./HowtoBookCoachingSession";
import MyBookings from "./MyBookings";

import { HelpEN } from "app/languages/en/help-translation";
import { HelpES } from "app/languages/es/help-translation";
import { HelpFR } from "app/languages/fr/help-translation";

const Help: React.FC = () => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const { lang } = useParams<{ lang: string }>();

  const selectedLanguage: string = i18n.language || lang || "en";

  useEffect(() => {
    if (selectedLanguage) {
      navigate(`/help/${selectedLanguage}`);
    }
  }, [navigate, selectedLanguage]);

  let helpContent =
    selectedLanguage === "es"
      ? HelpES
      : selectedLanguage === "fr"
      ? HelpFR
      : HelpEN;

  return (
    <>
      {" "}
      <Container maxWidth="md" sx={{ marginTop: "2rem", marginBottom: "2rem" }}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: 600,
            marginBottom: "1rem",
            textTransform: "uppercase",
          }}
        >
          {t("helpScreen.getStarted")} - Resa Padel
        </Typography>
        <HowToSignup
          title={helpContent?.howToSignup?.title}
          content={helpContent?.howToSignup?.content}
        />
        <HowToSignupSocialAccounts
          title={helpContent?.howToSignSocialAccounts?.title}
          content={helpContent?.howToSignSocialAccounts?.content}
        />
        <HowtoReserveCourt
          title={helpContent?.howtoReserveCourt?.title}
          content={helpContent?.howtoReserveCourt?.content}
        />

        <Payment
          title={helpContent?.payment?.title}
          content={helpContent?.payment?.content}
        />

        <HowtoJointheMatch
          title={helpContent?.howtoJointheMatch?.title}
          content={helpContent?.howtoJointheMatch?.content}
        />
        <HowtoJointhePrivateMatch
          title={helpContent?.howtoJointhePrivateMatch?.title}
          content={helpContent?.howtoJointhePrivateMatch?.content}
        />
        <HowtoJointheTournament
          title={helpContent?.howtoJointheTournament?.title}
          content={helpContent?.howtoJointheTournament?.content}
        />
        <HowtoBookCoachingSession
          title={helpContent?.howtoBookCoachingSession?.title}
          content={helpContent?.howtoBookCoachingSession?.content}
        />
        <MyBookings
          title={helpContent?.myBookings?.title}
          content={helpContent?.myBookings?.content}
        />
      </Container>
    </>
  );
};

export default Help;
