import { createSlice } from "@reduxjs/toolkit";

const tournamentsSlice = createSlice({
  name: "tournaments",
  initialState: { data: [], loading: false, error: null },
  reducers: {
    tournamentsRequested: (state) => {
      state.loading = true;
      state.error = null;
    },
    tournamentsReceived: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    tournamentsRequestFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  tournamentsRequested,
  tournamentsReceived,
  tournamentsRequestFailed,
} = tournamentsSlice.actions;

export default tournamentsSlice.reducer;
