import React, { useState, useEffect } from "react";
import {
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Select,
  FormControl,
  Divider,
  Button,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { SelectChangeEvent } from "@mui/material/Select";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { brownButtonStyle } from "../../../../shared/uiConst/uniconst";
import useFetchClubsLean from "../../hooks/useFetchClubsLean";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/es"; // Import the Spanish locale
import "dayjs/locale/fr"; // Import the French locale
import "dayjs/locale/en"; // Import the English locale

dayjs.extend(utc);

const ButtonWrapper = styled("div")`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${(props: any) => props.theme.spacing(2)};
`;

const ModalFeedFilter: React.FC<{
  onApply: (
    selectedClub: string,
    selectedFromDate: Date | null,
    selectedToDate: Date | null
  ) => void;
  selectedClub: string;
  selectedFromDate: Date | null;
  selectedToDate: Date | null;
  onSelectedClubChange: (club: string) => void;
  onSelectedFromDateChange: (date: Date | null) => void;
  onSelectedToDateChange: (date: Date | null) => void;
}> = ({
  onApply,
  onSelectedClubChange,
  onSelectedFromDateChange,
  onSelectedToDateChange,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [clubName, setClubName] = useState([]);
  const [clubId, setClubId] = useState<number[]>([]);
  const [clubNames, setClubNames] = useState<string[]>([]);
  const [selectedFromDateState, setSelectedFromDateState] =
    useState<Date | null>(null);
  const [selectedToDateState, setSelectedToDateState] = useState<Date | null>(
    null
  );
  const [datePickerFromOpen, setDatePickerFromOpen] = useState(false);
  const [datePickerToOpen, setDatePickerToOpen] = useState(false);
  const { t } = useTranslation();

  const { data: clubsList, isLoading } = useFetchClubsLean();

  useEffect(() => {
    setClubName(clubsList);
  }, [clubsList]);

  const handleClubsDropdownChange = (
    event: SelectChangeEvent<typeof clubNames>
  ) => {
    const selectedClubId = event?.target?.value as string[];

    const clubIdFetched = clubName
      ?.filter((club: any) => selectedClubId?.includes(club.id))
      .map((club: any) => club.id);

    const localClubNames =
      typeof event.target.value === "string"
        ? event.target.value.split(",")
        : event.target.value;

    setClubId(clubIdFetched);
    setClubNames(localClubNames);
  };

  const handleDateChange = (date: Dayjs | null, dateType: any) => {
    const dateAsDate = date ? date.toDate() : null;
    if (dateType === "fromDate") {
      setSelectedFromDateState(dateAsDate);
      onSelectedFromDateChange(dateAsDate);
    } else {
      setSelectedToDateState(dateAsDate);
      onSelectedToDateChange(dateAsDate);
    }
  };

  const handleReset = () => {
    setClubId([]);
    setClubNames([]);
    setSelectedFromDateState(null);
    setSelectedToDateState(null);
    onSelectedClubChange?.("");
    onSelectedFromDateChange?.(null);
    onSelectedToDateChange?.(null);
  };

  const handleApply = () => {
    onApply?.(clubId.join(","), selectedFromDateState, selectedToDateState);
    handleClose();
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        style={{ marginRight: "230px", marginTop: "-50px", cursor: "pointer" }}
      >
        <FilterAltIcon />
        <Typography>{t("filter")}</Typography>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem>
          <Typography variant="h6" style={{ flex: 1 }}>
            {t("filterBy")}
          </Typography>
          <IconButton
            onClick={handleClose}
            style={{ justifyContent: "flex-end" }}
          >
            <CloseIcon />
          </IconButton>
        </MenuItem>
        <Divider />
        <MenuItem>
          <FormControl fullWidth>
            <Typography
              variant="body2"
              gutterBottom
              style={{ marginTop: "35px" }}
            >
              {t("club")}
            </Typography>
            <Select
              name="clubs"
              value={clubNames}
              onChange={handleClubsDropdownChange}
              style={{ marginTop: "15px" }}
              multiple
            >
              {isLoading ? (
                <MenuItem value="Loading">{t("loading")}</MenuItem>
              ) : Array.isArray(clubName) ? (
                clubName
                  ?.sort((a: any, b: any) => {
                    return a?.name?.localeCompare(b?.name);
                  })
                  .map((club: any) => (
                    <MenuItem key={club?.id} value={club?.id}>
                      <Checkbox checked={clubNames?.includes(club?.id)} />
                      {club?.name || t("noName")}
                    </MenuItem>
                  ))
              ) : (
                <MenuItem value="No Data">{t("noData")}</MenuItem>
              )}
            </Select>
          </FormControl>
        </MenuItem>
        <MenuItem>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={localStorage.getItem("language") || "en"}
          >
            <FormControl fullWidth style={{ marginTop: "35px" }}>
              <DatePicker
                label={t("selectFromDate")}
                onChange={(e: any) => handleDateChange(e, "fromDate")}
                value={
                  selectedFromDateState
                    ? dayjs.utc(selectedFromDateState)
                    : null
                }
                format="DD/MM/YYYY"
                timezone="UTC"
                open={datePickerFromOpen}
                onOpen={() => setDatePickerFromOpen(true)}
                onClose={() => setDatePickerFromOpen(false)}
                slotProps={{
                  textField: {
                    onClick: () => setDatePickerFromOpen(true),
                  },
                }}
              />
            </FormControl>
          </LocalizationProvider>
        </MenuItem>
        <MenuItem>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={localStorage.getItem("language") || "en"}
          >
            <FormControl fullWidth style={{ marginTop: "35px" }}>
              <DatePicker
                label={t("selectToDate")}
                onChange={(e: any) => handleDateChange(e, "toDate")}
                value={
                  selectedToDateState ? dayjs.utc(selectedToDateState) : null
                }
                format="DD/MM/YYYY"
                timezone="UTC"
                open={datePickerToOpen}
                onOpen={() => setDatePickerToOpen(true)}
                onClose={() => setDatePickerToOpen(false)}
                slotProps={{
                  textField: {
                    onClick: () => setDatePickerToOpen(true),
                  },
                }}
              />
            </FormControl>
          </LocalizationProvider>
        </MenuItem>
        <MenuItem>
          <Button onClick={handleReset}>{t("resetFilters")}</Button>
          <ButtonWrapper>
            <Button
              variant="contained"
              color="primary"
              onClick={handleApply}
              style={brownButtonStyle}
            >
              {t("applyFilters")}
            </Button>
          </ButtonWrapper>
        </MenuItem>
      </Menu>
    </>
  );
};

export default ModalFeedFilter;
