import { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./style.css";

//import useAuth from 'pages/Auth/context/Authentication';

export interface EditorProps {
  id?: string;
  initialContent?: any;
  initialAttachments?: any;
  placeholder?: string;
  heightInPx?: string;
  handleReturn?: any;
  isExternalAttachment?: boolean;
  allowImageUpload?: boolean;
  keyPressOption?: boolean;
  mode?: string;
  onAttachmentsChanged?: (attachment: any) => void;
  onContentChange?: (content: any, plainText: any) => void;
}

const CustomToolbar = ({ id, isExternalAttachment, allowImageUpload }: any) => (
  <div id={id}>
    <button className="ql-bold" />
    <button className="ql-italic" />
    <button className="ql-underline" />
    <button className="ql-strike" />
    <button className="ql-list" value="ordered" />
    <button className="ql-list" value="bullet" />
    <select className="ql-color" />
    <select className="ql-background" />
    <span className="ql-formats">
      <button className="ql-align" value=""></button>
      <button className="ql-align" value="center"></button>
      <button className="ql-align" value="right"></button>
      <button className="ql-align" value="justify"></button>
    </span>
    {allowImageUpload && <button className="ql-image" />}
  </div>
);

const Editor = (props: EditorProps) => {
  const {
    id,
    heightInPx,
    placeholder,
    handleReturn,
    initialContent,
    onContentChange,
    allowImageUpload,
    isExternalAttachment,
    keyPressOption,
  } = props;

  const [editorHtml, setEditorHtml] = useState<any>("");

  useEffect(() => {
    setEditorHtml(initialContent.value || initialContent);
  }, [initialContent]);

  const handleChange = (content: any, delta: any, source: any, editor: any) => {
    if (editor && content) {
      setEditorHtml(content);
      const text: any = editor?.getText(content);
      if (text && typeof onContentChange === "function") {
        onContentChange(content, text);
      }
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    // Check if Enter key is pressed and if it's not combined with other keys like Ctrl or Shift
    if (
      event.key === "Enter" &&
      !event.shiftKey &&
      !event.ctrlKey &&
      !event.altKey
    ) {
      // Prevent default behavior of the Enter key
      event.preventDefault();
      // Perform your custom action or call handleReturn function if provided
      if (handleReturn) {
        handleReturn();
        setEditorHtml("");
      }
    }
  };

  const modules = {
    toolbar: {
      container: `#${id}`,
    },
  };

  const formats = [
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "color",
    "background",
    "link",
    "image",
    "align",
  ];
  return (
    <div className="text-editor">
      {/* Classlist issue in QuillEditor Component , Don't remove the condition*/}
      {id === "video-call-chat" && editorHtml ? (
        <ReactQuill
          value={editorHtml}
          onChange={handleChange}
          placeholder={placeholder}
          modules={modules}
          formats={formats}
          style={{
            height: `${heightInPx}`,
          }}
          {...(keyPressOption && { onKeyDown: handleKeyPress })}
        />
      ) : id !== "video-call-chat" ? (
        <ReactQuill
          value={editorHtml}
          onChange={handleChange}
          placeholder={placeholder}
          modules={modules}
          formats={formats}
          style={{
            height: `${heightInPx}`,
          }}
          {...(keyPressOption && { onKeyDown: handleKeyPress })}
        />
      ) : null}

      <CustomToolbar
        id={id}
        isExternalAttachment={isExternalAttachment}
        allowImageUpload={allowImageUpload}
      />
    </div>
  );
};

export const QuillEditor = ({
  id = "toolbar",
  initialContent = "",
  initialAttachments = [],
  placeholder = "",
  handleReturn,
  heightInPx = "150px",
  allowImageUpload = false,
  isExternalAttachment = false,
  onAttachmentsChanged = (files: any) => {},
  onContentChange = (e?: string, text?: any) => {},
  keyPressOption = false,
  mode = "edit",
}: EditorProps) =>
  mode === "edit" ? (
    <div className="quillEditor">
      <Editor
        id={id}
        heightInPx={heightInPx}
        placeholder={placeholder}
        initialContent={initialContent}
        handleReturn={handleReturn}
        onContentChange={onContentChange}
        allowImageUpload={allowImageUpload}
        isExternalAttachment={isExternalAttachment}
        onAttachmentsChanged={onAttachmentsChanged}
        keyPressOption={keyPressOption}
      />
    </div>
  ) : (
    <>
      <ReactQuill
        value={initialContent?.value || initialContent || ""}
        readOnly={true}
        modules={{ toolbar: false }}
        className="quill-view"
      />
    </>
  );
