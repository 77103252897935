import React from 'react'
import * as XLSX from 'xlsx';

const exportExcel = (sheetName: string | undefined, data: any[]) => {
    const wb = XLSX.utils.book_new(); // Create a new workbook
    const ws = XLSX.utils.aoa_to_sheet(data); // Convert data to a worksheet

    // Calculate column widths based on the maximum length of content
    const colWidths = data[0].map((_: any, colIndex: string | number) => {
        const columnData = data.map((row) => row[colIndex] ?? ''); // Default to empty string
        const maxLength = Math.max(
            ...columnData.map((data) => (typeof data === 'string' ? data.length : data.toString().length)),
            data[0].length // Include header length
        );
        return { wpx: maxLength * 7 }; // Set width in pixels based on character count (adjust multiplier)
    });

    ws['!cols'] = colWidths; // Set the column widths

    XLSX.utils.book_append_sheet(wb, ws, sheetName); // Append the sheet to the workbook

    return wb; // Return the workbook
};

export default exportExcel