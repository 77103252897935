// src/redux/coachingReducer.ts
import { createSlice } from "@reduxjs/toolkit";

const couponsSlice = createSlice({
  name: "coupons",
  initialState: { data: [], loading: false, error: null },
  reducers: {
    couponsRequested: (state) => {
      state.loading = true;
      state.error = null;
    },
    couponsReceived: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    couponsRequestFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateCouponData: (state: any, action) => {
      const updatedData = state?.data?.data?.map((x: any) =>
        x?._id === action?.payload?._id
          ? { ...x, ...action.payload, updated: true }
          : x
      );

      return {
        ...state,
        loading: false,
        data: { ...state?.data, data: updatedData },
      };
    },
    singleCouponRequested: (state) => {
      state.loading = true;
      state.error = null;
    },
    singleCouponReceived: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    singleCouponRequestFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    couponsReset: (state) => {
      state.loading = false;
      state.data = [];
    },
  },
});

export const {
  couponsRequested,
  couponsReceived,
  couponsRequestFailed,
  singleCouponRequested,
  singleCouponReceived,
  singleCouponRequestFailed,
  updateCouponData,
  couponsReset,
} = couponsSlice.actions;

export default couponsSlice.reducer;
