import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
  useMemo,
} from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "app/redux/store";
import { USER_ROLE } from "app/config/constants";
import {
  logout,
  handelLogin,
  isTokenExpired,
  decodeToken,
} from "./app/shared/components/authentication";
import {
  getSingleConfiguration,
  getProfile,
} from "app/entities/Configurations/configurationsApiService";

interface AuthContextProps {
  children: ReactNode;
}

interface AuthContextType {
  isAuthenticated: boolean;
  login: (
    username: string,
    password: string,
    userType: string,
    redirectUrl?: string
  ) => Promise<void>;
  logout: () => void;
  setIsAuthenticated?: any;
  setLoggedUserData?: any;
  user?: any;
  profileData?: any;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<AuthContextProps> = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<any>();
  const [profileData, setProfileData] = useState<any>();

  const urlCheck = [
    "/signIn",
    "/forgotPassword",
    "/changePassword",
    "/verifyOTP",
    "/successChangePassword",
    "/app/",
    "/coach/signIn",
    "/coach/forgotPassword",
    "/coach/changePassword",
  ];

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      let decodedTokenObj = decodeToken(token);
      setUser({
        ...decodedTokenObj,
        isCoachPortal: decodedTokenObj?.userType === USER_ROLE.COACH,
      });
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    const currentPath = window.location.pathname;

    if (
      !token &&
      !(urlCheck.includes(currentPath) || currentPath.includes("/app/"))
    ) {
      // Only redirect if there is no token and the user is not already on the sign-in page
      //window.location.href = "/signIn"; // Redirect to sign-in page if token is not present
    }
  }, [urlCheck]);

  useEffect(() => {
    if (
      isAuthenticated
      //localStorage.getItem("userType") !== USER_ROLE.COACH
    ) {
      (async () => {
        const data = await dispatch(getSingleConfiguration()); // Call the data fetching function
        localStorage.setItem("settings", JSON.stringify(data));
      })();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token && localStorage.getItem("userType")) {
      //if (token && localStorage.getItem("userType")) {
      let userType: any = localStorage.getItem("userType");
      (async () => {
        const data = await dispatch(getProfile(userType)); // Call the data fetching function
        localStorage.setItem("profileData", JSON.stringify(data));
        setProfileData(data);
      })();
    }
  }, [isAuthenticated]);

  const loginHandler = async (
    username: string,
    password: string,
    userType: string,
    redirectUrl?: string
  ) => {
    try {
      const tokenFromLogin = await handelLogin(username, password, userType);
      if (tokenFromLogin) {
        setIsAuthenticated(true);
        //localStorage.setItem("username", username);
        if (isTokenExpired(tokenFromLogin)) {
          navigate("/signIn");
        } else {
          const decodedToken = decodeToken(tokenFromLogin);
          localStorage.setItem("userType", decodedToken?.userType);
          setUser(decodedToken);

          // Redirect to the specified redirectUrl if provided, otherwise redirect to "/"
          const targetUrl =
            redirectUrl ||
            decodedToken?.userType === "super_admin" ||
            decodedToken?.userType === "club_admin"
              ? "/court-bookings"
              : "/";

          navigate(targetUrl);
        }
      }
      // else {
      //   window.location.href = "/signIn";
      // }
    } catch (error) {
      // Handle authentication error
      console.error("Authentication failed", error);
    }
  };

  const logoutHandler = () => {
    // Store the current URL in localStorage before logging out
    localStorage.setItem("logoutRedirectUrl", window.location.pathname);
    logout();
    // setIsAuthenticated(false);
  };

  // Memoize the context value using useMemo
  const contextValue = useMemo(
    () => ({
      isAuthenticated,
      setIsAuthenticated,
      user,
      profileData,
      setLoggedUserData: setUser,
      login: loginHandler,
      logout: logoutHandler,
    }),
    [isAuthenticated, user, profileData]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
