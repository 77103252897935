export const TermsConditionsFR = {
  generalInfo: {
    title: "CONDITIONS GENERALES D’UTILISATION",
    content: `L’application « RESAPADEL » (ci-après « <strong>l’Application</strong> ») est éditée par <strong>LRD</strong>, société par
              actions simplifiée au capital social de 229.966 euros, dont le siège social est situé 24 quai du
              4 septembre – 92100 Boulogne Billancourt, immatriculée au registre du commerce et des
              sociétés de NANTERRE sous le numéro 820 434 561 (ci-après la « <strong>Société » ou
              « RESAPADEL</strong> »).<br /><br />

              La Société exploite directement ou indirectement des centres de padel sous l’enseigne
              « CASA PADEL » en France métropolitaine (ci-après les « <strong>Centres Casa Padel</strong> »). Les
              Centres Casa Padel proposent la location de terrains de padel et la mise à disposition de
              services associés tels que la vente de petits matériels, la mise à disposition de prestations
              de coaching, etc.<br /><br />

              L’Application constitue un outil de réservation de prestations de services au sein des Centres
              Casa Padel.<br /><br />
              
              Les présentes conditions générales d’utilisation précisent les conditions et modalités d’accès
              et d’utilisation de l’Application (ci-après les « <strong>CGU</strong> ») et s’appliquent à tout utilisateur de
              l’Application (ci-après désigné par l’ « <strong>Utilisateur</strong> »)..
`,
  },
  acceptanceAndModification: {
    title: "Article 1. &nbsp;&nbsp;&nbsp;&nbsp; Acceptation et modification des CGU",
    content: `Tout accès et utilisation de l’Application signifie que l’Utilisateur a lu et accepté sans
              restriction ni réserve les CGU.<br /> <br />

              Les CGU applicables à l’Utilisateur sont celles en vigueur à la date de création du compte
              par l’Utilisateur. Nonobstant, RESAPADEL se réserve le droit de modifier à tout moment, à
              son entière discrétion, tout ou partie des CGU. Les Utilisateurs seront informés de toute
              modification avant qu&#39;elles n&#39;entrent en vigueur par l’intermédiaire de leur compte sur
              l’Application et/ou par courriel.<br /> <br />

              Si l’Utilisateur n’accepte pas la mise à jour des CGU par RESAPADEL, il devra cesser
              d’utiliser l’Application et procéder à la suppression de son compte, le cas échéant.<br /> <br />

              <strong>L’Utilisateur s’engage lors de chacune de ses visites de l’Application à respecter
              l’ensemble des CGU sans aucune réserve. En conséquence, l’Utilisateur reconnait
              avoir pris connaissance des CGU et accepte d’être lié par ses dispositions.</strong><br /> <br />

              Si l’Utilisateur accède à l’Application pour le compte d’une entreprise ou de toute autre entité
              juridique, il est néanmoins personnellement lié par les CGU.
`,
  },
  accountcreation: {
    title: "Article 2. &nbsp;&nbsp;&nbsp;&nbsp; Création de compte",
    content: `Pour accéder aux Services, l’Utilisateur doit créer un compte sur l’Application qui lui
              permettra de :<br />
               <ul>
                  <li style="margin-bottom : .3rem">
                    consulter et mettre à jour ses coordonnées et moyens de paiement ;
                  </li>
                  <li style="margin-bottom : .3rem">
                    suivre ses réservations en cours et consulter l’historique de ses dernières
                    réservations sur les six derniers mois ;
                  </li>
                  <li style="margin-bottom : .3rem">
                    gérer ses paramètres de confidentialité au regard du chat et de la possibilité d’être
                    convié à des matchs compétitifs.
                  </li>
                </ul>
                <br />

              Pour créer son compte, l’Utilisateur peut :<br />
                <ul style="list-style-type: '-  ';">
                  <li style="margin-bottom : .3rem">
                    soit renseigner son numéro de téléphone mobile et choisir un mot de passe associé;
                  </li>
                  <li style="margin-bottom : .3rem">
                    soit insérer une adresse électronique assortie d&#39;un mot de passe ;
                  </li>
                  <li style="margin-bottom : .3rem">
                    soit se connecter par l’intermédiaire de son compte Google, Facebook, Apple, ou
                    LinkedIn.
                  </li>
                </ul>
                <br />

              En tout état de cause, l’Utilisateur, s’il souhaite accéder aux Services, devra renseigner un
              numéro de téléphone valide, celui-ci étant soumis à une validation par SMS via le processus
              OTP (One-Time-Password) lors de chaque connexion à l’Application.<br /> <br />

              L’Utilisateur est seul responsable de tout usage qui pourrait être fait de ses identifiants et/ou
              mot de passe, et seul garant de leur confidentialité, ainsi que de toute utilisation de son
              compte. Chaque Utilisateur s’engage à ne créer qu’un seul compte correspondant à son
              profil et associé à un numéro de téléphone ou une adresse email unique. Il est formellement
              interdit de créer des profils fictifs ou de multiplier les profils.<br /> <br />

              L’Utilisateur accepte et garantit fournir des informations personnelles exactes, véridiques,
              complètes et à jour et s’engage à les tenir à jour afin d’en garantir l’exactitude pendant la
              durée de sa relation contractuelle avec RESAPADEL.<br /> <br />
              
              RESAPADEL se réserve le droit de refuser l’accès à tout ou partie des Services ou de
              l’Application dans le cas où l’Utilisateur fournirait des informations fausses, incomplètes ou
              erronées.<br /> <br />

              Une fois son compte créé et son profil complété, l’Utilisateur peut souscrire aux Services
              proposés sur l’Application selon les modalités prévues dans les Conditions Générales de
              Vente.<br /> <br />

              Pour supprimer son compte client, le Client est invité à contacter le service clients de
              RESAPADEL conformément aux dispositions « Vos droits d&#39;accès, de modification et de
              suppression » dans l&#39;onglet &quot;Politique de Confidentialité&quot; de l’Application.`,
  },
  conditionsOfAccessToTheApplication: {
    title: "Article 3. &nbsp;&nbsp;&nbsp;&nbsp; Conditions d’accès à l’Application et aux Services",
    content: `<strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 3.1 &nbsp;&nbsp;&nbsp;&nbsp; Accès à l’Application</strong><br /><br />

              La création d’un compte d’Utilisateur sur l’Application et l’accès aux Services sont
              exclusivement réservés aux personnes physiques majeures, disposant de la capacité
              juridique.<br /><br />

              RESAPADEL se réserve le droit de procéder à la fermeture des comptes ouverts par des
              personnes ne répondant pas aux conditions ci-dessus, sans préjudice de toutes autres
              actions.<br /><br />

              <strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 3.2 &nbsp;&nbsp;&nbsp;&nbsp; Configuration technique de l’appareil de l’Utilisateur</strong><br /><br />
              
              L’utilisation de l’Application nécessite une configuration technique minimale, à savoir :<br />
                <ul style="list-style-type: '-  ';">
                  <li style="margin-bottom : .3rem">
                    Un smartphone ou une tablette disposant d’un système d’exploitation iOS (version
                    12.4 ou supérieure) ou Android (version 8 (oreo) ou supérieure) ;
                  </li>
                  <li style="margin-bottom : .3rem">
                    L&#39;application doit être installée via l&#39;App Store (pour iOS) ou le Google Play Store
                    (pour Android) ;
                  </li>
                  <li style="margin-bottom : .3rem">
                    Un accès à Internet via Wi-Fi ou un réseau mobile haut débit.
                  </li>
                </ul>
                <br />
                Il appartient à l’Utilisateur de disposer d’un accès web et de s’assurer de la compatibilité de
                son environnement informatique avec l’Application, sous sa propre responsabilité, de
                souscrire tout accès à Internet auprès de l’opérateur de son choix, et d’acquérir tout matériel
                répondant aux exigences de configuration.`,
  },
  intellectualProperty: {
    title: "Article 4. &nbsp;&nbsp;&nbsp;&nbsp; Propriété intellectuelle",
    content: `<strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 4.1 &nbsp;&nbsp;&nbsp;&nbsp; Contenu propriétaire de RESAPADEL</strong><br /><br />

              Sous réserve des droits de ses partenaires, l’Application et/ou son contenu (incluant, de
              façon non limitative, sa structure générale, les images, photographies, textes, vidéos, logos,
              graphismes et icônes composant l’Application), sont la propriété exclusive de la Société ou
              de tiers ayant autorisé la Société à les exploiter, et sont protégés par les lois françaises et
              internationales relatives à la propriété intellectuelle. Ce droit de propriété inclut les bases de
              données présentes sur l’Application.<br /><br />

              L’utilisation de l’Application et/ou des Services ne confère aucun droit à l’Utilisateur sur
              l’Application et/ou les éléments s’y trouvant.<br /><br />

              Du seul fait de sa connexion à l’Application, l’Utilisateur reconnaît accepter une licence
              temporaire d’usage du contenu de l’Application strictement limitée aux conditions impératives
              suivantes :<br />

              <ul style="list-style-type: '-  ';">
                  <li style="margin-bottom : .3rem">
                    La présente licence est accordée à titre non exclusif et n’est pas transmissible ;
                  </li>
                  <li style="margin-bottom : .3rem">
                    Le droit d’usage conféré à l’Utilisateur est personnel et privé ;
                  </li>
                  <li style="margin-bottom : .3rem">
                    Le droit d’usage comprend seulement l’autorisation de consulter l’Application et son
                    contenu.
                  </li>
                </ul>
                <br />

              Les éléments de l’Application, pris dans leur ensemble ou individuellement, ne peuvent faire
              l’objet d’aucune utilisation, représentation, transformation, traduction, adaptation,
              reproduction et/ou exploitation commerciale ou non, de façon totale ou partielle, par quelque
              moyen ou quelque forme que ce soit, y compris par des techniques de webscraping, sans
              l’autorisation expresse et préalable de RESAPADEL, sous peine de poursuites.<br /><br />

              Tout Utilisateur s’interdit de procéder à toute extraction du contenu de l’Application pour une
              activité similaire ou concurrente, ou à des fins de recrutement.<br /><br />

              <strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 4.2 &nbsp;&nbsp;&nbsp;&nbsp; Licence du contenu de l’Utilisateur</strong><br /><br />
              
              L&#39;Utilisateur accorde à RESAPADEL, de manière non exclusive, gratuite et mondiale, une
              licence sur l&#39;ensemble des contenus qu&#39;il publie ou transmet au sein de l&#39;Application,
              notamment mais sans s&#39;y limiter, via les discussions (chats) et son profil (ci-après les
              « <strong>Contenus Utilisateur</strong> »).<br /><br />

              Cette licence, nécessaire du fait des fonctionnalités ouvertes aux Utilisateurs de
              l’Application, vaudra pour toute la durée de protection légale des droits de propriété
              intellectuelle attachés aux Contenus Utilisateur :<br />
              
                <ul>
                  <li style="margin-bottom : .3rem">
                    Le droit de reproduire, représenter, adapter, modifier, diffuser, publier et communiquer
                    les Contenus Utilisateur, sur tout support, existant ou futur, dans le cadre de
                    l&#39;exploitation paisible de l&#39;Application ;
                  </li>
                  <li style="margin-bottom : .3rem">
                    Le droit d&#39;utiliser les Contenus Utilisateur à des fins d&#39;amélioration des services
                    proposés par l&#39;Application ;
                  </li>
                  <li style="margin-bottom : .3rem">
                    Le droit de sous-licencier ces droits à toute entité affiliée à l&#39;Application, dans le cadre
                    de l’exploitation paisible de l’Application.
                  </li>
                </ul>
                <br />

              L&#39;Utilisateur garantit que les Contenus Utilisateur publiés ou transmis sur l&#39;Application
              respectent les droits de tiers, notamment en matière de propriété intellectuelle, et qu&#39;il
              dispose des droits nécessaires pour accorder la présente licence.<br /><br />

              L&#39;Utilisateur conserve le droit de modifier ou de supprimer ses Contenus Utilisateur via les
              interfaces de l&#39;Application. Toutefois, toute utilisation déjà faite des Contenus Utilisateur par
              RESAPADEL ou les autres Utilisateurs de l’Application avant leur suppression ou
              modification reste valide et ne pourra être contestée.<br /><br />

              La présente licence prendra fin automatiquement en cas de suppression du compte de
              l&#39;Utilisateur, sous réserve des droits d&#39;exploitation antérieurement accordés à l&#39;Application.`,
  },
  personalDataAndRespect: {
    title: "Article 5. &nbsp;&nbsp;&nbsp;&nbsp; Données personnelles et respect de la vie privée ",
    content: `L’Utilisateur a pris connaissance de la Politique de confidentialité de RESAPADEL.<br /><br />

              RESAPADEL s’engage à respecter les dispositions du Règlement UE 2016/679 sur la
              protection des données personnelles, et la loi du 6 janvier 1978 modifiée relative à
              l’informatique, aux fichiers et aux libertés, conformément à ce qui est indiqué dans sa
              Politique de confidentialité. RESAPADEL collecte et traite les données personnelles de
              l’Utilisateur aux fins de fournir l’Application et les Services dans les conditions prévues et
              détaillées à la Politique de Confidentialité.`,
  },
  availabilityOfTheApplication: {
    title: "Article 6. &nbsp;&nbsp;&nbsp;&nbsp; Disponibilité de l’Application",
    content: `RESAPADEL met en œuvre les moyens nécessaires pour assurer l’accès et le
              fonctionnement de l’Application 24 heures sur 24, 7 jours sur 7, pour l’ensemble des
              Utilisateurs.<br /><br />

              RESAPADEL fait également ses meilleurs efforts, conformément aux règles de l’art, pour
              fournir et sécuriser l’Application eu égard à la complexité de l’Internet. Elle ne saurait assurer
              une accessibilité ou une disponibilité absolue et permanente de l’Application.<br /><br />

              Ainsi, l’accès et le fonctionnement de l’Application et des Services sont susceptibles d’être
              interrompus en cas de force majeure, de mauvais fonctionnement des équipements de
              l’Utilisateur, de dysfonctionnements du réseau Internet de l’Utilisateur, d’opération de
              maintenance destinées à améliorer l’Application, sans que cette liste ne soit exhaustive.<br /><br />

              RESAPADEL ne saurait être tenue responsable d’une interruption de l’accès à l’Application,
              volontaire ou non, étant précisé qu’elle s’engage à faire ses meilleurs efforts pour limiter les
              interruptions qui lui seraient imputables.<br /><br />

              Par ailleurs, RESAPADEL se réserve le droit de fermer temporairement l’Application pour
              procéder à sa maintenance ou aux mises à jour, modifications ou améliorations qu’elle juge
              nécessaires et utiles, et/ou nécessitées par les évolutions techniques ou réglementaires, et
              ce sans préavis, ni indemnité.<br /><br />

              L’Utilisateur déclare accepter les caractéristiques, limites et risques associés à 
              l’utilisation de l’Internet. Il reconnaît avoir connaissance de la nature du réseau de 
              l’Internet, et en particulier, de ses performances techniques et des temps de réponse 
              pour consulter, interroger ou transférer les données d’informations.<br /><br />

              L’Utilisateur se doit d’informer RESAPADEL de toute défaillance de l’Application dès qu’il en
              a connaissance via une notification devant être adressée à RESAPADEL par email à
              <a href="mailto:contact@casapadel.fr">contact@casapadel.fr</a>.<br /><br />

              L&#39;Utilisateur reconnaît avoir été pleinement informé et accepte que l&#39;Application puisse faire
              l&#39;objet de mises à jour correctives ou d&#39;améliorations, à l&#39;initiative de RESAPADEL.`,
  },
  responsibility: {
    title: "Article 7. &nbsp;&nbsp;&nbsp;&nbsp; Responsabilité",
    content: `<strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 7.1 &nbsp;&nbsp;&nbsp;&nbsp; Responsabilité de l’Utilisateur</strong><br /><br />
              Chaque Utilisateur s&#39;engage à utiliser l’Application de bonne foi et à ne pas faire un usage de
              l’Application qui serait contraire aux lois ou aux règlements, ou aux CGU, sous peine
              d&#39;engager sa responsabilité.<br /><br />

              L’Utilisateur est seul responsable du préjudice direct ou indirect qu’il est susceptible de subir
              du fait d’informations inexactes, incomplètes, et/ ou trompeuses qu’il fournirait lors de son
              inscription ou en l’absence de mise à jour de ces informations, ce dont il assume seul les
              conséquences.<br /><br />

              Tout Utilisateur s&#39;interdit tout usage de l’Application qui serait susceptible de porter atteinte
              aux droits de RESAPADEL ou qui serait susceptible de causer un dommage à RESAPADEL
              et à ses partenaires.<br /><br />

              Tout Utilisateur s&#39;interdit tout usage de l’Application qui serait de nature à porter atteinte à
              l&#39;intégrité de l&#39;infrastructure technique de RESAPADEL, notamment ses serveurs
              informatiques.<br /><br />

              L’Utilisateur est seul responsable des informations qu’il renseigne ou communique via
              l’Application.<br /><br />

              La Société se réserve le droit de restreindre, suspendre ou supprimer, sans préavis ni
              remboursement, le compte de tout Utilisateur qui utilise de façon abusive ou inappropriée
              l’Application. L’appréciation du comportement de l’Utilisateur est réservée à l’entière
              discrétion de RESAPADEL.<br /><br />

              Cela inclut notamment le cas où l&#39;Utilisateur diffuserait des données, informations ou
              contenus à caractère diffamatoire, injurieux, obscène, offensant, violent ou incitant à la
              violence, ou encore des contenus de nature politique, raciste ou xénophobe, et, de manière
              générale, tout contenu qui serait contraire aux lois et règlements en vigueur ou aux bonnes
              mœurs.<br /><br />
              
              Il est notamment interdit de tenter d’obtenir d’autres Utilisateurs des mots de passe ou des
              données personnelles d’identification, ou de proposer un service que RESAPADEL
              considère, à son entière discrétion, comme concurrentiel à ses Services par l’intermédiaire
              de l’Application. Par ailleurs, la vente, l’échange ou la monétisation d&#39;informations, de
              contenus ou de données présents sur l’Application sans l&#39;accord exprès et écrit de
              RESAPADEL, sont également interdits.<br /><br />

              <strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 7.2 &nbsp;&nbsp;&nbsp;&nbsp; Responsabilité de RESAPADEL</strong><br /><br />
              <strong> 7.2.1 &nbsp;&nbsp;&nbsp;&nbsp; Informations diffusées sur l’Application</strong><br /><br />

              Malgré le soin apporté au traitement des informations, RESAPADEL décline toute
              responsabilité concernant les erreurs ou omissions portant sur les informations diffusées sur
              l’Application. RESAPADEL ne peut être tenue responsable de l’interprétation des
              informations contenues sur l’Application, ni des conséquences de leur utilisation, ni des
              dommages de toute nature, directs ou indirects résultant de l’utilisation de l’Application.<br /><br />

              <strong>7.2.1 &nbsp;&nbsp;&nbsp;&nbsp; Liens hypertextes</strong><br /><br />

              L’Application peut contenir des liens hypertextes renvoyant l’Utilisateur vers des sites
              extérieurs (par exemple sites partenaires). N’exerçant aucun contrôle sur les contenus vers
              lesquels ces liens hypertextes redirigent, RESAPADEL ne peut voir sa responsabilité
              engagée du seul fait de la reproduction de ces liens sur l’Application, sauf à démontrer un
              comportement fautif de sa part. Lorsqu’il accède à ces sites extérieurs, l’Utilisateur est seul
              responsable de l’utilisation qu’il en fait, il lui appartient donc de consulter les mentions
              légales de ces sites extérieurs et de respecter leurs éventuelles conditions d’utilisation.<br /><br />

              En sa qualité d’hébergeur, RESAPADEL ne peut voir sa responsabilité engagée du seul fait
              de la publication de liens hypertextes par les Utilisateurs (dans le cadre des discussions
              privées ou en groupe, par exemple), sauf à ce que leur contenu soit manifestement illicite.<br /><br />

              Si leur contenu n’est pas manifestement illicite, il est possible d’adresser à RESAPADEL une
              notification de l’illicéité de ce contenu en adressant un courrier électronique à l’adresse
              suivante : <a href="mailto:contact@casapadel.fr">contact@casapadel.fr</a>. Si l’illicéité de ce contenu est avérée, RESAPADEL
              s’engage à supprimer le lien hypertexte ou à le rendre inaccessible dans un prompt délai.`,
  },
  applicableLaw: {
    title: "Article 8. &nbsp;&nbsp;&nbsp;&nbsp; Droit applicable",
    content: `Les CGU sont soumises au droit français, quel que soit le pays d’origine de l’Utilisateur ou le
              pays depuis lequel l’Utilisateur accède à l’Application et nonobstant les principes de conflits
              de lois.<br /><br />

              Avant tout recours, l’Utilisateur doit tenter de soumettre ses griefs à RESAPADEL par écrit,
              en adressant un courriel à contact@casapadel.fr ou une lettre à l’adresse du siège de
              RESAPADEL, en vue d’une résolution amiable entre les parties.<br /><br />

              A défaut de réponse de RESAPADEL dans le mois suivant la date de réception par
              RESAPADEL de la réclamation de l’Utilisateur, ou si les parties n’arrivaient pas à se mettre
              d’accord sur un compromis ou une solution, l’Utilisateur peut alors :<br />

              <ul style="list-style-type: '-  ';">
                  <li style="margin-bottom : .3rem">
                    Contacter un médiateur à la consommation conformément aux indications des CGV ;
                  </li>
                  <li style="margin-bottom : .3rem">
                    Soumettre son litige à un juge étatique, la compétence juridictionnelle du Tribunal
                    compétent dans le ressort du domicile du défendeur.
                  </li>
              </ul>`,
  },
  // mentionsLegales: {
  //   title: "<div style='text-align: center'>MENTIONS LEGALES<div>",
  //   content: `<strong><u>Editeur</u></strong><br /><br />

  //             L’application RESAPADEL (ci-après « <strong>l’Application</strong> ») est éditée et diffusée par la société
  //             <strong>LRD « Groupe CASA PADEL</strong> », société par actions simplifiée au capital social de 229.966
  //             euros, dont le siège social est situé 24, quai du 4 septembre – 92100 Boulogne Billancourt,
  //             immatriculée au registre du commerce et des sociétés de NANTERRE sous le numéro 820
  //             434 561 – TVA intracommunautaire : FR87820434561 (ci-après la « <strong>Société</strong> » ou « <strong>CASA
  //             PADEL</strong> »).<br /><br />
              
  //             Directeur de la publication : Monsieur José Manuel ESCOIN CANO<br />
  //             Contact : <a href="mailto:contact@casapadel.fr">contact@casapadel.fr</a><br />
  //             N° de téléphone : 01 84 21 49 00<br /><br />

  //             <u>Hébergement</u> <br /><br />

  //             L’Application est hébergée par :<br />

  //             <ul style="list-style-type: '-  ';">
  //                 <li>
  //                   MongoDB :
  //                   <ul>
  //                     <li style="margin-bottom : .3rem">
  //                       Adresse : 20 Rue Quentin Bauchart, 2 e étage – 75008 Paris, France
  //                     </li>
  //                     <li style="margin-bottom : .3rem">
  //                       Téléphone : +33 182 881 666
  //                     </li>
  //                     <li style="margin-bottom : .3rem">
  //                       TVA intracommunautaire : FR50794107326
  //                     </li>
  //                   </ul>
  //               </li>
  //                 <li style="margin-bottom : .3rem">
  //                   Amazon Web Services EMEA SARL :
  //                   <ul>
  //                     <li style="margin-bottom : .3rem">
  //                       Adresse : 38 Avenue John F. Kennedy, L-1855, Luxembourg
  //                     </li>
  //                     <li style="margin-bottom : .3rem">
  //                       Contact : <a href="https://aws.amazon.com/fr/contact-us/compliance-support/" target="_blank">https://aws.amazon.com/fr/contact-us/compliance-support/</a>
  //                     </li>
  //                     <li style="margin-bottom : .3rem">
  //                       TVA intracommunautaire : LU26888617
  //                     </li>
  //                   </ul>
  //                 </li>
  //               </ul>
  //               <br />

              

  //             <u>Base de données</u> : <br /><br />

  //             L’Application RESAPADEL constitue notamment une base de données protégée au titre du
  //             droit sui generis des bases de données (loi n° 98-536 du 1 er juillet 1998).<br /><br />

  //             La reproduction, le transfert, le stockage, la rediffusion ou l&#39;extraction automatique ou manuel
  //             d’éléments de la base de données est interdite. L&#39;emploi de robots, programmes permettant
  //             l&#39;extraction directe de données est rigoureusement interdit.<br /><br />

  //             <u>Propriété Intellectuelle</u><br /><br />

  //             L’ensemble de l’Application relève de la législation française sur le droit d’auteur et la
  //             propriété intellectuelle. Tous les droits de reproduction sont réservés, y compris les
  //             représentations iconographiques et photographiques. La reproduction, adaptation et/ou
  //             traduction de tout ou partie de l’Application sur un support quel qu&#39;il soit, est formellement
  //             interdite sauf autorisation expresse du Directeur de la publication.<br /><br />

  //             Tout utilisateur de l’Application est tenu de se référer aux Conditions Générales d’Utilisation,
  //             Conditions Générales de Vente, et à la Politique de confidentialité de CASA PADEL.`,
  // },
  // confidentialityAndPersonalData: {
  //   title: "POLITIQUE DE CONFIDENTIALITE ET DE PROTECTION DES DONNÉES PERSONNELLES",
  //   content: `RESAPADEL est une application mobile (l’« <strong>Application</strong> ») exploitée par <strong>LRD « 
  //             Groupe CASA PADEL »</strong>, société par actions simplifiée au capital social de 229.966 
  //             euros, dont le siège social est situé 24 quai du 4 septembre – 92100 Boulogne 
  //             Billancourt, immatriculée au registre du commerce et des sociétés de NANTERRE 
  //             sous le numéro 820 434 561 – TVA intracommunautaire : FR87820434561 (ci-après 
  //             la « <strong>Société</strong> » ou « <strong>CASA PADEL</strong> », ou « <strong>nous</strong> »).<br /><br />

  //             Elle propose les Services mentionnés aux Conditions Générales d’Utilisation 
  //             (ci-après les « <strong>CGV</strong> »), par le biais de l’Application.<br /><br />

  //             L’Application a pour but de permettre à tout utilisateur de l’Application de réserver 
  //             des terrains de padel auprès de centres de padel à l’enseigne « CASA PADEL » 
  //             situés sur le territoire de France métropolitaine (ci-après les « <strong>Centres Casa 
  //             Padel</strong> ») afin d’effectuer des matchs privatifs, de participer à des matchs compétitifs 
  //             avec d’autres joueurs, de réserver des créneaux de coaching individuels avec un 
  //             coach de padel ou de participer à des tournois (ci-après les « <strong><mark>Services</mark></strong> »).<br /><br />

  //             Dans le cadre de l’exploitation de l’Application, CASA PADEL est amenée à collecter 
  //             et à traiter des informations dont certaines sont qualifiées de "données personnelles" 
  //             (ci-après les « <strong>Données Personnelles</strong> »).<br /><br />

  //             La présente Charte de Confidentialité a vocation à informer les personnes physiques 
  //             (ci-après « <strong>vous</strong> ») dont les Données Personnelles sont susceptibles d’être 
  //             collectées par RESAPADEL dans le cadre des Services sur les conditions dans 
  //             lesquelles lesdites Données Personnelles seront collectées, traitées et conservées.`,
  // },
  // whoIsTheController: {
  //   title: "QUI EST LE RESPONSABLE DU TRAITEMENT ?",
  //   content: `Les Données Personnelles directement ou indirectement collectées dans le cadre de 
  //             l’exploitation de l’Application sont traitées par le responsable de traitement, soit :<br /><br />
              
  //             <strong>LRD</strong>, société par actions simplifiée au capital social de 229.966 euros, dont le siège social est situé 24 quai du 4 septembre – 92100 Boulogne Billancourt, immatriculée au registre du commerce et des sociétés de NANTERRE sous le numéro 820 434 561 qui seule ou conjointement avec d’autres, détermine les finalités et les moyens du traitement.`,
  // },
  // howAreYourPersonal: {
  //   title: "COMMENT SONT COLLECTEES VOS DONNEES PERSONNELLES ?",
  //   content: `Nous sommes susceptibles de collecter via l’Application, des questionnaires, 
  //   applications, appareils, pages consacrées aux Services sur les réseaux sociaux ou 
  //   par tout autre moyen, des informations à caractère personnel susceptibles de 
  //   permettre de vous identifier en tant que personne physique.<br /><br />

  //   Dans certains cas, vous serez amené à nous communiquer directement des 
  //   Données Personnelles par exemple lorsque vous procédez à la création d’un compte 
  //   sur l’Application, lorsque vous souscrirez à un abonnement, un match compétitif ou 
  //   un tournoi ou lorsque vous nous contactez.<br /><br />

  //   Enfin dans d’autres cas, nous procédons à la collecte de Données Personnelles 
  //   indirectes et notamment des données techniques (adresse IP, données de 
  //   navigation, etc.).<br /><br />

  //   Lorsque nous collectons des Données Personnelles, nous signalons les champs 
  //   obligatoires au moyen d’un astérisque. Certaines des Donnée Personnelles que 
  //   nous demandons sont indispensables pour les raisons suivantes :<br />

  //     <ul>
  //       <li style="margin-bottom : .3rem">
  //         L’exécution du contrat que vous souscrivez ;
  //       </li>
  //       <li style="margin-bottom : .3rem">
  //         La fourniture des Services auxquels vous avez souscrit (par exemple, afin de 
  //         vous envoyer des notifications de réservation ou d’annulation) ;
  //       </li>
  //       <li style="margin-bottom : .3rem">
  //         Le respect des obligations légales (tels que celles liées à la facturation).
  //       </li>
  //     </ul>
  //     <br />

  //     Le fait de ne pas renseigner les champs signalés par un astérisque pourra impacter 
  //     notre capacité à vous offrir les Services.`,
  // },
  // whatPersonalDataIsLikelyToBeCollected: {
  //   title: "QUELLES DONNEES PERSONNELLES SONT SUSCEPTIBLES D’ETRE COLLECTEES, POUR QUELLES RAISONS ET SUR QUELLE BASE LEGALE ?",
  //   content: `Vous trouverez dans le tableau ci-dessous, des informations plus détaillées sur les 
  //             utilisations qui sont faites de vos données, c’est à dire les finalités de traitement, 
  //             ainsi que la base légale justifiant un tel traitement conformément à la règlementation 
  //             applicable.

  //             <table style="width: 100%; border-collapse: collapse; margin-top: 1rem;">
  //               <thead>
  //                 <tr>
  //                   <th style="border: 1px solid #ccc; padding: 4px 8px; background-color: #f4f4f4; text-align: left; vertical-align: top;">Dans quelles situations vos Données Personnelles peuvent être fournies ou collectées ?</th>
  //                   <th style="border: 1px solid #ccc; padding: 4px 8px; background-color: #f4f4f4; text-align: left; vertical-align: top;">Quelles sont les Données Personnelles traitées ?</th>
  //                   <th style="border: 1px solid #ccc; padding: 4px 8px; background-color: #f4f4f4; text-align: left; vertical-align: top;">Pourquoi sont-elles traitées ?</th>
  //                   <th style="border: 1px solid #ccc; padding: 4px 8px; background-color: #f4f4f4; text-align: left; vertical-align: top;">Quelle est la base légale du traitement ?</th>
  //                 </tr>
  //               </thead>
  //               <tbody>
  //                 <tr>
  //                   <td rowspan="3" style="border: 1px solid #ccc; padding: 4px 8px; vertical-align: top;">
  //                     <strong>Création et gestion d’un compte</strong><br />
  //                     Données collectées lors de la création d’un compte sur l’Application.
  //                   </td>
  //                   <td rowspan="3" style="border: 1px solid #ccc; padding: 4px 8px; vertical-align: top;">
  //                      Ces Données Personnelles à peuvent comprendre :<br />
  //                       <ul>
  //                         <li style="margin-bottom : .3rem">
  //                           Nom et prénom
  //                         </li>
  //                         <li style="margin-bottom : .3rem">
  //                           Adresse email
  //                         </li>
  //                         <li style="margin-bottom : .3rem">
  //                           Numéro de téléphone
  //                         </li>
  //                       </ul>
  //                   </td>
  //                   <td style="border: 1px solid #ccc; padding: 4px 8px; vertical-align: top;">
  //                     Nous utilisons ces données afin de :<br />
  //                       <ul>
  //                         <li style="margin-bottom : .3rem">
  //                           Vous donner accès à un compte utilisateur personnel ;
  //                         </li>
  //                         <li style="margin-bottom : .3rem">
  //                           Répondre à vos questions et interagir avec vous de toute autre manière ;
  //                         </li>
  //                         <li style="margin-bottom : .3rem">
  //                           Vous nous contacter pour finaliser votre commande si vous avez placé des services dans votre panier d’achat sans procéder au paiement.
  //                         </li>
  //                       </ul>
  //                   </td>
  //                   <td style="border: 1px solid #ccc; padding: 4px 8px; vertical-align: top;">
  //                     <strong>Exécution d’un contrat</strong><br />
  //                       <ul>
  //                         <li style="margin-bottom : .3rem">
  //                           Pour fournir le Service que vous avez demandé à savoir la création de compte. 
  //                         </li>
  //                       </ul>
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td style="border: 1px solid #ccc; padding: 4px 8px; vertical-align: top;">
  //                       <ul>
  //                         <li style="margin-bottom : .3rem">
  //                           Vous adresser des communications commerciales.
  //                         </li>
  //                       </ul>
  //                   </td>
  //                   <td style="border: 1px solid #ccc; padding: 4px 8px; vertical-align: top;">
  //                     <strong>Intérêt légitime</strong><br />
  //                     <ul>
  //                       <li style="margin-bottom : .3rem">
  //                         Vous adresser des messages promotionnels en lien avec les Services auxquels vous avez souscrit. 
  //                       </li>
  //                     </ul>
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td style="border: 1px solid #ccc; padding: 4px 8px; vertical-align: top;">
  //                     <ul>
  //                       <li style="margin-bottom : .3rem">
  //                         Contrôler et améliorer notre Application ;
  //                       </li>
  //                       <li style="margin-bottom : .3rem">
  //                         Procéder à des analyses d’audience ou réaliser des statistiques ;
  //                       </li>
  //                       <li style="margin-bottom : .3rem">
  //                         Sécuriser notre Application et assurer notre et votre protection contre la fraude.
  //                       </li>
  //                     </ul>
  //                   </td>
  //                   <td style="border: 1px solid #ccc; padding: 4px 8px; vertical-align: top;">
  //                     <strong>Intérêt légitime</strong><br />
  //                     <ul>
  //                       <li style="margin-bottom : .3rem">
  //                         Maintenir la sécurité de l’Application et assurer sa protection contre la fraude, ainsi que nous aider à mieux comprendre vos besoins et vos attentes et, ainsi, améliorer nos services.
  //                       </li>
  //                     </ul>
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td  rowspan="2" style="border: 1px solid #ccc; padding: 4px 8px; vertical-align: top;">
  //                     <strong>Souscription et gestion des réservations et des abonnements</strong><br />
  //                     <ul>
  //                       <li style="margin-bottom : .3rem">
  //                         Données collectées lors de la procédure d’achat de Services réalisée sur l’Application.
  //                       </li>
  //                     </ul>
  //                   </td>
  //                   <td  rowspan="2" style="border: 1px solid #ccc; padding: 4px 8px; vertical-align: top;">
  //                     Ces données à caractère personnel peuvent comprendre :<br />
  //                     <ul>
  //                       <li style="margin-bottom : .3rem">
  //                         Nom et prénom
  //                       </li>
  //                       <li style="margin-bottom : .3rem">
  //                         Adresse de messagerie 
  //                       </li>
  //                       <li style="margin-bottom : .3rem">
  //                         Numéro de téléphone ;
  //                       </li>
  //                       <li style="margin-bottom : .3rem">
  //                         Présentation ou préférences personnelles 
  //                       </li>
  //                       <li style="margin-bottom : .3rem">
  //                         Informations relatives à toute transaction, y compris les services souscrits 
  //                       </li>
  //                       <li style="margin-bottom : .3rem">
  //                         Paiement et informations 
  //                       </li>
  //                       <li style="margin-bottom : .3rem">
  //                         Historique de souscription.
  //                       </li>
  //                     </ul>
  //                   </td>
  //                   <td style="border: 1px solid #ccc; padding: 4px 8px; vertical-align: top;">
  //                     Nous utilisons ces données afin de :<br />
  //                     <ul>
  //                       <li style="margin-bottom : .3rem">
  //                         Assurer la fourniture des Services ;
  //                       </li>
  //                       <li style="margin-bottom : .3rem">
  //                         Gérer le paiement de votre abonnement ou de votre commande. Il est précisé que les informations relatives au paiement (numéro de carte de crédit/coordonnées bancaires) ne sont pas collectées par nos soins mais directement par les prestataires de service de paiement Stripe ;
  //                       </li>
  //                       <li style="margin-bottom : .3rem">
  //                         Gérer tout contact que vous avez avec nous concernant votre abonnement. 
  //                       </li>
  //                     </ul>
  //                   </td>
  //                   <td style="border: 1px solid #ccc; padding: 4px 8px; vertical-align: top;">
  //                     <strong>Exécution d’un contrat</strong><br />
  //                     <ul>
  //                       <li style="margin-bottom : .3rem">
  //                         Pour fournir le Service que vous avez réservé. 
  //                       </li>
  //                     </ul>
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td style="border: 1px solid #ccc; padding: 4px 8px; vertical-align: top;">
  //                     <ul>
  //                       <li style="margin-bottom : .3rem">
  //                         Procéder à des analyses d’audience ou réaliser des statistiques. 
  //                       </li>
  //                     </ul>
  //                   </td>
  //                   <td style="border: 1px solid #ccc; padding: 4px 8px; vertical-align: top;">
  //                     <strong>Intérêt légitime</strong><br />
  //                     <ul>
  //                       <li style="margin-bottom : .3rem">
  //                         Adapter nos communications commerciales et comprendre leur efficacité, et veiller à ce que vous bénéficiez de l’expérience la plus appropriée, ainsi que nous aider à mieux comprendre vos besoins et vos attentes et, ainsi, améliorer nos services.
  //                       </li>
  //                     </ul></td>
  //                 </tr>
  //                 <tr>
  //                   <td style="border: 1px solid #ccc; padding: 4px 8px; vertical-align: top;">
  //                     <strong>Navigation sur l’Application</strong><br />
  //                     <ul>
  //                       <li style="margin-bottom : .3rem">
  //                         Données collectées par des cookies ou des technologies similaires (ci-après des « Cookies ») lors de votre navigation sur l’Application. 
  //                       </li>
  //                     </ul>
  //                   </td>
  //                   <td style="border: 1px solid #ccc; padding: 4px 8px; vertical-align: top;">
  //                     Selon la fréquence de vos interactions avec nous, ces Données Personnelles peuvent comprendre :<br />
  //                     <ul>
  //                       <li style="margin-bottom : .3rem">
  //                         Données relatives à votre utilisation de l’Application 
  //                       </li>
  //                       <li style="margin-bottom : .3rem">
  //                         Données de connexion  
  //                       </li>
  //                       <li style="margin-bottom : .3rem">
  //                         Pages consultées 
  //                       </li>
  //                       <li style="margin-bottom : .3rem">
  //                         Votre localisation
  //                       </li>
  //                       <li style="margin-bottom : .3rem">
  //                         Durée de votre visite  
  //                       </li>
  //                     </ul>
  //                     <br />
  //                     Informations techniques :<br />
  //                     <ul>
  //                       <li style="margin-bottom : .3rem">
  //                         Adresse IP 
  //                       </li>
  //                       <li style="margin-bottom : .3rem">
  //                         Données relatives au navigateur 
  //                       </li>
  //                       <li style="margin-bottom : .3rem">
  //                         Données relatives à l’appareil
  //                       </li>
  //                     </ul>
  //                   </td>
  //                   <td style="border: 1px solid #ccc; padding: 4px 8px; vertical-align: top;">
  //                     Nous utilisons des Cookies, pour les finalités suivantes :<br />
  //                     <ul>
  //                       <li style="margin-bottom : .3rem">
  //                         Veiller au bon fonctionnement de l’Application ; 
  //                       </li>
  //                       <li style="margin-bottom : .3rem">
  //                         Améliorer notre Application ou nos Services ; 
  //                       </li>
  //                       <li style="margin-bottom : .3rem">
  //                         Garantir la protection et la sécurité de l’Application, et vous protéger contre toute fraude ou utilisation frauduleuse de notre Application ;
  //                       </li>
  //                       <li style="margin-bottom : .3rem">
  //                         Réaliser des statistiques et des mesures d’audience ; 
  //                       </li>
  //                       <li style="margin-bottom : .3rem">
  //                         Adapter nos Services pour vous afin de vous adresser des recommandations, des communications commerciales en lien ;
  //                       </li>
  //                       <li style="margin-bottom : .3rem">
  //                         Vous proposer nos services de manière personnalisée, par exemple, en gardant en mémoire vos données de connexion, votre langue, les cookies de personnalisation de l’interface utilisateur.
  //                       </li>
  //                     </ul>
  //                   </td>
  //                   <td style="border: 1px solid #ccc; padding: 4px 8px; vertical-align: top;">
  //                     <strong>Intérêt légitime</strong><br />
  //                     <ul>
  //                       <li style="margin-bottom : .3rem">
  //                         Veiller à vous proposer une Application, des publicités et des communications qui fonctionnent correctement, et améliorer en permanence les cookies qui sont (i) fondamentaux pour le fonctionnement de notre site Internet, (ii) utilisés pour assurer la protection et la sécurité de notre site Internet (les « cookies nécessaires »). 
  //                       </li>
  //                     </ul><br />
  //                     <strong>Consentement</strong><br />
  //                     <ul>
  //                       <li style="margin-bottom : .3rem">
  //                         Pour tous les autres cookies.
  //                       </li>
  //                     </ul>
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td style="border: 1px solid #ccc; padding: 4px 8px; vertical-align: top;">
  //                     <strong>Demande d’exercice de droits</strong>
  //                   </td>
  //                   <td style="border: 1px solid #ccc; padding: 4px 8px; vertical-align: top;">
  //                     <ul>
  //                       <li style="margin-bottom : .3rem">
  //                         Nom et prénom 
  //                       </li>
  //                       <li style="margin-bottom : .3rem">
  //                         Adresse email 
  //                       </li>
  //                     </ul>
  //                   </td>
  //                   <td style="border: 1px solid #ccc; padding: 4px 8px; vertical-align: top;">
  //                     <ul>
  //                       <li style="margin-bottom : .3rem">
  //                         Répondre à une demande d’exercice de droits formulée par vos soins.
  //                       </li>
  //                     </ul>
  //                   </td>
  //                   <td style="border: 1px solid #ccc; padding: 4px 8px; vertical-align: top;">
  //                     <ul>
  //                       <li style="margin-bottom : .3rem">
  //                         <strong>Obligation légale et Intérêt légitime</strong> pour se constituer des preuves en cas de contentieux 
  //                       </li>
  //                     </ul>
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td style="border: 1px solid #ccc; padding: 4px 8px; vertical-align: top;">
  //                     <strong>Assistance et support </strong>
  //                   </td>
  //                   <td style="border: 1px solid #ccc; padding: 4px 8px; vertical-align: top;">
  //                     <ul>
  //                       <li style="margin-bottom : .3rem">
  //                         Nom et prénom
  //                       </li>
  //                       <li style="margin-bottom : .3rem">
  //                         Adresse email
  //                       </li>
  //                       <li style="margin-bottom : .3rem">
  //                         Numéro de téléphone 
  //                       </li>
  //                     </ul>
  //                   </td>
  //                   <td style="border: 1px solid #ccc; padding: 4px 8px; vertical-align: top;">
  //                     <ul>
  //                       <li style="margin-bottom : .3rem">
  //                         Répondre à une demande d’assistance formulée par vos soins. 
  //                       </li>
  //                     </ul>
  //                   </td>
  //                   <td style="border: 1px solid #ccc; padding: 4px 8px; vertical-align: top;">
  //                     <ul>
  //                       <li style="margin-bottom : .3rem">
  //                        <strong>Consentement</strong>
  //                       </li>
  //                     </ul>
  //                   </td>
  //                 </tr>
  //               </tbody>
  //             </table>`,
  // },
  // personalDataStorageDuration: {
  //   title: "DUREE DE CONSERVATIONS DES DONNEES PERSONNELLES",
  //   content: `Les Données Personnelles collectées dans le cadre de votre utilisation de 
  //             l’Application et des Services sont conservées : <br />

  //             <ul style="list-style-type: '-  ';">
  //               <li style="margin-bottom : .3rem">
  //                 pendant toute la durée de la relation contractuelle pour ce qui concerne les données nécessaires à l’exercice de la relation contractuelle ; 
  //               </li>
  //               <li style="margin-bottom : .3rem">
  //                 pendant 36 mois pour les données personnelles issues de comptes inactifs ; 
  //               </li>
  //               <li style="margin-bottom : .3rem">
  //                 pendant une durée de 5 ans à compter de la demande d’exercice de droits ; 
  //               </li>
  //               <li style="margin-bottom : .3rem">
  //                 pendant une durée de 3 mois pour ce qui concerne les données d’assistance et de support.
  //               </li>
  //               <li style="margin-bottom : .3rem">
  //                 les Données Personnelles collectées par l’intermédiaire de Cookies seront sauvegardées durant une durée maximale de 13 mois.
  //               </li>
  //             </ul>
  //             <br />

  //             Par dérogation à ce qui précède, nous sommes susceptibles d’étendre la durée de 
  //             conservation de certaines de vos Données Personnelles et ce, uniquement dans le 
  //             but de remplir les obligations légales à notre charge.  <br /><br />

  //             A l’expiration des délais susmentionnés, vos Données Personnelles seront soit 
  //             anonymisées à des fins statistiques et selon un système d’archivage adapté à la 
  //             préservation de vos droits, soit détruites. <br /><br />

  //             Nous nous engageons à prendre toutes mesures techniques et organisationnelles 
  //             pour limiter l’accès à vos Données Personnelles aux seules personnes habilitées et 
  //             pour en assurer la sécurité contre tout type d’événements (destruction, perte, 
  //             altération, diffusion ou accès non autorisé…).`,
  // },
  // thirdPartyIntervention: {
  //   title: "INTERVENTION DE TIERS",
  //   content: `La fourniture des Services peut requérir la communication de certaines de vos 
  //             Données Personnelles à nos prestataires et ce, exclusivement en vue de la 
  //             fourniture par ces derniers des prestations nécessaires aux Services. <br /><br />

  //             Nous sommes également susceptibles de communiquer vos données personnelles 
  //             aux sous-traitants techniques auxquels nous faisons appel pour la fourniture des 
  //             Services (informatique, données bancaires, etc.). <br /><br />

  //             Nous nous assurons que l’intégralité de nos prestataires et sous-traitants s’engage à assurer une pleine conformité avec la réglementation en vigueur au sein de l’Union Européenne en matière de données personnelles et avec nos obligations au titre de la présente Politique de confidentialité.`,
  // },
  // placeOfStorageOfYourData: {
  //   title: "Protection des données",
  //   content: `Nous conservons vos données exclusivement en France ou au sein de l’Union 
  //             Européenne. <br /><br />

  //             Certains de nos prestataires ou sous-traitants destinataires de vos Données 
  //             Personnelles peuvent se trouver dans des pays offrant des niveaux de protection des 
  //             données personnelles non équivalents au niveau de protection des données 
  //             personnelles dans l’Union Européenne. <br /><br />

  //             Dans cette hypothèse, nous avons encadré le transfert de vos Données 
  //             Personnelles par le biais d’engagements contractuels avec nos prestataires et 
  //             sous-traitants afin qu’ils s’engagent à mettre en place un niveau suffisant de 
  //             protection de vos Données Personnelles.`,
  // },
  // yourRightsRegardingPersonalData: {
  //   title: "VOS DROITS CONCERNANT LES DONNÉES PERSONNELLES",
  //   content: `Conformément à la réglementation en vigueur, vous disposez concernant vos 
  //             Données Personnelles d’un : <br />

  //               <ul>
  //                 <li style="margin-bottom : .3rem">
  //                   Droit d’accès et de communication : vous pouvez demander si des Données Personnelles qui vous concernent sont traitées et, dans l’affirmative, demander l’obtention d’une copie dans un format compréhensible ; 
  //                 </li>
  //                 <li style="margin-bottom : .3rem">
  //                   Droit de rectification : Vous pouvez demander à RESAPADEL que vos Données Personnelles soient, selon les cas, rectifiées, complétées si elles sont inexactes, incomplètes, équivoques, périmées ;
  //                 </li>
  //                 <li style="margin-bottom : .3rem">
  //                   Droit à l’effacement : Vous pouvez demander à RESAPADEL l’effacement de vos Données Personnelles dans les cas prévus par la législation et la réglementation ;
  //                 </li>
  //                 <li style="margin-bottom : .3rem">
  //                   Droit de limitation : Vous pouvez demander la limitation du traitement de vos Données Personnelles en vue de suspendre temporairement l’utilisation, partielle ou totale, de vos Données Personnelles ; 
  //                 </li>
  //                 <li style="margin-bottom : .3rem">
  //                   Droit d’opposition : Vous pouvez vous opposer, à tout moment, pour des raisons tenant à votre situation particulière, à un traitement de vos Données Personnelles ayant comme base juridique l’intérêt légitime de RESAPADEL ; 
  //                 </li>
  //                 <li style="margin-bottom : .3rem">
  //                   Droit à la portabilité : Vous disposez du droit à la portabilité de vos Données Personnelles. RESAPADEL attire toutefois votre attention sur le fait qu’il ne s’agit pas d’un droit général, toutes les données de tous les traitements n’étant pas portables et ce droit ne concernant que les traitements automatisés à l’exclusion des traitements manuels ou papiers. Ce droit est limité aux traitements dont la base juridique est votre consentement ou l’exécution des mesures précontractuelles ou d’un contrat.
  //                 </li>
  //                 <li style="margin-bottom : .3rem">
  //                   Droit d’introduire une réclamation : Vous avez le droit d’introduire une réclamation auprès de la CNIL en France (3, place de Fontenoy, 75007 Paris) pour contester les pratiques de RESAPADEL en matière de protection des données à caractère personnel et de respect de la vie privée. 
  //                 </li>
  //               </ul>
                
  //               À titre de complément, vous pouvez consulter la page de la CNIL vous informant de 
  //               vos différents <br />
                
  //               droits : <a href="https://www.cnil.fr/fr/les-droits-pour-maitriser-vos-donnees-personnelles" target="_blank">https://www.cnil.fr/fr/les-droits-pour-maitriser-vos-donnees-personnelles</a> `,
  // },
  // contactResponsableConformite : {
  //   title: "CONTACTER LE RESPONSABLE CONFORMITE",
  //   content: `Si vous avez des questions ou des remarques sur la manière dont nous traitons et 
  //             utilisons vos Données Personnelles, ou si vous souhaitez exercer l’un quelconque de 
  //             vos droits mentionnés ci-dessus, veuillez nous contacter à l’adresse électronique 
  //             suivante [email à compléter] ou nous écrire à l’adresse suivante : [<mark>à compléter</mark>]. <br /> <br />
              
  //             Vous pouvez aussi contacter notre responsable conformité au RGPD aux 
  //             coordonnées suivantes : [<mark>à compléter</mark>]`,
  // },
};
