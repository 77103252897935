import React, { useState } from 'react';
import {
    Button,
    CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface IFilterProps {
    callback?: any;
    defaultValue?: any;
    disabled?: boolean;
    onClick?: any; // Assuming onClick is an async function
    type?: 'button' | 'submit'; // Add type prop
    children?:any
}

export const SubmitButton: React.FC<IFilterProps> = ({
    callback,
    defaultValue,
    disabled,
    onClick,
    children,
    type = 'button', // Default type is 'button'
}) => {
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        // Check if the button type is not submit, otherwise skip onClick handling
        if (type === 'submit') {
            return; // Just let the form submission handle it
        }

        if (onClick) {
            setIsSubmitting(true);
            try {
                await onClick(); // Call the provided onClick function
            } catch (error) {
                console.error('Error during submit:', error);
                // Handle the error as needed (e.g., show a toast)
            } finally {
                setIsSubmitting(false); // Reset the submitting state
                if (callback) callback(); // Call the callback if provided
            }
        }
    };

    return (
        <Button
            variant="contained"
            color="primary"
            style={{
                //width: '30%',
                backgroundColor: '#333333',
                color: 'white', // Text color
            }}
            disabled={disabled || isSubmitting} // Disable if loading or explicitly disabled
            onClick={type === 'button' ? handleClick : undefined}  // Attach the click handler
            type={type} // Set the button type
        >
            {isSubmitting || disabled? (
                <CircularProgress size={24} color="inherit" />
            ) : (
                children || t("submit") // Fallback to a default text
            )}
        </Button>
    );
};
