import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  IconButton,
  Divider,
  Typography,
  CardMedia,
  Avatar,
  Menu,
  MenuItem,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Button,
  AvatarGroup,
  Chip,
} from "@mui/material";

import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { Mention } from "primereact/mention";

import CloseIcon from "@mui/icons-material/Close";
import Slider from "react-slick";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import { formatTimestamp } from "../../../../shared/components/FormatDate";
import EmojiSelector from "./EmojiSelector";
import {
  IClubSummary,
  IConsolidatedUser,
  IFeedItem,
  IFeedItemComment,
  IMedia,
  IModalFeedItemDetailsProps,
  ITopLikeSummary,
  IUserSummary,
} from "../../models/FeedPageModels";
import {
  CDN_URL,
  FEED_ITEMS_LIST,
  FEED_ITEM_COMMENTS_LIST,
  FeedAttachmentSliderSettings,
  PersonDefaultImage,
} from "../../../../config/constants";
import ModalFeedItemShare from "./ModalFeedItemShare";
import useFetchFeedItemComments from "../../hooks/useFetchFeedItemComments";
import {
  addFeedItemComment,
  deleteFeedItem,
  deleteFeedItemComment,
} from "../../apiService/feedAPIService";
import { useQueryClient } from "@tanstack/react-query";
import useGetCurrentUser from "../../hooks/useGetCurrentUser";
import { replaceUserPatternWithName } from "../../../../shared/util/common";
import useFetchAllUsers from "../../hooks/useFetchAllUsers";

interface IUsers extends IConsolidatedUser {
  nickname: string;
}

const ModalFeedItemDetails: React.FC<IModalFeedItemDetailsProps> = ({
  open,
  onClose,
  post,
  onEdit,
  onFeedChange,
  setFeedLoading,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedComment, setSelectedComment] =
    useState<IFeedItemComment | null>(null);
  const [mainAnchorEl, setMainAnchorEl] = useState<null | HTMLElement>(null);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [commentMessage, setCommentMessage] = useState("");
  const [comments, setComments] = useState<IFeedItemComment[]>([]);
  const [openEmojiSelector, setOpenEmojiSelector] = useState(false);
  const [users, setUsers] = useState<IUsers[]>([]);
  const [suggestions, setSuggestions] = useState<IUsers[]>([]);
  const [mentions, setMentions] = useState<string[]>([]);

  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { data: allUsersList } = useFetchAllUsers();
  const { data: commentsList } = useFetchFeedItemComments(post?._id);
  const { currentUser } = useGetCurrentUser();

  useEffect(() => {
    setComments(commentsList);
  }, [commentsList]);

  useEffect(() => {
    if (!allUsersList) return;
    let locUsers: IUsers[] = [];
    allUsersList.forEach((user: IUsers) => {
      const { firstName, lastName, userId, profilePic, userType } = user;

      if (!firstName || !lastName) return;

      const fullName = `${firstName}${lastName}`
        .replace(/\s+/g, "")
        .toLowerCase();

      locUsers.push({
        firstName,
        lastName,
        userId,
        profilePic,
        userType,
        nickname: `${fullName}__${userId}^`,
      });
    });

    setUsers(locUsers);
  }, [allUsersList]);

  useEffect(() => {
    extractMentions(commentMessage || "");
  }, [commentMessage]);

  const reloadPost = async () => {
    await queryClient.invalidateQueries({
      queryKey: [FEED_ITEM_COMMENTS_LIST, post?._id],
    });
  };

  const renderClubChips = () => {
    if (!post?.clubs || post?.clubs.length === 0) {
      return null;
    }

    return (
      <div style={{ marginTop: "8px" }}>
        {post?.clubs?.map((club: IClubSummary) => {
          return (
            <Chip
              key={club?._id}
              label={club?.name}
              style={{ margin: "4px" }}
            />
          );
        })}
      </div>
    );
  };

  // Done - reviewing
  const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const emojiIconClick =
      e.target.tagName === "IMG" &&
      e.target.getAttribute("alt") === "Emoji icons";
    if (emojiIconClick) {
      setOpenEmojiSelector(true);
    }
    setCommentMessage(e.target.value);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    comment: IFeedItemComment
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedComment(comment);
  };

  const handleEmojiSelect = (emoji: string) => {
    setCommentMessage((prevComment) => prevComment + emoji);
    setOpenEmojiSelector(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedComment(null);
    onFeedChange();
  };

  // Done - refactoring
  const handleDeleteComment = async () => {
    await deleteFeedItemComment(selectedComment?._id ?? "");
    await reloadPost();
    handleClose();
  };

  // done - reviewing
  const handleEditClick = () => {
    handleClose();
    onEdit();
    handleMainClose();
    onClose();
  };

  // done - refactoring
  const addCommentPost = async () => {
    await addFeedItemComment({
      feedId: post?._id,
      message: commentMessage,
      userId: currentUser.userId,
      mentions,
    });

    setTimeout(async () => {
      await queryClient.invalidateQueries({ queryKey: [FEED_ITEMS_LIST] });
      setFeedLoading && setFeedLoading(false);
    }, 5 * 1000); // Giving 10 seconds for the feed item to be processed

    setCommentMessage(""); // Clear the comment message after posting
    handleClose();

    // Fetch club data again to ensure the latest club information is available
    await reloadPost();
  };

  const renderAttachments = (feedItem: IFeedItem) => {
    return feedItem?.attachments?.map((attachment: IMedia) => {
      let cardHeight = "56vh";
      if (
        feedItem?.message ||
        !isEmpty(feedItem?.clubs) ||
        feedItem?.ageRange
      ) {
        cardHeight = "54vh";
      }

      if (attachment?.fileType === "image") {
        return (
          <div key={attachment.mediaId} style={{ height: "100%" }}>
            <CardMedia
              component="img"
              style={{
                height: cardHeight,
                objectFit: "cover",
              }}
              alt={`Post Image ${attachment?.mediaId}`}
              src={`${CDN_URL}/${attachment?.filePath}`}
            />
          </div>
        );
      } else if (attachment?.fileType === "video") {
        return (
          <div key={attachment.mediaId} style={{ height: "100%" }}>
            <CardMedia
              component="video"
              style={{
                height: cardHeight,
                objectFit: "cover",
              }}
              controls
              src={`${CDN_URL}/${attachment?.filePath}`}
            />
          </div>
        );
      } else {
        return null;
      }
    });
  };

  const renderCommentContent = (
    commentMessage: string,
    commentMentions: IUserSummary[]
  ) => {
    if (!commentMessage) return null;
    const contentWithMention = replaceUserPatternWithName(
      commentMessage,
      commentMentions || []
    );
    return (
      <Typography>
        {contentWithMention.split("\n").map((line, index) => (
          <React.Fragment key={line[index]}>
            <span dangerouslySetInnerHTML={{ __html: line }} />
            <br />
          </React.Fragment>
        ))}
      </Typography>
    );
  };

  const renderComments = (comments: IFeedItemComment[]) => {
    return comments?.map((comment: IFeedItemComment) => {
      const {
        _id: commentId,
        commenterDetails,
        createdAt,
        message,
        // mentions,
        mentionsDetails,
      } = comment;

      return (
        <Grid container spacing={2} alignItems="center" key={commentId}>
          <Grid item xs={1}>
            <Avatar
              src={commenterDetails?.profilePic}
              alt={commenterDetails?.fullName}
            />
          </Grid>
          <Grid item xs={4}>
            <>
              {" "}
              <Typography>{`${commenterDetails?.fullName}`}</Typography>
              <Typography
                style={{ marginLeft: "25px", fontSize: "10px", color: "grey" }}
              >
                {formatTimestamp(createdAt)}
              </Typography>
            </>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">
              {renderCommentContent(message, mentionsDetails ?? [])}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              onClick={(e) => {
                handleClick(e, comment);
              }}
            >
              {/* Three dots icon */}
              ...
            </IconButton>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={4}></Grid>
          {/* Menu for comment options */}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={(e) => {
                handleDeleteComment();
              }}
              style={{ color: "#EB5757" }}
            >
              Delete Comment
            </MenuItem>
          </Menu>
        </Grid>
      );
    });
  };

  const renderLikes = (likes: ITopLikeSummary[], totalLikes?: any) => {
    if (!likes.length) {
      return null;
    }

    const firstLike = likes[0];
    const likedBy = `${firstLike?.likedByDetails?.[0]?.fullName}`;
    const remainingLikes = totalLikes - 1;

    return (
      <Box
        display="flex"
        alignItems="center"
        style={{ position: "absolute", bottom: 5 }}
      >
        <Typography>
          Liked by <strong>{likedBy}</strong>
          {remainingLikes > 0 && (
            <>
              {" "}
              <strong>
                and {remainingLikes} {remainingLikes === 1 ? "other" : "others"}
              </strong>
            </>
          )}
        </Typography>
        {/* Display avatars for the first three likes */}
        <Box ml={2}>
          <AvatarGroup>
            {likes?.slice(0, likes.length)?.map((like: ITopLikeSummary) => (
              <Avatar
                key={like?._id}
                src={like?.likedByDetails?.[0]?.profilePic}
                alt={`${like?.likedByDetails?.[0]?.fullName}`}
              />
            ))}
          </AvatarGroup>
        </Box>
        <Divider />
      </Box>
    );
  };

  const handleMainMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setMainAnchorEl(event.currentTarget);
  };

  const handleShareClick = () => {
    setShareModalOpen(true);
    handleClose();
  };

  // done - refactoring
  const handleDeleteClick = async () => {
    await deleteFeedItem(post?._id);
    await queryClient.invalidateQueries({ queryKey: [FEED_ITEMS_LIST] });
    handleClose();
  };

  const handleShareModalClose = () => {
    setShareModalOpen(false);
  };

  // done - refactoring
  const handleMainClose = async () => {
    await queryClient.invalidateQueries({ queryKey: [FEED_ITEMS_LIST] });
    setMainAnchorEl(null);
  };

  const renderPostContent = () => {
    if (!post?.message) return null;
    const contentWithMention = replaceUserPatternWithName(
      post?.message,
      post?.mentionsDetails || []
    );
    return (
      <Typography>
        {contentWithMention.split("\n").map((line, index) => (
          <React.Fragment key={line[index]}>
            <div dangerouslySetInnerHTML={{ __html: line }} />
          </React.Fragment>
        ))}
      </Typography>
    );
  };

  // create a function that will take postContent as parameter. Find all the userId from the postContent which will have the format of @userName__userId^ and extract the userId from it, and set it to the mentions state
  const extractMentions = (text: string) => {
    const regex = /@[a-zA-Z0-9_]+__([0-9][0-9a-f]{23})\^/g;
    const matches = text.matchAll(regex);
    const userIds: string[] = [];

    for (const match of matches) {
      userIds.push(match[1]);
    }

    setMentions(userIds);
  };

  const onSearch = (event: any) => {
    setTimeout(() => {
      const query = event.query;
      let suggestions: IUsers[]; // Declare suggestions as an array of IUsers

      if (!query.trim().length) {
        suggestions = [...users];
      } else {
        suggestions = users.filter((user) => {
          return (user?.nickname ?? "")
            .toLowerCase()
            .includes(query.toLowerCase());
        });
      }

      setSuggestions(suggestions);
    }, 250);
  };

  const itemTemplate = (suggestion: IUsers) => {
    const src = suggestion?.profilePic ?? PersonDefaultImage;

    return (
      <div className="flex align-items-center">
        <img
          alt={`${suggestion?.firstName} ${suggestion?.lastName}`}
          src={src}
          width="32"
          style={{ verticalAlign: "middle" }}
        />
        <span className="flex flex-column ml-2">
          {`${suggestion?.firstName} ${suggestion?.lastName}`}
        </span>
      </div>
    );
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          textAlign: "center",
          left: "50%",
          width: "90%",
          height: "80vh",
          borderRadius: "5px",
          transform: "translate(-50%, -50%)",
          padding: 16,
          background: "white",
        }}
      >
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          sx={{
            position: "absolute",
            top: "2px",
            right: "34px",
            cursor: "pointer",
          }}
        >
          <CloseIcon />
        </IconButton>
        {/* Modal content */}
        {/* Two-column layout (attachments and post details) */}
        <Box display="flex">
          <Box width="45%">
            {/* Attachments carousel */}
            {post?.attachments && !isEmpty(post?.attachments) && (
              <Slider {...FeedAttachmentSliderSettings}>
                {renderAttachments(post)}
              </Slider>
            )}

            <Box
              display="flex"
              alignItems="left"
              justifyContent="flex-start"
              sx={{ paddingTop: 3 }}
            >
              {renderPostContent()}
            </Box>
            {post?.clubs && !isEmpty(post?.clubs) && (
              <Box display="flex" alignItems="left" justifyContent="flex-start">
                {renderClubChips()}
              </Box>
            )}
            <Box display="flex" alignItems="left" justifyContent="flex-start">
              {post?.gender && post?.gender !== "" && (
                <Chip
                  key={post?.gender}
                  label={`Gender: ${post?.gender}`}
                  style={{ margin: "4px" }}
                />
              )}
              {post?.ageRange && (
                <Chip
                  key={post?.ageRange.join("_")}
                  label={`Age: ${post?.ageRange.join(" - ")}`}
                  style={{ margin: "4px" }}
                />
              )}
            </Box>
          </Box>
          <Box width="55%" style={{ height: "56vh", overflowY: "scroll" }}>
            {post?.createdByDetails && (
              <>
                <Card elevation={0}>
                  <CardHeader
                    avatar={<Avatar src={post?.createdByDetails?.profilePic} />}
                    title={post?.createdByDetails?.firstName}
                    action={
                      <>
                        <IconButton onClick={handleMainMenuClick}>
                          <MoreHorizIcon />
                        </IconButton>
                        <Menu
                          id="simple-menu"
                          anchorEl={mainAnchorEl}
                          keepMounted
                          open={Boolean(mainAnchorEl)}
                          onClose={handleMainClose}
                        >
                          <MenuItem onClick={handleShareClick}>
                            {t("share")}
                          </MenuItem>
                          <MenuItem onClick={handleEditClick}>
                            {t("edit")}
                          </MenuItem>
                          <MenuItem
                            onClick={handleDeleteClick}
                            style={{ color: "#EB5757" }}
                          >
                            {t("delete")}
                          </MenuItem>
                        </Menu>
                      </>
                    }
                  />
                  <CardContent sx={{ minHeight: "50vh" }}>
                    <Box display="flex" alignItems="center">
                      <IconButton onClick={() => setOpenEmojiSelector(true)}>
                        <InsertEmoticonIcon />
                      </IconButton>

                      <Mention
                        suggestions={suggestions}
                        onSearch={onSearch}
                        field="nickname"
                        placeholder={`${t("addAComment")}...`}
                        rows={2}
                        itemTemplate={itemTemplate}
                        onChange={handleCommentChange}
                        style={{ width: "100%" }}
                        inputStyle={{
                          width: "100%",
                          maxHeight: "7vh",
                          height: "7vh",
                          overflowY: "auto",
                        }}
                        panelStyle={{
                          minWidth: "40%",
                          maxHeight: "300px",
                          height: "300px",
                          zIndex: 9999,
                          // overflowY: "auto",
                        }}
                        value={commentMessage}
                      />
                      <Button
                        style={{ color: "grey" }}
                        onClick={addCommentPost}
                      >
                        {t("post")}
                      </Button>
                    </Box>
                    <Divider sx={{ marginBottom: 1, marginTop: 2 }} />
                    {/* Render comments */}
                    {comments && renderComments(comments)}

                    {/* <Divider sx={{ marginBottom: 1, marginTop: 2 }} /> */}
                    {/* Render likes */}
                    {post?.topLikes &&
                      renderLikes(post?.topLikes, post?.totalLikes)}

                    {openEmojiSelector && (
                      <EmojiSelector onSelect={handleEmojiSelect} />
                    )}
                  </CardContent>
                </Card>
                {shareModalOpen && (
                  <ModalFeedItemShare
                    open={shareModalOpen}
                    onClose={handleShareModalClose}
                    post={post}
                  />
                )}
              </>
            )}
          </Box>
        </Box>
      </div>
    </Modal>
  );
};

export default ModalFeedItemDetails;
