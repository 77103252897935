import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Dispatch, AnyAction } from "redux";
import { isEmpty } from "lodash";

import { CUSTOM_ERROR_MSG, HANDLE_ERROR, HTTP_CALL } from "../../config/http";

import { GET, POST, CHAT_MESSAGES, DELETE } from "../../config/API.constants";

import { showToast } from "../../shared/util/toastHelper";
import { productsReset } from "app/redux/productsReducer";

type AppDispatch = Dispatch<AnyAction>;

export const fetchConversationUsers =
  (callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};
    try {
      response = await HTTP_CALL(
        `${CHAT_MESSAGES.GET_CONVERSATIONS}?page=1&limit=1000&sort=lastUpdatedAt%7CDESC`,
        GET,
        ""
      );

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (typeof callback === "function") {
        callback(apiData);
      }

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

/**
 * Fetches data from the API to load end users.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */
export const fetchNewEndUsers =
  (params: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};
    try {
      const filters = [params?.status, params?.userType].join(",");

      const filterParamsObj = {
        page: params?.page || 1,
        limit: params?.pageSize || 10,
        sort: params?.sort || "timestamp|DESC",
        ...(filters ? { filters: filters } : {}),
      };

      const filteredSearchParams = new URLSearchParams(filterParamsObj);

      response = await HTTP_CALL(
        `${CHAT_MESSAGES.GET_END_USERS}?${filteredSearchParams.toString()}`,
        GET,
        ""
      );

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (typeof callback === "function") {
        callback(apiData);
      }

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

/**
 * Fetches data from the API.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */
export const fetchAllMessages =
  (params: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};

    const conversationId =
      params?.conversation && `conversation|${params?.conversation}`;

    // const conversationId = null;

    const filterParamsObj = {
      page: params?.page || 1,
      limit: params?.pageSize || 10,
      sort: params?.sort || "timestamp|DESC",
      ...(conversationId ? { filters: conversationId } : {}),
    };

    const filteredSearchParams = new URLSearchParams(filterParamsObj);

    try {
      response = await HTTP_CALL(
        `${CHAT_MESSAGES.GET_ALL_MESSAGE}?${filteredSearchParams.toString()}`,
        GET
      );

      const {
        data: { data: apiData, statusCode, message, totalCount },
      } = response;

      if (typeof callback === "function") {
        callback(response.data);
      }

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return { data: apiData, totalCount };
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

/**
 * Create a conversation in a chat (private or group).
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const createConversation =
  (values: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};
    try {
      response = await HTTP_CALL(
        CHAT_MESSAGES.ADD_CONVERSATION,
        POST,
        "",
        values
      );

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (typeof callback === "function") {
        callback(response);
      }

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        } else {
          showToast("No Data", "Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

/**
 * Create a conversation in a chat (private or group).
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const createMessage =
  (values: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};
    try {
      response = await HTTP_CALL(CHAT_MESSAGES.ADD_MESSAGE, POST, "", values);

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (typeof callback === "function") {
        callback(response);
      }

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        } else {
          showToast("No Data", "Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

/**
 * Delete a conversation in a chat (private or group).
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const deleteMessage =
  (values: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};
    try {
      response = await HTTP_CALL(
        `${CHAT_MESSAGES.DELETE_MESSAGE}/${values.id}`,
        DELETE,
        ""
      );

      showToast("Message deleted successfully", "Success", {
        position: toast.POSITION.TOP_RIGHT,
      });

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (typeof callback === "function") {
        callback(response);
      }

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        } else {
          showToast("No Data", "Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

export const resetData = () => async (dispatch: AppDispatch) => {
  dispatch(productsReset());
};
