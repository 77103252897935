import { IMAGE_BASE_PATH_BUCKET_URL } from "../../config/constants";

export const HelpFR = {
  howToSignup: {
    title: "COMMENT S'INSCRIRE RESA PADEL?",
    content: `Vous pouvez vous connecter à Resa Padel de deux manières :
            <br/>

            <ul style="list-style: number">
              <li>Inscription avec un numéro de mobile</li>
              <li>Inscription avec des comptes sociaux</li>
            </ul>
            <div class="static-subtitle">
              Inscription avec un numéro de mobile
            </div>
            Pour créer un compte, les utilisateurs doivent fournir leur indicatif de pays ainsi que leur numéro de mobile. <br/>

            <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/1Signup.png" alt="signup"  width="200px" />
            </div>

            En entrant un numéro de mobile valide, un mot de passe à usage unique (OTP) sera envoyé à l'utilisateur par SMS.
            <br/>

            <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/2Otp_msg.png" alt="Otp message"  width="200px" />
            </div>

            <div class="static-subtitle">
              Processus de vérification
            </div>
            Après avoir reçu l'OTP, les utilisateurs doivent le saisir dans l'application. Si l'OTP est valide, l'application demandera à l'utilisateur de définir un mot de passe. Ils doivent fournir un nouveau mot de passe et le confirmer en le saisissant à nouveau. Une fois le mot de passe confirmé avec succès, les utilisateurs peuvent se connecter à l'application.
            <br />
            <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/3OTP.png" alt="Otp"  width="200px" />
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/4create_pass.png" alt="Create Password"  width="200px" />
            </div>
            <br />

            Pour les connexions ultérieures, l'utilisateur doit se souvenir de ce mot de passe pour se connecter.

             <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/5Entermobile.png" alt="Enter Mobile"  width="200px" />
            </div>
          `,
  },
  howToSignSocialAccounts: {
    title: "COMMENT S'INSCRIRE À L'AIDE DES COMPTES SOCIAUX ?",
    content: `L'utilisateur doit authentifier la connexion au compte social et après une authentification 
            réussie, l'application permettra à l'utilisateur de se connecter.
            <br /><br />

            Comptes sociaux tels que <b>Gmail, Facebook, LinkedIn, Apple</b>

            <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/6Social.png" alt="Social"  width="200px" />
            </div>

            <div class="static-subtitle">Connexion :</div>
            Les utilisateurs inscrits se connecteront en fournissant leur numéro de mobile et leur mot de passe. S'ils se sont inscrits en utilisant leurs comptes sociaux, ils devront se connecter via leur compte social.


            <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/7Forgot.png" alt="Forgot"  width="200px" />
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/8Password.png" alt="Password"  width="200px" />
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/3OTP.png" alt="OTP"  width="200px" />

              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/9Update_pass.png" alt="Update"  width="200px" />
            </div>

            <div class="static-subtitle">Mot de passe oublié :</div>
            Lorsque vous ne parvenez pas à vous souvenir du mot de passe, vous pouvez utiliser le processus de mot de passe oublié. Cliquez sur l'option Mot de passe oublié. Entrez le numéro de mobile et saisissez le mot de passe à usage unique reçu pour réinitialiser le mot de passe.

            <div class="static-subtitle">Créer le profil joueur dans Resa Padel :</div>
            Après une inscription réussie, l'application demandera à l'utilisateur de remplir les informations de base du profil et les questions d'intégration.
            <br /><br />

            Les informations de base du profil et les questions d'intégration sont obligatoires et en fonction des réponses de l'utilisateur, le système générera automatiquement le niveau initial du joueur.

            <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/10Player_level.png" alt="Playerlevel"  width="200px" />
            </div>

            <div class="static-subtitle">Qu'est-ce que le niveau de joueur de l'utilisateur :</div>

            Lors de l'inscription de l'utilisateur, l'application affichera la liste des questions et en fonction des réponses de l'utilisateur, l'application calculera le niveau du joueur et celui-ci sera mis à jour en fonction des résultats des matchs compétitifs.
          `,
  },
  howtoReserveCourt: {
    title: "COMMENT RÉSERVER UN TERRAIN de Padel?",
    content: `<div class="static-subtitle">
              Sélection des détails du match :
            </div>
            Lorsque l'utilisateur active la localisation et clique sur l'option Réserver un court, l'application affiche le formulaire où l'utilisateur peut choisir le type de match (Simple ou Double) et d'autres détails comme le sexe, l'heure du match, Amical/Compétitif.
            <br /><br />

            Note : Si le type de match est défini comme Privé, les autres ne pourront pas voir le match sauf les invités dans l'organisation.
            <br />

            <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/11Config.png" alt="Config"  width="200px" />
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/12Config_2.png" alt="Config2"  width="200px" />
            </div>

            <div class="static-subtitle">
              Liste des clubs en fonction de la localisation actuelle de l'utilisateur ou de sa recherche :
            </div>
            L'application affichera la liste des clubs en fonction de la localisation actuelle de l'utilisateur et par défaut, le filtre de distance sera appliqué dans un rayon de 15 km par rapport à la localisation actuelle de l'utilisateur.
            <br /><br />

            Si l'utilisateur souhaite choisir un club ou une localisation spécifique, il peut le rechercher et les résultats de la recherche seront affichés.

            <div class="image-container">
            <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/13club-list.png" alt="clublist"  width="200px" />
            <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/14club-filter.png" alt="clubfilter"  width="200px" />
            </div>

            <div class="static-subtitle">
              Sélection de la date et de l'heure de réservation :
            </div>
            (Les utilisateurs peuvent réserver "n" jours à l'avance, configurables dans l'application).
            <br /><br />

            Lorsque l'utilisateur choisit une date, l'application liste les créneaux horaires disponibles. Les créneaux horaires seront affichés le matin, l'après-midi et le soir. Les créneaux avant 12h sont affichés le matin, entre 12h et 17h seront affichés l'après-midi et à partir de 17h, les créneaux seront affichés le soir.
            <br /><br />

            Après avoir choisi la date et l'heure, les courts disponibles dans le club respectif à la date et à l'heure spécifiques seront affichés et l'utilisateur pourra choisir le court et passer à la page de paiement. Sur la page de paiement, l'application affichera le récapitulatif du paiement et les détails de la réservation avec la possibilité de choisir les extras s'ils souhaitent en acheter.
            <br /><br />

            L'achat d'extras se fera en sélectionnant le produit et la quantité. Pour chaque achat et réservation, une taxe de 20 % sera ajoutée.
            <br /><br />

            Note : Tous les extras commandés seront affichés séparément sur la page Commandes avec leurs détails.

            <div class="static-subtitle">
              Qu'est-ce que le niveau de joueur de l'utilisateur :
            </div>
            Lors de l'inscription de l'utilisateur, l'application affichera la liste des questions et en fonction des réponses de l'utilisateur, l'application calculera le niveau du joueur et celui-ci sera mis à jour en fonction des résultats des matchs compétitifs.

            <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/15court.png" alt="court"  width="200px" />
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/16court-1.png" alt="court1"  width="200px" />
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/17iPhone-13-mini-24.png" alt="17iPhone"  width="200px" />
            </div>
         `,
  },
  payment: {
    title: "PAIEMENT",
    content: `Les utilisateurs peuvent ajouter une méthode de paiement pour effectuer le paiement. 
          Voici les options de   paiement disponibles dans l'application :

          <ul style="list-style: number">
            <li>Paiement par carte</li>
            <li>Paiement avec Gymlib</li>
            <li>Apple Pay</li>
          </ul>
          <div class="static-subtitle">Paiement par carte :</div>
          Lorsque l'utilisateur souhaite effectuer un paiement par carte, il doit ajouter la carte en fournissant les informations requises telles que le nom sur la carte, le numéro de la carte, la date d'expiration. Une fois la carte ajoutée et le paiement confirmé, l'application débitera le montant pour le paiement et la réservation sera confirmée.
          <br /><br />
          Une fois la réservation confirmée, l'utilisateur recevra un email de confirmation. Pour chaque transaction effectuée via Stripe, l'application déclenchera également une communication par email.

          <div class="static-subtitle">Paiement avec Gymlib :</div>
          Lorsque l'utilisateur souhaite payer avec Gymlib, il doit entrer le code Gymlib. Le code sera validé par Gymlib et, s'il est valide, la réservation sera confirmée.

          <div class="static-subtitle">Paiement avec Apple Pay :</div>
          L'utilisateur doit choisir Apple Pay comme méthode de paiement, confirmer les détails de la réservation et authentifier la transaction. De cette manière, la réservation peut être confirmée avec Apple Pay.

          <div class="image-container">
            <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/18payment.png" alt="payment"  width="200px" />
          </div>

          <div class="static-subtitle">Plans d'abonnement pour la réservation de courts :</div>
          Les plans d'abonnement sont disponibles de deux manières :
          <ul style="list-style: number">
            <li>
              Abonnement mensuel (La date de facturation actuelle sera la date d'achat du plan. La prochaine date de facturation sera la date de facturation du mois suivant)
            </li>
            <li>
              Abonnement annuel (La date de facturation actuelle sera la date d'achat du plan et la prochaine date de facturation sera la date de facturation de l'année suivante)
            </li>
          </ul>
          Les utilisateurs peuvent choisir la catégorie de plan et les plans disponibles dans cette catégorie seront affichés. Les plans peuvent être achetés selon le souhait de l'utilisateur (mensuel ou annuel).
          <br /><br />
          Les utilisateurs peuvent acheter le plan (achat unique) ou, s'ils le souhaitent, s'y abonner (paiement récurrent). Si l'utilisateur achète le plan, un email de confirmation du plan sera envoyé. Un email de rappel sera également envoyé à l'utilisateur avant l'expiration du plan (5 jours avant la date d'expiration).
          <br />

          <div class="static-subtitle">Avantages de l'achat d'un abonnement :</div>
          <ul>
            <li>1 session gratuite de 90 minutes par jour jusqu'à la date d'expiration du plan.</li>
            <li>Aucune transaction de paiement nécessaire pour chaque réservation.</li>
          </ul>
          À tout moment, les utilisateurs peuvent avoir 2 sessions gratuites en statut programmé. Une fois l'une des sessions gratuites terminée, l'utilisateur peut réserver à nouveau en utilisant l'abonnement. Si l'utilisateur souhaite effectuer une réservation le même jour où il a déjà eu une session gratuite, il doit payer pour la réservation.

          <div class="static-subtitle">Qu'est-ce que le niveau de joueur de l'utilisateur :</div>
          Lors de l'inscription de l'utilisateur, l'application affichera la liste des questions et, en fonction des réponses de l'utilisateur, l'application calculera le niveau du joueur et celui-ci sera mis à jour en fonction des résultats des matchs compétitifs.

          <div class="image-container">
            <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/19prouser-1.png" alt="payment"  width="200px" />
            <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/20prouser-2.png" alt="payment"  width="200px" />
          </div>
           `,
  },
  howtoJointheMatch: {
    title: "COMMENT REJOINDRE LE MATCH:",
    content: `<div class="static-subtitle">
              Choisir le club où ils veulent jouer
            </div>
            L'application affichera la liste des clubs en fonction de la localisation actuelle de l'utilisateur, et par défaut, un filtre de distance sera appliqué dans un rayon de 15 km autour de la localisation actuelle de l'utilisateur.
            <br /><br />
            Si l'utilisateur souhaite choisir un club ou un emplacement spécifique, il peut le rechercher et les résultats de la recherche seront affichés.
            <div class="static-subtitle">
              Choisir la date et les horaires du match :
            </div>
            Lorsque les utilisateurs veulent rejoindre un match, ils peuvent choisir le club et la liste des matchs (créés publiquement par d'autres utilisateurs dans le club en question) sera affichée.
            <br />
            Dans la liste des matchs, l'utilisateur peut voir les détails suivants :
            <ul>
              <li>Informations sur les joueurs</li>
              <li>Niveau du match</li>
              <li>Horaire du match et autres détails comme le sexe, les détails du club.</li>
            </ul>
            Les utilisateurs doivent sélectionner la position où ils veulent jouer et si le niveau du joueur de l'utilisateur se situe dans la gamme de niveaux du match, l'utilisateur peut rejoindre le match immédiatement.
            <br />  <br />

            Si le niveau du joueur de l'utilisateur est en dehors du niveau du match, alors l'utilisateur doit envoyer une demande aux autres joueurs du match. Lorsque tous les autres joueurs approuvent la demande, l'utilisateur qui a envoyé la demande doit être notifié.
            <br />  <br />

            Si l'utilisateur n'a pas reçu la notification d'approbation ou si la demande a été approuvée mais que l'utilisateur n'a pas confirmé la participation en payant le montant ou pendant le processus de demande, si l'un des autres joueurs a rejoint le match et qu'aucune position n'est laissée vide, l'utilisateur ne sera pas autorisé à rejoindre le match particulier.

            <div class="static-subtitle">
              Confirmer la participation au match en effectuant le paiement avec succès
            </div>
            Une fois que l'utilisateur a sélectionné sa position, il sera dirigé vers la page de paiement, où il pourra procéder au paiement pour sa place (l'organisateur du match a effectué le paiement pour sa place seulement).
            <br /><br />

            Si l'organisateur a réservé le court en payant la totalité du montant, alors l'utilisateur ne sera pas sollicité pour un paiement pour la participation au match.
            <br /><br />

            Cependant, si l'utilisateur souhaite acheter des extras, il devra les payer séparément, et une taxe de 20 % sera ajoutée à chaque achat.
            <br /><br />

            Les utilisateurs peuvent choisir la méthode de paiement si elle est déjà ajoutée ou ils peuvent ajouter la méthode de paiement et la réservation sera confirmée lorsque le paiement sera effectué avec succès.

            <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/21join-match.png" alt="join-match"  width="200px" />
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/22join-match1.png" alt="join-match1"  width="200px" />
            </div>
          `,
  },
  howtoJointhePrivateMatch: {
    title: "HOW TO JOIN THE PRIVATE MATCH:",
    content: `Lorsque l'organisateur crée le match de manière privée, seuls les utilisateurs qui ont reçu une invitation de l'organisateur peuvent voir les détails du match et rejoindre le match.
      <br />
      <br />
      Les détails du match peuvent être partagés via l'application Resa Padel en interne ou avec n'importe quelle plateforme de réseaux sociaux installée sur le téléphone de l'utilisateur ou depuis la liste de contacts.
      <br />
      <br />
      Lorsque l'utilisateur partage les détails du match, l'application enverra les détails du match dans l'application respective et lorsqu'il est partagé via les contacts, un message texte sera envoyé.
      <br />
      <br />
      Les utilisateurs invités peuvent rejoindre le match et le processus d'approbation du niveau de joueur restera le même que pour les matchs publics.`,
  },
  howtoJointheTournament: {
    title: "HOW TO JOIN THE TOURNAMENT:",
    content: `<div class="static-subtitle">
              Liste des Tournois :
            </div>
            Lorsque l'utilisateur clique sur le livre du tournoi, l'application listera les tournois se déroulant dans un rayon de 15 km à partir de l'emplacement actuel.
            <br /><br />
            Vous pouvez appliquer des filtres pour rechercher plus de tournois se déroulant en dehors des 15 km.

            <div class="static-subtitle">
              Rejoindre le Tournoi par soi-même sans invitation :
            </div>
            Lorsque l'utilisateur choisit le tournoi, il doit fournir le numéro de licence, ce qui est obligatoire à tout moment.
            <br /><br />
            L'utilisateur peut également mettre à jour le numéro de licence après avoir rejoint le tournoi.
            <br /><br />
            Lorsque l'utilisateur souhaite rejoindre le tournoi, il doit inviter son partenaire au tournoi et après avoir payé le montant, l'utilisateur devrait être autorisé à rejoindre le tournoi.
            <br /><br />
            Lorsque l'utilisateur rejoint le tournoi, l'application listera la participation au tournoi dans Mes Réservations → Écran du Tournoi.
            <br /><br />
            Initialement, il sera en statut programmé et une fois que l'heure actuelle dépassera l'heure de début du tournoi, l'application déplacera la réservation particulière à En cours et lorsque l'heure de fin du tournoi sera atteinte, la réservation particulière sera déplacée à statut complété.

            <div class="static-subtitle">
              Rejoindre le tournoi après avoir reçu l'invitation :
            </div>
            Lorsque l'utilisateur reçoit le tournoi, l'application cliquera sur la notification et les détails du tournoi seront affichés. Depuis la page des détails du tournoi, l'utilisateur devrait pouvoir effectuer le paiement avec ou sans entrer le numéro de licence et lorsque le paiement est réussi.

            <div class="static-subtitle">
              Participation au Tournoi Refusée :
            </div>
            Les utilisateurs doivent être informés lorsque l'administrateur refuse le numéro de licence ou lorsque l'administrateur partage des informations, alors l'utilisateur doit mettre à jour le numéro de licence si nécessaire et si c'est encore en statut refusé, l'application annulera la participation de l'utilisateur.
            <br /><br />
            Même si l'administrateur ne vérifie pas le numéro de licence, l'application annulera la participation de l'utilisateur avant 4 heures de la date et l'heure de début du tournoi.

            <div class="static-subtitle">
              Confirmation de Participation au Tournoi :
            </div>
            Lorsque l'administrateur approuve le numéro de licence de l'utilisateur, la participation sera confirmée.
            <br /><br />
            Remarque : Toutes les mises à jour du tournoi seront gérées en dehors de Resa Padel.

            <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/23tour-licens.png" alt="tour-licens"  width="200px" />
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/24tour.png" alt="24tour"  width="200px" />
            </div>
          `,
  },
  howtoBookCoachingSession: {
    title: "HOW TO BOOK A COACHING SESSION",
    content: `<div class="static-subtitle">
              Voir la liste des Clubs :
            </div>
            L'application affichera la liste des clubs en fonction de la localisation actuelle de l'utilisateur et, par défaut, le filtre de Distance sera appliqué dans un rayon de 15 km à partir de la localisation actuelle de l'utilisateur.
            <br /><br />

            Si l'utilisateur souhaite choisir un club ou une localisation spécifique, il peut le rechercher et les résultats de la recherche seront affichés.

            <div class="static-subtitle">
              Voir la liste des Entraîneurs :
            </div>
            Lorsque l'utilisateur choisit le club, l'application listera les entraîneurs disponibles dans les clubs.
            <br />

            Les utilisateurs peuvent choisir de réserver des "Entraînements Publics" ainsi que des "Entraînements Privés" selon leur souhait et peuvent choisir l'Entraîneur.

            <div class="static-subtitle">
              Confirmation de la date et de l'heure de la séance d'entraînement :
            </div>
            Pour chaque type d'entraînement, les utilisateurs peuvent voir les entraîneurs disponibles à une date et une heure spécifiques, les utilisateurs seront dirigés vers la page de paiement, où ils pourront procéder au paiement.
            <br /><br />

            Cependant, si l'utilisateur souhaite acheter des extras, il devra les payer séparément, et une taxe de 20% sera ajoutée à chaque achat.
            <br /><br />

            Les utilisateurs peuvent choisir le mode de paiement s'il est déjà ajouté ou ils peuvent ajouter le mode de paiement.
            <br /><br />

            La séance d'entraînement sera confirmée lorsque le paiement sera réussi pour l'Entraînement Privé, tandis que pour l'entraînement en groupe, l'application retiendra le montant total de la séance d'entraînement en groupe et avant "n" heures de la date et de l'heure de la réservation, l'application débitera le montant (Montant de la séance d'entraînement en groupe / Nombre total de participants confirmés) et libérera le montant restant sur le compte de l'utilisateur.

            <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/25coach.png" alt="coach"  width="200px" />
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/26coach1.png" alt="coach1"  width="200px" />
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/27coach2.png" alt="coach2"  width="200px" />
            </div>

            <div class="static-subtitle">
              Achat de Produits d'Entraînement :
            </div>
            Les utilisateurs peuvent acheter des crédits de séances d'entraînement en achetant le plan spécifique qu'ils préfèrent.
            <br /><br />

            En fonction des produits d'entraînement achetés, les utilisateurs peuvent réserver la séance d'entraînement privé gratuitement avec les entraîneurs éligibles pour le plan particulier.
            <br /><br />

            Si les entraîneurs sont en dehors de la catégorie du plan acheté, les utilisateurs seront facturés pour cela.
            <br /><br />

            Pour chaque achat de produit d'entraînement, l'application appliquera une taxe de 20% sur celui-ci.
            <br /><br />

            Les utilisateurs peuvent réserver la séance d'entraînement gratuitement et bénéficier de la séance d'entraînement gratuite comme un package.
            <br /><br />

            Remarque : Ce produit d'entraînement ne sera pas utilisé pour les Séances d'Entraînement en Groupe.
            
            <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/17iPhone-13-mini-24.png" alt="coach"  width="200px" />
            </div>

            `,
  },
  myBookings: {
    title: "MES RÉSERVATIONS",
    content: `Toutes les réservations confirmées des Entraînements, Tournois et Réservations de courts/Rejoindre un match seront reflétées dans l'écran Mes réservations dans les onglets respectifs.
            <br /><br />

            Lorsque les réservations sont programmées et que la date et l'heure actuelles ne dépassent pas la date et l'heure de la réservation, l'utilisateur peut voir ces réservations en statut Programmé.
            <br /><br />

            Une fois que la date et l'heure actuelles dépassent la date et l'heure de la réservation, l'application déplacera les réservations au statut En cours et lorsque la date et l'heure actuelles dépassent la date et l'heure de fin de la réservation, elles seront déplacées au statut Terminé.

            <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/28booking1.png" alt="bookings1"  width="200px" />
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/29booking2.png" alt="bookings2"  width="200px" />
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/30booking3.png" alt="bookings3"  width="200px" />
            </div>

            <div class="static-subtitle">
              Mise à jour du Score du Match:
            </div>
            Lorsque les matchs sont déplacés dans l'onglet En cours/Terminé, n'importe quel participant au match peut mettre à jour le score de chaque set.
            <br /><br />

            Lorsqu'un des utilisateurs commence à saisir la mise à jour du score, les autres participants au match ne pourront pas le mettre à jour. Ils peuvent accepter/Élever un conflit pour les mises à jour du score.
            <br /><br />

            Lorsque le conflit est élevé, l'application permettra à l'utilisateur qui a saisi le score de le mettre à jour.
            <br /><br />

            Si aucun utilisateur ne répond à la mise à jour du score du match, l'application confirmera automatiquement le score 6 heures après l'heure de fin du match.

            <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/31result.png" alt="result"  width="200px" />
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/32result1.png" alt="result1"  width="200px" />
            </div>

            <div class="static-subtitle">
              Changer le type de Match de Privé à Public:
            </div>
            Les organisateurs peuvent changer le type de Match de Privé à Public dans l'onglet Mes réservations → statut Programmé. Une fois qu'il est passé en public, tous les utilisateurs devraient voir le match et ils peuvent également se joindre s'ils le souhaitent.

            <div class="image-container">
              <img src="${IMAGE_BASE_PATH_BUCKET_URL}help/33private-match.png" alt="bookings1"  width="200px" />
            </div>

            <div class="static-subtitle">
              Annuler le Match:
            </div>
            Les utilisateurs finaux peuvent annuler leur place en cliquant sur le bouton Annuler dans Mes réservations → page des détails de la réservation en statut Programmé.
            <br /><br />

            Si les utilisateurs annulent la réservation avant le délai autorisé pour annuler tel que défini dans la politique du club, ils recevront un remboursement complet et si le délai autorisé pour annuler est dépassé, aucun montant ne sera remboursé et la réservation sera déplacée au statut annulé.

            <div class="static-subtitle">
              Replanifier les sessions de coaching:
            </div>
            Les utilisateurs finaux peuvent replanifier leur session de coaching en cliquant sur le bouton Replanifier dans Mes réservations → page des détails du coaching en statut Programmé.
            <br /><br />

            Si les utilisateurs replanifient la réservation, l'application affichera les détails de l'entraîneur et sa disponibilité. En fonction de cela, l'utilisateur peut choisir la date et l'heure pour replanifier et lorsque la session est replanifiée, la réservation sera affichée à la nouvelle date et heure mises à jour.          `,
  },
};
