import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import { fetchConversationUsers } from "./messagesApiService";
import { AppDispatch } from "../../redux/store";
import { ChatClient } from "./ChatClient";
import { styled } from "@mui/system";
import { useAuth } from "../../../AuthContext";
import moment from "moment";

const ChatMessages: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useAuth();

  const ContentContainer = styled("div")`
    flex: 1;
    padding: ${(props) => props.theme.spacing(2)};
  `;

  const formatGroupName = (groupStr: string, isSingle = false) => {
    let groupName = "";
    if (isSingle) {
      groupName = moment(groupStr).format("DD-MM-yyyy HH:mm");
      return groupName;
    } else {
      groupName = groupStr?.split("@")?.[0]?.trim();
      groupName = groupName?.replace(/,/g, "");
      const date = new Date(groupName);

      return `${moment(date).format("DD/MM/yyyy")} @ ${moment(date).format(
        "HH:mm"
      )}`;
    }
  };

  const [conversationId, setConversationId] = useState("");
  const [groupName, setGroupName] = useState("");
  const [conversations, setConversations] = useState([]);

  useEffect(() => {
    dispatch(
      fetchConversationUsers((data: any) => {
        let newData = data
          ?.filter(
            (x: any) =>
              (x?.participants?.length > 1 ||
                (x?.participants?.length === 1 &&
                  x?.participants?.[0]?.user !== user?.userId)) &&
              x?.groupName
          )
          ?.map(
            (x: any) =>
              (x = {
                ...x,
                groupName: x?.groupName?.includes("@")
                  ? formatGroupName(x?.groupName)
                  : x?.groupName,
              })
          );

        setConversations(newData);
        setConversationId(newData?.[0]?._id);
        setGroupName(newData?.[0]?.groupName || "");
      })
    );
  }, [dispatch, user?.userId]);

  // handle user click event in chat conversation / room
  const handleUserClick = (user: any) => {
    setConversationId(user._id);
    setGroupName(user.groupName);
  };

  return (
    <>
      <ContentContainer>
        <Grid container spacing={2}>
          <ChatClient
            conversations={conversations}
            conversationId={conversationId}
            handleUserClick={handleUserClick}
            groupName={groupName}
          />
        </Grid>
      </ContentContainer>
    </>
  );
};

export default ChatMessages;
