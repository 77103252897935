import axios from "axios";
import { IFeedInputPayload, IFeedQueryParams } from "../models/FeedPageModels";
import { FEED_ITEMS_MANAGEMENT } from "../../../config/API.constants";
import { SERVER_API_URL } from "../../../config/constants";
import { getCurrentUser } from "../../../shared/components/authentication";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
const getHeaderWithToken = () => {
  const accessToken = localStorage.getItem("accessToken");
  return {
    Authorization: accessToken ? `Bearer ${accessToken}` : "",
  };
};

export const fetchFeedItems = async (parameters: IFeedQueryParams) => {
  const {
    sort = "createdAt|DESC",
    status = "published",
    isDeleted = false,
    clubs = "",
    textSearch = "",
    limit = 10,
    page = 1,
    fromDate = null,
    toDate = null,
    category = 'match-result'
  } = parameters;

  const params: any = {
    sort,
    filters: `status|${status},isDeleted|${isDeleted}`,
    limit,
    page,
  };

  if (clubs) {
    params["clubs"] = clubs;
  }

  if (textSearch) {
    params["textSearch"] = textSearch;
  }

  if (fromDate) {
    params["fromDate"] = dayjs.utc(fromDate).toISOString()?.slice(0, 10);
  }

  if (toDate) {
    params["toDate"] = dayjs.utc(toDate).toISOString()?.slice(0, 10);
  }

  const apiURL = `${SERVER_API_URL}${FEED_ITEMS_MANAGEMENT.API_END_POINTS.GET_ALL_FEEDS}`;
  const headers = getHeaderWithToken();
  const response = await axios.get(apiURL, {
    params,
    headers,
  });
  return response.data;
};

export const createFeedItem = (payload: IFeedInputPayload) => {
  return manageFeedItem(payload, false, "");
};

export const updateFeedItem = (payload: IFeedInputPayload, feedId: string) => {
  return manageFeedItem(payload, true, feedId);
};

export const manageFeedItem = (
  payload: IFeedInputPayload,
  isEdit: boolean,
  feedId: string
) => {
  if (isEdit) {
    const apiEndpoint = `${SERVER_API_URL}${FEED_ITEMS_MANAGEMENT.API_END_POINTS.UPDATE_FEED_DETAILS}/${feedId}`;
    const headers = getHeaderWithToken();
    return axios.put(apiEndpoint, payload, { headers });
  } else {
    const apiEndpoint = `${SERVER_API_URL}${FEED_ITEMS_MANAGEMENT.API_END_POINTS.CREATE_FEED}`;
    const headers = getHeaderWithToken();
    return axios.post(apiEndpoint, payload, { headers });
  }
};

export const likeFeedItem = async (feedItemId: string) => {
  const likeFeedItemUrl = `${SERVER_API_URL}${FEED_ITEMS_MANAGEMENT.API_END_POINTS.ADD_LIKE}/${feedItemId}/like`;
  const headers = getHeaderWithToken();
  const currentUser = getCurrentUser() as { userId: string };

  if (!currentUser) {
    return null;
  }

  try {
    const response = await axios.put(
      likeFeedItemUrl,
      { userId: currentUser.userId },
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error while liking feed item: ", error);
    return null;
  }
};

export const fetchFeedItemComments = async (feedItemId: string) => {
  const apiURL = `${SERVER_API_URL}${FEED_ITEMS_MANAGEMENT.API_END_POINTS.GET_ALL_COMMENTS}/${feedItemId}`;
  const headers = getHeaderWithToken();
  try {
    const {
      data: { data: apiData = {} },
    } = await axios.get(apiURL, {
      headers,
    });
    return apiData;
  } catch (error) {
    console.error("Error while fetching feed item comments: ", error);
    return [];
  }
};

export const deleteFeedItemComment = async (commentId: string) => {
  const apiURL = `${SERVER_API_URL}${FEED_ITEMS_MANAGEMENT.API_END_POINTS.DELETE_COMMENT}/${commentId}`;
  const headers = getHeaderWithToken();
  try {
    const {
      data: { data: apiData = {} },
    } = await axios.delete(apiURL, {
      headers,
    });
    return apiData;
  } catch (error) {
    console.error("Error while fetching feed item comments: ", error);
    return [];
  }
};

export const addFeedItemComment = async ({
  feedId,
  message,
  userId,
  mentions = [],
}: {
  feedId: string;
  message: string;
  userId: string;
  mentions?: string[];
}) => {
  const apiURL = `${SERVER_API_URL}${FEED_ITEMS_MANAGEMENT.API_END_POINTS.ADD_COMMENT}/${feedId}`;
  const headers = getHeaderWithToken();
  try {
    const {
      data: { data: apiData = {} },
    } = await axios.post(apiURL, { message, userId, mentions }, { headers });
    return apiData;
  } catch (error) {
    console.error("Error while fetching feed item comments: ", error);
    return [];
  }
};

export const deleteFeedItem = async (feedItemId: string) => {
  const apiURL = `${SERVER_API_URL}${FEED_ITEMS_MANAGEMENT.API_END_POINTS.DELETE_FEED}/${feedItemId}`;
  const headers = getHeaderWithToken();
  try {
    const {
      data: { data: apiData = {} },
    } = await axios.delete(apiURL, {
      headers,
    });
    return apiData;
  } catch (error) {
    console.error("Error while fetching feed item: ", error);
    return [];
  }
};
