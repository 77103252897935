/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Typography, IconButton, Box, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { BroadcastMessageGifIcon } from "app/config/constants";

interface BroadcastConfirmationModalProps {
  openModal?: any;
  onClose?: any;
  callback?: any;
}

export const BroadcastConfirmationModal: React.FC<
  BroadcastConfirmationModalProps
> = ({ openModal, onClose, callback }) => {
  const { t } = useTranslation();

  const handleClose = () => {
    onClose();
  };

  return (
    <div>
      <Modal open={openModal} onClose={handleClose}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            textAlign: "center",
            left: "50%",
            width: "30%",
            borderRadius: "5px",
            transform: "translate(-50%, -50%)",
            padding: 50,
            background: "white",
          }}
        >
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            sx={{
              position: "absolute",
              top: "15px",
              right: "24px",
              cursor: "pointer",
            }}
          >
            <CloseIcon />
          </IconButton>
          <>
            <div>
              <img width="100" src={BroadcastMessageGifIcon} />
            </div>
            <Typography
              style={{
                fontSize: "20px",
                fontWeight: 600,
                marginBottom: "1rem",
              }}
            >
              {t("BroadcastMessage")}
            </Typography>
            <Typography sx={{ color: "#8F8F8F" }}>
              {t("BroadcastMessageConfirmationText")}
            </Typography>
            '
            <Box style={{ marginTop: "2rem" }}>
              <Button
                onClick={() => {
                  callback();
                }}
                style={{
                  backgroundColor: "#333333",
                  color: "white",
                }}
                className="capitalize"
                variant="contained"
                color="primary"
              >
                {t("BroadcastMessage")}
              </Button>
            </Box>
          </>
        </div>
      </Modal>
    </div>
  );
};
