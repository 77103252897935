import {
  Box,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Avatar,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import {
  DashboardMenuImage,
  UsersMenuImage,
  ClubsMenuImage,
  BookingsMenuImage,
  CoachingMenuImage,
  CoachingScheduleMenuImage,
  CoachingProductsMenuImage,
  CoachingSessionsMenuImage,
  SettingsMenuImage,
  SettingsMenuWhiteImage,
  FeedMenuImage,
  TournamentsMenuImage,
  SubscriptionsMenuImage,
  CouponsMenuImage,
  ApparelMenuImage,
  MembershipMenuImage,
  LogOutMenuImage,
  DashboardMenuWhiteImage,
  UsersMenuWhiteImage,
  ClubsMenuWhiteImage,
  BookingsMenuWhiteImage,
  CoachingMenuWhiteImage,
  FeedMenuWhiteImage,
  TournamentsMenuWhiteImage,
  SubscriptionsMenuWhiteImage,
  CouponsMenuWhiteImage,
  ApparelMenuWhiteImage,
  CoachingProductsWhiteImage,
  MembershipMenuWhiteImage,
  TagsMenuImage,
  TagsMenuWhiteImage,
  USER_ROLE,
} from "../../config/constants";

import SvgImage from "app/shared/components/SvgImage";

const StyledListItemText = styled(ListItemText)`
  font-size: 18px !important;
  margin-left: 15px;
  max-width: 160px;
`;

const StyledListSubMenuItemText = styled(ListItemText)`
  margin-left: 15px;
  max-width: 160px;
  span {
    font-size: 15px !important;
  }
`;

const StyledListItemButton = styled(ListItemButton)`
  font-size: 18px;
`;

export const MenuList = (userType: string) => {
  const { t } = useTranslation();
  if (userType === USER_ROLE.COACH) {
    return [
      {
        MenuType: t("sessions"),
        imgSrc: BookingsMenuImage,
        whiteImgSrc: BookingsMenuWhiteImage,
        MenuName: "Dashboard",
        path: "/",
      },
      {
        MenuType: t("messages"),
        imgSrc: SubscriptionsMenuImage,
        whiteImgSrc: SubscriptionsMenuWhiteImage,
        MenuName: "Messages",
        path: "/messages",
      },
      {
        MenuType: t("coachesSchedule"),
        imgSrc: CoachingScheduleMenuImage,
        whiteImgSrc: CoachingMenuWhiteImage,
        MenuName: "CoachingSchedule",
        path: "/coachings",
      },
      {
        MenuType: t("logOut"),
        imgSrc: LogOutMenuImage,
        whiteImgSrc: LogOutMenuImage,
        MenuName: "Log Out",
        path: "logout",
      },
    ];
  } else if (userType) {
    return [
      {
        MenuType: t("dashboard"),
        imgSrc: DashboardMenuImage,
        whiteImgSrc: DashboardMenuWhiteImage,
        MenuName: "Dashboard",
        path: "/",
      },
      {
        MenuType: t("courtBookings"),
        imgSrc: BookingsMenuImage,
        whiteImgSrc: BookingsMenuWhiteImage,
        MenuName: "CourtBookings",
        path: "/court-bookings",
      },
      {
        MenuType: t("users"),
        imgSrc: UsersMenuImage,
        whiteImgSrc: UsersMenuWhiteImage,
        MenuName: "Users",
        path: "/users",
      },
      {
        MenuType: t("clubs"),
        imgSrc: ClubsMenuImage,
        whiteImgSrc: ClubsMenuWhiteImage,
        MenuName: "Clubs",
        path: "/clubs",
      },
      {
        MenuType: t("bookings"),
        imgSrc: BookingsMenuImage,
        whiteImgSrc: BookingsMenuWhiteImage,
        MenuName: "Bookings",
        path: "/bookings",
      },
      {
        MenuType: t("coaching"),
        imgSrc: CoachingMenuImage,
        whiteImgSrc: CoachingMenuWhiteImage,
        MenuName: "Coaching",
        path: "/coaching",
        subMenus: [
          {
            MenuType: t("coachesSchedule"),
            imgSrc: CoachingScheduleMenuImage,
            whiteImgSrc: CoachingMenuWhiteImage,
            MenuName: "CoachingSchedule",
            path: "/coachings",
          },
          {
            MenuType: t("coachProducts"),
            imgSrc: CoachingProductsMenuImage,
            whiteImgSrc: CoachingProductsWhiteImage,
            MenuName: "CoachingProducts",
            path: "/coach-products",
          },
          {
            MenuType: t("sessions"),
            imgSrc: CoachingSessionsMenuImage,
            whiteImgSrc: CoachingMenuWhiteImage,
            MenuName: "CoachingSessions",
            path: "/sessions",
          },
        ],
      },
      {
        MenuType: t("feed"),
        imgSrc: FeedMenuImage,
        whiteImgSrc: FeedMenuWhiteImage,
        MenuName: "Feed",
        path: "/feed",
      },
      {
        MenuType: t("tournaments"),
        imgSrc: TournamentsMenuImage,
        whiteImgSrc: TournamentsMenuWhiteImage,
        MenuName: "Tournaments",
        path: "/tournaments",
      },
      {
        MenuType: t("memberships"),
        imgSrc: MembershipMenuImage,
        whiteImgSrc: MembershipMenuWhiteImage,
        MenuName: "Subscriptions",
        path: "/memberships",
      },
      {
        MenuType: t("coupons"),
        imgSrc: CouponsMenuImage,
        whiteImgSrc: CouponsMenuWhiteImage,
        MenuName: "Coupons",
        path: "/coupons",
      },
      {
        MenuType: t("transactions"),
        imgSrc: TournamentsMenuImage,
        whiteImgSrc: TournamentsMenuWhiteImage,
        MenuName: "Transactions",
        path: "/transactions",
      },
      {
        MenuType: t("tags"),
        imgSrc: TagsMenuImage,
        whiteImgSrc: TagsMenuWhiteImage,
        MenuName: "Tags",
        path: "/tags",
      },
      {
        MenuType: t("extras/services"),
        imgSrc: ApparelMenuImage,
        whiteImgSrc: ApparelMenuWhiteImage,
        MenuName: "Extra",
        path: "/extras",
      },
      {
        MenuType: t("settings"),
        imgSrc: SettingsMenuImage,
        whiteImgSrc: SettingsMenuWhiteImage,
        MenuName: "Settings",
        path: "/settings",
      },
      {
        MenuType: t("logOut"),
        imgSrc: LogOutMenuImage,
        whiteImgSrc: LogOutMenuImage,
        MenuName: "Log Out",
        path: "logout",
      },
    ];
  }
};

export const MainMenuListContent = ({
  ele,
  currentPath,
  selectedItem,
  isSidebarOpenOrMinimize,
}: any) => {
  const listContent = (
    <StyledListItemButton
      disableRipple
      sx={{ borderRadius: "15px", padding: "16px!important" }}
    >
      <ListItemIcon
        sx={{
          minWidth: "auto !important",
        }}
      >
        {currentPath === ele.path ||
        selectedItem === ele?.MenuType ||
        selectedItem === ele?.MenuName ? (
          <Avatar
            src={ele?.whiteImgSrc}
            alt={ele?.MenuType}
            style={{ width: "24px", height: "24px" }}
          />
        ) : (
          <SvgImage src={ele?.imgSrc} width="24px" height="24px" />
        )}
      </ListItemIcon>
      {isSidebarOpenOrMinimize && (
        <StyledListItemText primary={ele?.MenuType} />
      )}
    </StyledListItemButton>
  );

  return !isSidebarOpenOrMinimize ? (
    <Tooltip title={ele?.MenuType || ""} placement="left" arrow>
      {listContent}
    </Tooltip>
  ) : (
    listContent
  );
};

export const SubMenuListContent = ({
  isSidebarOpenOrMinimize,
  subMenuItem,
}: any) => {
  const listContent = (
    <StyledListItemButton
      disableRipple
      sx={{ borderRadius: "15px", padding: "16px!important" }}
    >
      <ListItemIcon
        sx={{
          minWidth: "auto !important",
        }}
      >
        {subMenuItem?.MenuName !== subMenuItem?.MenuType ? (
          <Box>
            <SvgImage src={subMenuItem?.imgSrc} width="24px" height="24px" />
          </Box>
        ) : (
          <Avatar
            src={subMenuItem?.whiteImgSrc}
            alt={subMenuItem?.MenuType}
            style={{ width: "24px", height: "24px" }}
          />
        )}
      </ListItemIcon>
      {isSidebarOpenOrMinimize && (
        <StyledListSubMenuItemText
          primary={subMenuItem?.MenuType}
          sx={{ whiteSpace: "wrap" }}
        />
      )}
    </StyledListItemButton>
  );

  return !isSidebarOpenOrMinimize ? (
    <Tooltip title={subMenuItem?.MenuType || ""} placement="left" arrow>
      {listContent}
    </Tooltip>
  ) : (
    listContent
  );
};
